import React, { useState } from 'react';
import { useMediaQuery } from 'react-responsive';
import { CloseIcon } from '../../../assets/images/icons';
import CustomButton from '../../../utils/CustomButton';
import { showError, showSuccess } from '../../../utils/Toast';
import http from '../../../utils/utils';
import { useDispatch } from 'react-redux';
import { getOrdersById } from '../../../redux/actions/OrdersAction';
import CustomStatusDropDown from '../../../utils/CustomStatusDropDown';

const UpdateOrderStatusModal = (props) => {
    const isDesktopOrLaptop = useMediaQuery({ query: '(min-width: 1430px)' });
    const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1430px)' });

    const [state, setState] = useState({
        status: null,
    });
    const [open, setOpen] = useState(false); // Controls dropdown visibility
    const [selectedOption, setSelectedOption] = useState(null); // Tracks selected dropdown value
    const [loading, setLoading] = useState(false)

    const { orderId, onDecline } = props;
    const dispatch = useDispatch();

    const options = [
        { key: 'Order Accepted', value: 'ORDER_ACCEPTED' },
        { key: 'Order PickedUp', value: 'ORDER_PICKED_UP' },
        { key: 'Order Delivered', value: 'ORDER_DELIVERED' },
    ];

    const updateStatus = async () => {
        const obj = { delivery_status: state.status };
        console.log(obj);
        setLoading(true);
        try {
            const res = await http.put(`admin/order/${orderId}`, obj);
            showSuccess(res?.message);
            onDecline();
            dispatch(getOrdersById(orderId));
            setLoading(false);
        } catch (error) {
            console.log(error);

            // Check if the error has a response with a message and display it using showError
            const errorMessage = error[1]?.message || 'An unexpected error occurred';
            showError(errorMessage);

            setLoading(false);
        }
    };

    // Select status handler
    const selectStatus = (val) => {
        setState((prevState) => ({ ...prevState, status: val }));
    };

    return (
        <>
            {isDesktopOrLaptop &&
                <section className={'modalContainer'}>
                    <div className={'createOrderModal'} style={{ maxWidth: '633px', width: '633px' }}>
                        <div className={'createOrderModalHeader flexRow alignCenter justifyBetween'}>
                            <p className={'f20 boldText'}>Update Order Status</p>
                            <span onClick={props.onDecline}>
                                <img src={CloseIcon} alt='icon' />
                            </span>
                        </div>
                        <div>
                            <div style={{ width: '100%' }}>
                                <p className={'f14 grey semiBoldText'} style={{ marginBottom: '8px' }}>Order Status</p>
                                <CustomStatusDropDown
                                    options={options}
                                    selectedOption={state.status}
                                    setSelectedOption={(value) => setState(prev => ({ ...prev, status: value }))}
                                    open={open}
                                    setOpen={setOpen}
                                />


                            </div>
                        </div>
                        <div className={'createOrderModalFooter flexRow alignCenter justifyBetween'} style={{ marginTop: '40px' }}>
                            <div />
                            <div className={'flexRow alignCenter footerButtons'}>
                                <div style={{ marginRight: '8px' }}>
                                    <CustomButton title={'Cancel'} customStyle={'createOrderCancelBtn'} onClick={onDecline} height={'40px'} width={'88px'} />
                                </div>
                                <div>
                                    <CustomButton title={'Update'} bgColor={'#FFA024'} onClick={updateStatus} height={'40px'} width={'127px'} loading={loading} disabled={loading} />
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            }
            {isTabletOrMobile &&
                <section className={'mobileModalContainer'}>
                    <div className={'mobileCreateOrderModal'} style={{ width: "100%", maxHeight: "300px", height: open ? '500px' : 'auto', transition: '0.3s ease-in-out all ' }}>
                        <div className={'createOrderModalHeader flexRow alignCenter justifyBetween'}>
                            <p className={'f16 boldText'}>Update Order Status</p>
                            <span onClick={props.onDecline}>
                                <img src={CloseIcon} alt='icon' />
                            </span>
                        </div>
                        <div>
                            <div style={{ width: '100%' }}>
                                <p className={'f14 grey semiBoldText'} style={{ marginBottom: '8px' }}>Order Status</p>
                                <CustomStatusDropDown
                                    options={options}
                                    selectedOption={state.status}
                                    setSelectedOption={(value) => setState(prev => ({ ...prev, status: value }))}
                                    open={open}
                                    setOpen={setOpen}
                                />


                            </div>
                        </div>
                        <div className={'flexRow alignCenter justifyBetween'} style={{ marginTop: '40px' }}>
                            <div style={{ marginRight: '8px' }}>
                                <CustomButton title={'Cancel'} customStyle={'createOrderCancelBtn'} onClick={onDecline} height={'40px'} />
                            </div>
                            <div>
                                <CustomButton title={'Update'} bgColor={'#FFA024'} onClick={updateStatus} height={'40px'} loading={loading} disabled={loading} />
                            </div>
                        </div>
                    </div>
                </section>
            }
        </>
    );
};

export default UpdateOrderStatusModal;
