import React from "react";
import "./utils.css";
import "../assets/styles/textStyles.css";
import "../assets/styles/generalStyles.css";

const AIButton = ({
  icon,
  iconArt,
  loading,
  height,
  color,
  title,
  width,
  brd,
  pad,
  type,
  onClick,
  name,
  disabled,
  bgColor,
  glitterEffect, // New prop for glitter effect
}) => {
  const renderIcon = () => {
    if (icon) {
      return <img src={icon} alt="icon" style={{ height: "12px", width: "12px" }} />;
    }
    if (iconArt) {
      return iconArt;
    }
    return null;
  };

  return (
    <button
      className={`multiButton aiButton ${glitterEffect ? "glitter" : ""}`} // Conditionally apply glitter class
      style={{
        backgroundColor: bgColor,
        opacity: disabled ? "0.6" : "",
        height: height,
        width: width,
        border: brd,
        padding: pad,
        cursor: "pointer",
      }}
      type={type}
      onClick={onClick}
      disabled={disabled}
      name={name}
    >
      {loading ? (
        <span className={"loader"}></span>
      ) : (
        <>
          <div className={"flexRow alignCenter"} style={{ gap: "16px" }}>
            {renderIcon()}
            <div>
              <p className={"f14 semiBoldText"} style={{ color: color }}>
                {!loading ? title : ""}
              </p>
            </div>
          </div>
        </>
      )}
    </button>
  );
};

export default AIButton;
