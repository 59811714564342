import { LOGIN_SUCCESS, LOGIN_FAIL, LOADING_USER, SIGNOUT_USER_FAIL, SIGNOUT_USER, SIGNOUT_USER_SUCCESSFUL, GETTING_OTP_FAIL, GETTING_OTP_SUCCESSFUL, GETTING_OTP, VERIFY_OTP, VERIFY_OTP_SUCCESSFUL, VERIFY_OTP_FAIL, RESET_PASSWORD, RESET_PASSWORD_SUCCESSFUL, RESET_PASSWORD_FAIL } from '../Types';

const INITIAL_STATE = {
    error: [],
    userData: {},
    loggedIn: false,
    loadingUser: false,
    gettingOtp: false,
    verifyingOtp: false,
    resettingPassword: false,
    token: true,

};

export default (state = INITIAL_STATE, { type, payload }) => {
    switch (type) {
        case GETTING_OTP:
            return { ...state, gettingOtp: true };
        case GETTING_OTP_SUCCESSFUL:
            return { ...state, gettingOtp: false }
        case GETTING_OTP_FAIL:
            return { ...state, gettingOtp: false, error: payload }
        case LOADING_USER:
            return { ...state, loadingUser: true, error: [] };
        case LOGIN_SUCCESS:
            return { ...state, userData: payload, loadingUser: false, loggedIn: true };
        case LOGIN_FAIL:
            return { ...state, error: payload, userData: {}, loadingUser: false };
        case VERIFY_OTP:
            return { ...state, verifyingOtp: true };
        case VERIFY_OTP_SUCCESSFUL:
            return { ...state, verifyingOtp: false }
        case VERIFY_OTP_FAIL:
            return { ...state, verifyingOtp: false, error: payload }
        case RESET_PASSWORD:
            return { ...state, resettingPassword: true };
        case RESET_PASSWORD_SUCCESSFUL:
            return { ...state, resettingPassword: false }
        case RESET_PASSWORD_FAIL:
            return { ...state, resettingPassword: false, error: payload }
        case SIGNOUT_USER:
            return { ...state, loadingUser: true }
        case SIGNOUT_USER_SUCCESSFUL:
            return { ...state, loadingUser: false, loggedIn: false, userData: {} }
        case SIGNOUT_USER_FAIL:
            return { ...state, error: payload, loadingUser: false };

        default:
            return state;
    }
};