import React from 'react'
import { Outlet, Route, Routes } from 'react-router-dom'
import ProductsList from './Products'

const Products = () => {
    return (
        <>
            <Routes>
                <Route path="/" element={< ProductsList />} />
                {/* <Route path="/orderDetails" element={<OrderDetails />} />
                <Route path="/createOrder" element={<CreateOrder />} /> */}
            </Routes>
            <Outlet />
        </>

    )
}

export default Products