import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import { useMediaQuery } from 'react-responsive'
import { CloseIcon } from '../../../assets/images/icons'
import { getOrdersById } from '../../../redux/actions/OrdersAction'
import CustomButton from '../../../utils/CustomButton'
import CustomInput from '../../../utils/CustomInput'
import { showError, showSuccess } from '../../../utils/Toast'
import http from '../../../utils/utils'
import CityDropDown from '../../../utils/CityDropDown'

const banks = [{ id: '1', name: 'GT_BANK' }, { id: '2', name: 'WEMA_BANK' }]
// GT_BANK & 

const ConfirmPaymentModal = (props) => {
    const isDesktopOrLaptop = useMediaQuery({
        query: '(min-width: 1430px)'
    })

    const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1430px)' })

    const { onDecline, orderNo, id } = props
    const [state, setState] = useState({
        transaction_reference: ''
    })
    const [selectedBank, setSelectedBank] = useState('')
    const selectBank = (val) => {
        setSelectedBank(val.name)
    }

    const onChangeTrsRf = (e) => {
        setState({ ...state, transaction_reference: e.target.value })
    }

    const dispatch = useDispatch()
    const disabled = () => {
        if (state.transaction_reference !== '') {
            return false
        }
        return true
    }
    const returnError = (e) => {
        let formErrors = [];
        if (e[0]) {
            formErrors = [e[0]];
            console.log(formErrors, 'form Errors')
        } else {
            formErrors = e[1]?.message;
            console.log(formErrors, 'form Errors')
            showError(formErrors, 10000000)

        }
        console.log(formErrors, 'form Errors')

    };


    const confirmOrder = async () => {
        const obj = { transaction_reference: state.transaction_reference, order_no: orderNo, bank: selectedBank }
        setState({ ...state, loading: true })
        try {
            const res = await http.post(`admin/order/confirm_payment`, obj)
            setState({ ...state, loading: false })
            showSuccess(res?.message)
            onDecline()
            dispatch(getOrdersById(id))

        } catch (error) {
            console.log(error)
            setState({ ...state, loading: false })
            returnError(error)

        }
    }

    return (

        <>
            {isDesktopOrLaptop &&
                <section className={'modalContainer'}>
                    <div className={'createOrderModal'} style={{ maxWidth: '633px', width: '633px' }}>
                        <div className={'createOrderModalHeader flexRow alignCenter justifyBetween'}>
                            <p className={'f20 boldText'}>Confirm order payment</p>
                            <span onClick={props.onDecline}>
                                <img src={CloseIcon} alt='icon' />
                            </span>
                        </div>

                        <div style={{ marginBottom: '16px' }}>
                            <p className={'f14 grey semiBoldText flex alignCenter'} style={{ marginBottom: '8px' }}>Bank</p>
                            <CityDropDown options={banks} selectData={selectBank} data={selectedBank} />
                        </div>
                        <div>
                            <CustomInput label={'Transaction reference'} placeholder={'Enter payment transaction reference'} value={state.transaction_reference}
                                onChange={onChangeTrsRf}
                            />
                        </div>
                        <div className={'createOrderModalFooter flexRow alignCenter justifyBetween'} style={{ marginTop: '40px' }}>
                            <div />
                            <div className={'flexRow alignCenter footerButtons'} >
                                <div style={{ marginRight: '8px' }}>
                                    <CustomButton title={'Cancel'} customStyle={'createOrderCancelBtn'} onClick={props.onDecline} height={'40px'} width={'88px'} />
                                </div>
                                <div >
                                    <CustomButton title={'Yes, confirm'} onClick={confirmOrder} bgColor={'#FFA024'} disabled={disabled()} loading={state.loading} height={'40px'} width={'127px'} />
                                </div>

                            </div>
                        </div>
                    </div>
                </section>
            }
            {isTabletOrMobile &&
                <section className={'mobileModalContainer'}>
                    <div className={'mobileCreateOrderModal'} style={{ width: "100%" }}>
                        <div className={'createOrderModalHeader flexRow alignCenter justifyBetween'}>
                            <p className={'f20 boldText'}>Confirm order payment</p>
                            <span onClick={props.onDecline}>
                                <img src={CloseIcon} alt='icon' />
                            </span>
                        </div>
                        <div style={{ margin: '16px 0' }}>
                            <p className={'f14 grey semiBoldText flex alignCenter'} style={{ marginBottom: '8px' }}>Bank</p>
                            <CityDropDown options={banks} selectData={selectBank} data={selectedBank} />
                        </div>
                        <div>
                            <CustomInput label={'Transaction reference'} placeholder={'Enter payment transaction reference'} value={state.transaction_reference}
                                onChange={onChangeTrsRf}
                            />
                        </div>
                        <div className={' flexRow alignCenter justifyBetween'} style={{ marginTop: '40px' }}>


                            <div style={{ marginRight: '8px' }}>
                                <CustomButton title={'Cancel'} customStyle={'createOrderCancelBtn'} onClick={props.onDecline} height={'40px'} />
                            </div>
                            <div >
                                <CustomButton title={'Yes, confirm'} onClick={confirmOrder} bgColor={'#FFA024'} disabled={disabled()} loading={state.loading} height={'40px'} />
                            </div>


                        </div>
                    </div>
                </section>
            }
        </>

    )
}

export default ConfirmPaymentModal