import React, { useEffect, useRef, useState } from 'react'
import { CloseIcon, Courier } from '../../../assets/images/icons'
import { AdvancedMarker, APIProvider, Map, Marker, Pin } from "@vis.gl/react-google-maps";
import { useDispatch, useSelector } from 'react-redux';
import RiderModal from './RiderModal';
import http from '../../../utils/utils';
import { getOrdersById } from '../../../redux/actions/OrdersAction';
import { showError, showSuccess } from '../../../utils/Toast';
import { useMediaQuery } from 'react-responsive';

const MapModal = (props) => {
    const { closetRiders, gettingUsers } = useSelector(state => state.others)
    const isMobile = useMediaQuery({ query: '(max-width: 600px)' })
    const [showRider, setShowRider] = useState(false)
    const [rider, setRider] = useState({})
    const dispatch = useDispatch()

    console.log(process.env.REACT_APP_GOOGLE_MAP_ID);
    const mapRef = useRef(null);

    useEffect(() => {
        if (mapRef.current && closetRiders?.riders?.length > 0) {
            const bounds = new window.google.maps.LatLngBounds();

            // Adding business address to the bounds
            const businessLocation = closetRiders?.businessAddress;
            if (businessLocation?.latitude && businessLocation?.longitude) {
                bounds.extend(new window.google.maps.LatLng(businessLocation.latitude, businessLocation.longitude));
            }

            // Adding rider locations to the bounds
            closetRiders?.riders?.forEach((rider) => {
                const { riderLocation } = rider;
                if (riderLocation?.latitude && riderLocation?.longitude) {
                    bounds.extend(new window.google.maps.LatLng(riderLocation.latitude, riderLocation.longitude));
                }
            });

            // Fit map bounds to include all markers
            if (mapRef.current) {
                const map = mapRef.current.getMap(); // Access map instance
                map.fitBounds(bounds); // Adjust the map bounds to fit all markers
            }
        }
    }, [closetRiders?.riders]);

    const handleMarkerClick = (riderName, riderLocation, riderPhoneNumber, riderId) => {
        // Here you can handle the marker click
        // For example, you could alert the rider's name and location
        setRider({ riderName, riderLocation, riderPhoneNumber, riderId })
        setShowRider(true)
        // alert(`Rider: ${riderName}\nLocation: Lat: ${riderLocation.latitude}, Lng: ${riderLocation.longitude}`);
    };

    const assignRider = async () => {
        const obj = {
            order_id: props.orderId,
            rider_id: rider.riderId
        }
        console.log(obj)
        try {
            const res = await http.post(`admin/order/assign_rider`, obj)
            showSuccess(res?.message)
            setShowRider(false)
            dispatch(getOrdersById(props.id))
            props.onDecline()

        } catch (error) {
            console.log(error)
            showError()
        }
    }

    
    return (
        <>
            {showRider && (
                <RiderModal
                    onDecline={() => setShowRider(false)}
                    rider={rider}
                    onAssign={assignRider}
                />
            )}
            <section className={'mapModalContainer'}>
                <div className={'mapModal'} >
                    <div className={'createOrderModalHeader flexRow alignCenter justifyBetween'}>
                        <p className={'f20 boldText'}>Locate Rider</p>
                        <span onClick={props.onDecline}>
                            <img src={CloseIcon} alt='icon' />
                        </span>
                    </div>
                    <APIProvider apiKey={process.env.REACT_APP_GOOGLE_API_KEY}>
                        <div style={{ height: isMobile ? "93vh" : "70vh" }}>
                            {closetRiders.businessAddress && <Map
                                ref={mapRef}
                                defaultZoom={15}
                                defaultCenter={{ lat: closetRiders?.businessAddress?.latitude, lng: closetRiders?.businessAddress?.longitude }}
                                // defaultZoom={10}
                                mapId={process.env.REACT_APP_GOOGLE_MAP_ID}
                            >
                                <AdvancedMarker position={{ lat: closetRiders?.businessAddress?.latitude, lng: closetRiders?.businessAddress?.longitude }} />
                                {closetRiders?.riders?.map(({ riderId, riderLocation, riderName, riderPhoneNumber }) => {
                                    const { latitude, longitude } = riderLocation || {};
                                    if (!latitude || !longitude) {
                                        return null; // Skip rendering if location is invalid
                                    }
                                    return (
                                        <Marker
                                            key={riderId}
                                            position={{ lat: latitude, lng: longitude }}
                                            onClick={() => {
                                                handleMarkerClick(riderName, riderLocation, riderPhoneNumber, riderId)

                                            }}
                                            icon={{
                                                url: Courier,
                                                scaledSize: { width: 50, height: 50 }
                                            }}
                                        />
                                    );
                                })}



                            </Map>}
                        </div>
                    </APIProvider>
                </div>
            </section>
        </>
    )
}

export default MapModal