import React from 'react'

const CardsWithHeader = (props) => {
    return (
        <>
            <section className={`cardWithHeaderContainer ${props.class}`}>
                <div className={'flexRow alignCenter cardWithHeaderHeader'}>
                    <img src={props.icon} alt='icon' style={{ marginRight: '10px' }} />
                    <h5 className={'f16 boldText'} style={{ color: '#38383A' }}>
                        {props.title}
                    </h5>
                </div>
                {props.children}
            </section>
        </>
    )
}

export default CardsWithHeader
