import React from 'react'
import { AiOutlineClose, AiOutlinePlus } from 'react-icons/ai'
import { PlusIcon } from '../../../assets/images/icons'
import CustomDropDown from '../../../utils/CustomDropDown'
import CustomInput from '../../../utils/CustomInput'
import ImageUpload from '../../../utils/ImageUploadInput'
import MultiButton from '../../../utils/MultiButton'

const EditProductForm = (props) => {

    const { handleImageChange, image, tags, option_name, option_price, product_description, product_name, product_options, product_tags,
        onChangeOptionName, onChangeOptionPrice, onChangeProductDescription, onChangeProductName, onChangeTags, updateProductOptions, updateProductTags,
        deleteFromOptions, deleteFromTags, business, selectBusiness, product_images
    } = props

    const productImage = product_images[product_images?.length - 1]

    return (
        <section>
            <div className={'createProductsFormGrid'}>
                <div>
                    <CustomInput label={'Name'} placeholder={'Enter product name '} onChange={onChangeProductName} value={product_name} />
                </div>
                <div>
                    <CustomInput label={'Description'} placeholder={'Enter product description'} onChange={onChangeProductDescription} value={product_description} />
                </div>
            </div>
            <div style={{ marginBottom: '16px' }}>
                <p style={{ marginBottom: '10px' }} className={'f14 grey semiBoldText flex alignCenter'}>Product Tags</p>
                <div className={'priceInputContainer'}>
                    <CustomInput inputStyle={'noMargin'} customInputContainer={'noBorder'} onChange={onChangeTags} value={tags} />
                    <MultiButton title={'Add tags'} height={'25px'} pad={'0'} iconArt={<AiOutlinePlus color={'#FFA024'} />} bgColor={'inherit'} brd={'1px solid #FFA024'}
                        color={'#FFA024'} width={'140px'} disabled={tags == ''} onClick={updateProductTags}
                    />
                </div>
                <div className={'tagsContainer'}>
                    {product_tags && product_tags?.length !== 0 && product_tags?.map((item, i) => {
                        return <p className={'optionsTag semiBoldText f12'}>{item} <AiOutlineClose color='#fff' onClick={deleteFromTags} /></p>
                    })}
                </div>
            </div>
            <div>
                <ImageUpload label={'Upload image'} handleImageChange={handleImageChange} image={image} />
                {image ? <img src={URL.createObjectURL(image)} alt="Uploaded image" style={{ height: "100px", width: '100px', borderRadius: '10px' }} /> : <img src={productImage} alt="Uploaded image" style={{ height: "100px", width: '100px', borderRadius: '10px' }} />}

            </div>
            <div className={'createProductsFormGrid'} >
                <div>
                    <CustomInput label={'Combo options'} onChange={onChangeOptionName} value={option_name} />
                </div>
                <div>
                    <p style={{ marginBottom: '10px' }} className={'f14 grey semiBoldText flex alignCenter'}>Price</p>
                    <div className={'priceInputContainer'}>
                        <CustomInput inputStyle={'noMargin'} customInputContainer={'noBorder'} type={'number'} onChange={onChangeOptionPrice} value={option_price} />
                        <MultiButton title={'Add options'} height={'25px'} pad={'0'} iconArt={<AiOutlinePlus color={'#FFA024'} />} bgColor={'inherit'} brd={'1px solid #FFA024'}
                            color={'#FFA024'} width={'180px'} disabled={option_name === '' || option_price === ''} onClick={updateProductOptions}
                        />
                    </div>
                </div>

            </div>
            <div className={'tagsContainer'}>
                {product_options && product_options?.length !== 0 && product_options?.map((item, i) => {
                    return <p className={'optionsTag semiBoldText f12'}>{item.name}-{item.price} <AiOutlineClose color='#fff' onClick={() => deleteFromOptions(item, i)} /></p>
                })}
            </div>
        </section>
    )
}

export default EditProductForm


