import {
  CART,
  GET_RIDERS,
  GET_RIDERS_FAIL,
  GET_RIDERS_SUCCESSFUL,
  GET_USERS,
  GET_USERS_FAIL,
  GET_USERS_SUCCESSFUL,
  GET_VENDORS,
  GET_VENDORS_FAIL,
  GET_VENDORS_SUCCESSFUL,
  ORDER,
  GET_AGENCIES,
  GET_AGENCIES_SUCCESSFUL,
  GET_AGENCIES_FAIL,
  GET_CLOSEST_RIDERS,
  GET_CLOSEST_RIDERS_SUCCESSFUL,
  GET_CLOSEST_RIDERS_FAIL,
} from "../Types";

const INITIAL_STATE = {
  error: [],
  users: [],
  gettingUsers: false,
  gettingVendors: false,
  gettingRiders: false,
  gettingAgencies: false,
  agemcies: [],
  vendors: [],
  cart: [],
  orderSummary: {},
  riders: [],
  closetRiders: {}
};

export default (state = INITIAL_STATE, { type, payload }) => {
  switch (type) {
    case GET_USERS:
      return { ...state, gettingUsers: true };
    case GET_USERS_SUCCESSFUL:
      return { ...state, gettingUsers: false, users: payload };
    case GET_USERS_FAIL:
      return { ...state, gettingUsers: false, error: payload };
    case GET_VENDORS:
      return { ...state, gettingVendors: true };
    case GET_VENDORS_SUCCESSFUL:
      return { ...state, gettingVendors: false, vendors: payload };
    case GET_VENDORS_FAIL:
      return { ...state, gettingVendors: false, error: payload };
    case GET_RIDERS:
      return { ...state, gettingRiders: true };
    case GET_RIDERS_SUCCESSFUL:
      return { ...state, gettingRiders: false, riders: payload };
    case GET_RIDERS_FAIL:
      return { ...state, gettingRiders: false, error: payload };
    case GET_AGENCIES:
      return { ...state, gettingAgencies: true };
    case GET_AGENCIES_SUCCESSFUL:
      return { ...state, gettingAgencies: false, agencies: payload };
    case GET_AGENCIES_FAIL:
      return { ...state, gettingAgencies: false, error: payload };
    case GET_CLOSEST_RIDERS:
      return { ...state, gettingRiders: true };
    case GET_CLOSEST_RIDERS_SUCCESSFUL:
      return { ...state, gettingRiders: false, closetRiders: payload };
    case GET_CLOSEST_RIDERS_FAIL:
      return { ...state, gettingRiders: false, error: payload };
    case CART:
      return { ...state, cart: payload };
    case ORDER:
      return { ...state, orderSummary: payload };
    default:
      return state;
  }
};
