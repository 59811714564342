import React, { useState, useEffect, useRef } from 'react';
import CustomInput from '../../../utils/CustomInput';
import CustomButton from '../../../utils/CustomButton';
import http from '../../../utils/utils';

const EditAIProductsModal = ({ closeModal, isOpen, item, onSave }) => {
    const [name, setName] = useState('');
    const [description, setDescription] = useState('');
    const [price, setPrice] = useState('');
    const [category, setCategory] = useState('');
    const [image, setImage] = useState(null);
    const [loading, setLoading] = useState(false);

    // Update state when item prop changes
    useEffect(() => {
        if (item) {
            setName(item.name || '');
            setDescription(item.description || '');
            setPrice(item.price || '');
            setCategory(item.category || '');
            setImage(item.image || null);
        }
    }, [item]); // Update this with `item` as dependency

    const hiddenFileInput = useRef(null);

    const handleClick = () => {
        hiddenFileInput.current.click();
    };

    const handleFileChange = async (event) => {
        const formData = new FormData();
        formData.append("image", event.target.files[0]);
        setLoading(true);
        try {
            const res = await http.post('admin/upload_image', formData);
            const imageUrl = res?.data?.image_url;
            setLoading(false);
            setImage(imageUrl);
        } catch (error) {
            console.log(error);
            setLoading(false);
        }
    };

    const handleSave = () => {
        const updatedItem = {
            ...item,
            name,
            description,
            price,
            category,
            image,
        };
        onSave(updatedItem);
    };

    return (
        <div className={`createWithAIModal ${isOpen ? 'open' : ''}`} onClick={closeModal}>
            <div className="createWithAIModalContent" onClick={(e) => e.stopPropagation()}>
                <header style={{ position: 'sticky', top: '-40px', background: 'white', padding: '20px 0' }}>
                    <h3 className={'boldText f16'}>Edit Item</h3>
                </header>

                <div>
                    <CustomInput label={'Name'} onChange={(e) => setName(e.target.value)} value={name} placeholder="Name" />
                </div>

                <div>
                    <CustomInput label={'Description'} onChange={(e) => setDescription(e.target.value)} value={description} placeholder="Description" />
                </div>

                <div>
                    <CustomInput onChange={(e) => setPrice(Number(e.target.value))} value={price} placeholder="Price" label="Price" />
                </div>

                <div>
                    <CustomInput onChange={(e) => setCategory(e.target.value)} value={category} placeholder="Category" label={'Category'} />
                </div>

                <div>
                    {image ? (
                        <div>
                            <img
                                src={image}
                                alt="Uploaded"
                                style={{ width: '100px', height: '100px' }}
                            />
                            <div className='flexRow alignCenter' style={{ gap: '16px' }}>
                                <button
                                    className="button"
                                    onClick={() => setImage(null)}
                                    style={{ backgroundColor: '#f44336', padding: '8px', borderRadius: '4px', color: '#fff', cursor: 'pointer', maxWidth: '100px' }}
                                >
                                    Remove
                                </button>
                            </div>
                        </div>
                    ) : (
                        <div className={`inputContainer `} style={{ position: 'relative', cursor: 'pointer' }}>
                            <label className={`f14 grey semiBoldText flex alignCenter`}>
                                Upload Image
                            </label>
                            <div className={`input flexRow alignCenter justifyBetween`} style={{ padding: '0 16px', height: '48px' }} onClick={handleClick}>
                                <input
                                    type="file"
                                    id="image"
                                    name="image"
                                    accept="image/*"
                                    style={{ display: 'none' }}
                                    onChange={handleFileChange}
                                    ref={hiddenFileInput}
                                />
                                {loading ? (
                                    <div className={'flexRow alignCenter'} style={{ gap: '16px' }}>
                                        <div className={'aiLoader'} />
                                        <p className={'boldText f12'}>Loading...</p>
                                    </div>
                                ) : (
                                    <p className={'f10 boldText'} style={{ color: '#999CA0' }}>
                                        Click to upload food image jpg or png (size within 6mb).
                                    </p>
                                )}
                            </div>
                        </div>
                    )}
                </div>

                <div className={'flexRow alignCenter'} style={{ gap: '16px', margin: '30px 0 40px 0' }}>
                    <CustomButton title={'Save Changes'} onClick={handleSave} bgColor={'#FFA024'} />
                    <CustomButton title={'Cancel'} onClick={closeModal} bgColor={'#FFF'} brd={'1px solid #000'} color={'#000'} />
                </div>
            </div>
        </div>
    );
};

export default EditAIProductsModal;
