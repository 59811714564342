import React from 'react'

const OrderSummaryCards = (props) => {
    return (
        <>
            <section className={'cardWithHeaderContainer'} style={{ borderColor: '#E6E7E9', boxShadow: 'none' }}>
                <div className={'flexRow alignCenter cardWithHeaderHeader'}>
                    <img src={props.icon} alt='icon' style={{ marginRight: '10px' }} />
                    <h5 className={'f16 boldText'} style={{ color: '#38383A' }}>
                        {props.title}
                    </h5>
                </div>
                {props.children}
            </section>
        </>
    )

}

export default OrderSummaryCards