import React, { useState } from 'react';
import Upload from '../assets/images/icons/upload.svg';

function ImageUpload(props) {
    // const [image, setImage] = useState(null);
    const { label, customInputContainer, customLabel, icon, inputStyle, image, handleImageChange } = props

    // const handleChange = (event) => {
    //     setImage(event.target.files[0]);
    // };

    // Create a reference to the hidden file input element
    const hiddenFileInput = React.useRef(null);

    // Programatically click the hidden file input element
    // when the Button component is clicked
    const handleClick = event => {
        hiddenFileInput.current.click();
    };

    return (
        <div className={`inputContainer ${customInputContainer}`} style={{ position: 'relative', cursor: 'pointer' }} onClick={handleClick} >
            <label className={`f14 grey semiBoldText flex alignCenter ${customLabel}`} >
                {label}
            </label>
            <div className={`input flexRow alignCenter  justifyBetween ${inputStyle}`} style={{ padding: '0 16px', height: '48px' }}>
                <input type="file" id="image" name="image" accept="image/*" style={{ display: 'none' }} onChange={handleImageChange} ref={hiddenFileInput} />
                {image ? <p className={'f12 boldText '}>{image?.name}</p> : <p className={'f10 boldText '} style={{
                    color: '#999CA0',
                }}>Upload corresponding food image in
                    jpg or png (size within 6mb)
                    .
                </p>}
                <label style={{ position: 'absolute', right: '16px', }}>
                    <img src={Upload} alt={'upload images'} />
                </label>
            </div>
            {/* {image && <img src={URL.createObjectURL(image)} alt="Uploaded image" />} */}
        </div>
    );
}

export default ImageUpload;