import React, { useEffect, useState } from "react";
import "../../../assets/styles/settingsStyles.css";
import SettingsNav from "../../../widgets/SettingsNav";
import Navigation from "../../../widgets/Navigation";
import PersonalInformation from "../../../components/dashboard/settings/PersonalInformation";
import { getAdminProfile, getOperationsLocations } from "../../../redux/actions/SettingsActions";
import { useDispatch, useSelector } from "react-redux";

const Settings = () => {
  const [selectedTab, setSelectedTab] = useState("Personal information");
  const { operationLocation } = useSelector((state) => state.settings);
  const toggleTabs = (val) => {
    setSelectedTab(val);
  };

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getAdminProfile());
    if (operationLocation?.locations?.length === 0 || operationLocation === null) {
      dispatch(getOperationsLocations());
    }
  }, []);

  return (
    <main className={"settingsContainer"}>
      <Navigation />
      <SettingsNav selectedTab={selectedTab} toggleTabs={toggleTabs} />
      <section>{selectedTab === "Personal information" && <PersonalInformation />}</section>
    </main>
  );
};

export default Settings;
