import http from "../../utils/utils";
import { REVENUES, REVENUES_FAIL, REVENUES_SUCCESSFUL, TRANSACTIONS, TRANSACTIONS_FAIL, TRANSACTIONS_SUCCESSFUL } from "../Types";



export const getRevenues = (order_status) => {
    return dispatch => {
        dispatch({ type: REVENUES })
        return new Promise(async (resolve, reject) => {
            try {
                const res = await http.get(`admin/transactions/revenues`,)
                const data = res
                // console.log('get Orders ', res)
                dispatch({ type: REVENUES_SUCCESSFUL, payload: res });
                resolve(data);
            } catch (error) {
                // console.log('get orders error', error)
                dispatch({ type: REVENUES_FAIL, payload: error });
                reject(error);
            }
        });
    };
};



// export const getTransactions = () => {
//     return dispatch => {
//         dispatch({ type: TRANSACTIONS })
//         return new Promise(async (resolve, reject) => {
//             try {
//                 const res = await http.get(`admin/transactions`,)
//                 const data = res
//                 // console.log('get Orders ', res)
//                 dispatch({ type: TRANSACTIONS_SUCCESSFUL, payload: res });
//                 resolve(data);
//             } catch (error) {
//                 // console.log('get orders error', error)
//                 dispatch({ type: TRANSACTIONS_FAIL, payload: error });
//                 reject(error);
//             }
//         });
//     };
// };

// transaction_type
// completed
// start_date
// 2023 - 01 - 27
// end_date
// 2023 - 01 - 27

export const getTransactions = (start_date, end_date) => {
    return (dispatch) => {
        dispatch({ type: TRANSACTIONS });
        return new Promise(async (resolve, reject) => {
            try {
                let apiCall = `admin/transactions`;
                if (start_date && end_date) {
                    apiCall += `?startDate=${start_date}&endDate=${end_date}`;
                }
                const res = await http.get(apiCall);
                const data = res.data;
                dispatch({ type: TRANSACTIONS_SUCCESSFUL, payload: data });
                resolve(data);
            } catch (error) {
                console.log('transactions error', error);
                dispatch({ type: TRANSACTIONS_FAIL, payload: error });
                reject(error);
            }
        });
    };
};


