import http from "../../utils/utils";
import { ALL_PRODUCTS, ALL_PRODUCTS_FAIL, ALL_PRODUCTS_SUCCESSFUL, SINGLE_PRODUCT, SINGLE_PRODUCT_FAIL, SINGLE_PRODUCT_SUCCESSFUL } from "../Types";


export const getProducts = (page) => {
    return dispatch => {
        dispatch({ type: ALL_PRODUCTS })
        return new Promise(async (resolve, reject) => {
            try {
                const res = await http.get(`admin/products?page=${page}&limit=10`,)
                const data = res
                // console.log('get Orders ', res)
                dispatch({ type: ALL_PRODUCTS_SUCCESSFUL, payload: res });
                resolve(data);
            } catch (error) {
                // console.log('get orders error', error)
                dispatch({ type: ALL_PRODUCTS_FAIL, payload: error });
                reject(error);
            }
        });
    };
};


export const getSingleProduct = (businessId, id) => {
    return dispatch => {
        dispatch({ type: SINGLE_PRODUCT })
        return new Promise(async (resolve, reject) => {
            try {
                const res = await http.get(`admin/business/${businessId}/product/${id}`,)
                const data = res
                // console.log('get Orders ', res)
                dispatch({ type: SINGLE_PRODUCT_SUCCESSFUL, payload: res });
                resolve(data);
            } catch (error) {
                // console.log('get orders error', error)
                dispatch({ type: SINGLE_PRODUCT_FAIL, payload: error });
                reject(error);
            }
        });
    };
};
