import { CloseSquare } from 'iconsax-react';
import React from 'react';
// import './SliderModal.css';

const SliderModal = ({ isOpen, toggleModal, children }) => {
    return (
        <>
            <div
                className={`modal-overlay ${isOpen ? 'open' : ''}`}
                onClick={toggleModal}
            ></div>
            <div className={`slider-modal ${isOpen ? 'open' : ''}`}>
                <CloseSquare size="32" color="#000" className={'close-modal-button'} onClick={toggleModal} />
                <div className="modal-content">
                    {children}
                </div>
            </div>
        </>
    );
};

export default SliderModal;
