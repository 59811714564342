import React from 'react';
import './utils.css';
import '../assets/styles/textStyles.css';
import '../assets/styles/generalStyles.css';
const CustomMessageInput = (props) => {

    const { label, type, name, value, onChange, placeholder, disabled, maxLength, customLabel, inputStyle, customInputContainer, multiple, height } = props

    return (
        <>
            <div className={`inputContainer ${customInputContainer}`}>
                <label className={`f14 grey semiBoldText flex alignCenter ${customLabel}`} >
                    {label}
                </label>
                <textarea name={name} value={value} onChange={onChange} placeholder={placeholder} disabled={disabled} className={'input'} style={{ height: height, outline: 'none' }} ></textarea>
            </div>
        </>
    )
}

export default CustomMessageInput