import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';
import { useMediaQuery } from 'react-responsive';
import { Navigate } from 'react-router-dom';
import { Chart } from '../../assets/images/Index';
import ActiveOrders from '../../components/dashboard/orderManagement/ActiveOrders';
import RecentOrdersTable from '../../components/dashboard/overview/RecentOrdersTable'
import RecentTransactionsCard from '../../components/dashboard/overview/RecentTransactionsCard'
import StatsCardContainer from '../../components/dashboard/overview/StatsCardContainer'
import { getActiveOrders, getOrdersAll, getOrders } from '../../redux/actions/OrdersAction'
import { getUsers, getVendors } from '../../redux/actions/OtherActions'
import { getRevenues, getTransactions } from '../../redux/actions/TransactionsActions'
import Navigation from '../../widgets/Navigation'
import OverviewMobileNav from '../../widgets/OverviewMobileNav';

const Overview = (props) => {

    const isDesktopOrLaptop = useMediaQuery({ query: '(min-width: 1200px)' })
    const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1200px)' })
    const { orders, gettingOrders, } = useSelector(state => state.orders)
    const [activeOrders, setActiveOrders] = useState([]);
    const [recentOrders, setRecentOrders] = useState([]);
    const [meta, setMeta] = useState({
        count: orders?.data?.meta?.count, currentPage: orders?.data?.meta?.currentPage,
        perPage: orders?.data?.meta?.perPage, total: orders?.data?.meta?.total, totalPages: orders?.data?.meta?.totalPages
    });

    const fetchRevenue = async () => {
        try {
            const revenue = await props.getRevenues()
            const usersData = await props.getUsers()
            const transactions = await props.getTransactions()
            const getOrders = await props.getOrders()
        } catch (error) {
            // console.log(error)
        }
    }

    useEffect(() => {
        fetchRevenue()
        // if (isBigScreen)
    }, [])




    useEffect(() => {
        // Function to filter and set active orders
        const returnActiveOrders = () => {
            if (!orders || !orders.data || !orders.data.orders) {
                setActiveOrders([]);
                return;
            }

            const arr = orders.data.orders;
            const filterActiveOrders = arr.filter((x) =>
                ['ORDER_ASSIGNED', 'ORDER_ACCEPTED', 'ORDER_REJECTED', 'ORDER_PICKED_UP', 'ORDER_CONFIRMED'].includes(
                    x?.orderStatus
                )
            );
            setActiveOrders(filterActiveOrders);
        };

        returnActiveOrders();
    }, [orders]);



    useEffect(() => {
        // Function to filter and set active orders for the last seven days
        const returnRecentOrders = () => {
            if (!orders || !orders.data || !orders.data.orders) {
                setRecentOrders([]);
                return;
            }

            const arr = orders.data.orders;

            // Calculate the date seven days ago from today
            const sevenDaysAgo = new Date();
            sevenDaysAgo.setDate(sevenDaysAgo.getDate() - 7);

            // Filter orders with updatedAt within the last seven days
            const filterRecentOrders = arr.filter(
                (order) => new Date(order.updatedAt) >= sevenDaysAgo
            );

            setRecentOrders(filterRecentOrders);
        };

        returnRecentOrders();
    }, [orders]);




    const renderComponents = () => {
        if (isDesktopOrLaptop) {
            return (
                <>
                    <Navigation />
                    <main className={'overViewContainer'}>
                        <section >
                            <StatsCardContainer />
                        </section>
                        <section className={'ordersSection'}>
                            <div>
                                <img src={Chart} alt='chart' style={{ width: '100%', }} />
                            </div>
                            <RecentTransactionsCard />
                        </section>
                        <section className={'ordersSection'}>
                            <RecentOrdersTable meta={meta} setMeta={setMeta} />
                            <ActiveOrders activeOrders={activeOrders} gettingActiveOrders={gettingOrders} title={'Active Orders'} />
                        </section>
                    </main>
                </>
            )
        }
        if (isTabletOrMobile) {
            return (
                <>
                    <Navigation />
                    <OverviewMobileNav />
                    <main className={'overViewContainer'}>
                        <section >
                            <StatsCardContainer />
                        </section>
                        <ActiveOrders activeOrders={recentOrders} gettingActiveOrders={gettingOrders} title={'Recent Orders'} />
                    </main>
                </>
            )
        }
    }


    return (
        <>
            {renderComponents()}

        </>
    )
}

export default connect(null, { getRevenues, getUsers, getTransactions, getOrders, getOrdersAll, getActiveOrders, getVendors })(Overview)