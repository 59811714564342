import React from 'react';
import '../../assets/styles/extraStyles.css';

const StatsCard = (props) => {
    return (
        <div className={'statsCardContainer'}>
            <div>
                <p className={'boldText f14'} style={{ color: '#7A7A9D' }}>{props.title}</p>
                <p className={'boldText f20'} style={{ color: '#38383A', marginBottom: '12px' }}>{props?.count}</p>
                <div className={'flexRow alignCenter'}>
                    <p className={'rateContainer f12 boldText'} style={{ color: props.rateColor, backgroundColor: props.rateBg }}>{props?.rate}%</p>
                    <span className={'semiBoldText'} style={{ fontSize: '10px', color: "#7A7A9D" }}>since last month</span>
                </div>
            </div>
            <div className={'iconContainer'} style={{ backgroundColor: props?.bgColor }}>
                <img src={props?.icon} alt='icon' />
            </div>
        </div>
    )
}

export default StatsCard