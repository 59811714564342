import React, { useState } from 'react'
import { ArrowDown } from '../assets/images/icons';
import './utils.css';
const CityDropDown = (props) => {
    const [open, setOpen] = useState(false)
    const { selectData, data } = props

    const toggler = () => {
        setOpen(previousState => !previousState);
    }


    return (
        <>
            <div className={'flexRow alignCenter justifyBetween customSelectContainer'} onClick={toggler}>
                <div>
                    <span className={'f14 regularText'} style={{ color: '#999CA0', marginBottom: '0' }}>{data ? data : 'Select option'}</span>
                </div>
                <div>
                    <img src={ArrowDown} alt={'icon'} />
                </div>
                {open &&
                    <ul className={'customSelectListContainer'}>
                        {props.options?.map((item, index) => {
                            return (
                                <li className={'customSelectListItem regularText f16'}
                                    onClick={() => selectData(item)} key={index}
                                    style={{ color: '#000000', cursor: 'pointer' }}
                                >{item?.name}</li>
                            )
                        })}
                    </ul>
                }
            </div>
        </>
    )
}

export default CityDropDown;