import React from 'react';
import '../../../assets/styles/userManagementStyles.css';
import { SlClose } from 'react-icons/sl';
import { RxDotsVertical } from 'react-icons/rx';
import { BiFile, BiUserCircle } from 'react-icons/bi';
import { useState } from 'react';
import UserCardContainer from './UserCardContainer';
import { useDispatch, useSelector } from 'react-redux';
import Skeleton from 'react-loading-skeleton';
import moment from 'moment';
import { Logo } from '../../../assets/images/Index';
import { getUserOrderHistory } from '../../../redux/actions/UsersActions';
import OrderHistoryCard from './OrderHistoryCard';


const tabs = ['About', 'Order history']

const ViewModal = (props) => {
    const { onDecline } = props
    const [selectedTab, setSelectedTab] = useState('About')
    const { gettingUserProfile, userProfile, orderHistory, gettingOrderHistory } = useSelector(state => state.users)
    console.log(userProfile, 'us')
    const dispatch = useDispatch()

    const selectTab = (val) => {
        setSelectedTab(val)
        dispatch(getUserOrderHistory(userProfile?.id))

    }

    const formattedDate = moment(userProfile?.createdAt).format('YYYY-MM-DD')
    const renderAvatar = () => {
        if (userProfile?.avatar) {
            return userProfile?.avatar
        }
        return Logo
    }

    const renderOrders = () => {
        if (gettingOrderHistory) {
            return <Skeleton />
        }
        if (orderHistory?.length !== 0) {
            return orderHistory?.map((item) => {
                return (
                    <div key={item?.id}>
                        <OrderHistoryCard item={item} />
                    </div>
                )
            })

        }
        if (orderHistory?.length === 0) {
            return <>
                <p>No data</p>
            </>
        }
    }

    return (
        <section className={'userModalContainer'}>
            <div className={'userModalContent'}>
                <div className={'flexRow alignCenter justifyBetween'}>
                    <div>
                        <h3 className={'f24 boldText'}>User profile</h3>
                        <p className={'f16 regularText'}>An overview of the users activities</p>
                    </div>
                    <div>
                        <SlClose color='#F96868' size={30} onClick={onDecline} />
                    </div>
                </div>
                <div className={'flexRow alignCenter justifyBetween'} style={{ paddingBottom: '24px', borderBottom: '1px solid #D6D6D8', margin: '40px 0 30px 0' }}>
                    <div className={'flexRow'}>
                        {gettingUserProfile ? <Skeleton className={'userProfileAvatar'} style={{ width: "50px", height: '50px', borderRadius: '100px' }} /> :
                            <div className={'userProfileAvatar'}>
                                <img src={renderAvatar()} alt='avatar' style={{ width: '100%', height: '100%', objectFit: 'cover', borderRadius: '100px' }} />
                            </div>}
                        {gettingUserProfile ? <Skeleton style={{ height: "40px", width: '150px' }} /> :
                            <>
                                <div>

                                    <p className={'f20 boldText'} style={{ color: '#38383A' }}>{userProfile?.firstName || 'Null'}{" "} {userProfile?.lastName || 'Null'}</p>
                                    <p className={'f16 mediumText'} style={{ color: '#919294' }}>Joined {formattedDate}</p>

                                </div>

                                <div className={'userProfileStatus'}>
                                    <span className={'f12 boldText'}>{userProfile?.status}</span>
                                </div>
                            </>}
                    </div>
                    {/* <div className={'actionButton'}>
                        <RxDotsVertical />
                    </div> */}
                </div>
                <div className={'userTabsContainer'}>
                    {/* {tabs.map((item, index) => {
                        return (
                            <li key={index} className={`f16 semiBoldText ${item === selectedTab ? 'activeProfileTab' : 'inactiveProfileTab'} `} onClick={() => selectTab(item)} style={{ cursor: 'pointer' }}> {item}</li>
                        )
                    })} */}
                    {
                        tabs.map((item, index) => {
                            if (userProfile?.role === 'CUSTOMER') {
                                // Render both tabs
                                return (
                                    <li
                                        key={index}
                                        className={`f16 semiBoldText ${item === selectedTab ? 'activeProfileTab' : 'inactiveProfileTab'
                                            } `}
                                        onClick={() => selectTab(item)}
                                        style={{ cursor: 'pointer' }}
                                    >
                                        {item}
                                    </li>
                                );
                            } else {
                                // Render only one tab
                                if (item === selectedTab) {
                                    return (
                                        <li
                                            key={index}
                                            className={`f16 semiBoldText activeProfileTab`}
                                            onClick={() => selectTab(item)}
                                            style={{ cursor: 'pointer' }}
                                        >
                                            {item}
                                        </li>
                                    );
                                } else {
                                    return null; // Or any other action you want for the false condition
                                }
                            }
                        })
                    }
                </div>
                {selectedTab === 'About' &&
                    <div>
                        <UserCardContainer title={'User Information'} icon={<BiUserCircle color='#FFB049' style={{ marginRight: '10px' }} />}>
                            <section>
                                <div className={'flexRow alignCenter justifyBetween'} style={{ marginBottom: '24px' }}>
                                    <p className={'boldText f16'} style={{ color: '#38383A' }}>User type</p>
                                    <p className={'semiBoldText f16'} style={{ color: '#919294' }}>{userProfile?.role}</p>
                                </div>
                                <div className={'flexRow alignCenter justifyBetween'} style={{ marginBottom: '24px' }}>
                                    <p className={'boldText f16'} style={{ color: '#38383A' }}>Email address</p>
                                    <p className={'semiBoldText f16'} style={{ color: '#919294' }}>{userProfile?.email || 'Nil'}</p>
                                </div>
                                <div className={'flexRow alignCenter justifyBetween'} style={{ marginBottom: '24px' }}>
                                    <p className={'boldText f16'} style={{ color: '#38383A' }}>Mobile number</p>
                                    <p className={'semiBoldText f16'} style={{ color: '#919294' }}>{userProfile?.phone}</p>
                                </div>
                                <div className={'flexRow alignCenter justifyBetween'} style={{ marginBottom: '24px' }}>
                                    <p className={'boldText f16'} style={{ color: '#38383A' }}>State of residence</p>
                                    <p className={'semiBoldText f16'} style={{ color: '#919294' }}>{userProfile?.addresses[0]?.state}</p>
                                </div>
                                {userProfile?.role === 'CUSTOMER' &&
                                    <div className={'flexRow alignCenter justifyBetween'} style={{ marginBottom: '24px' }}>
                                        <p className={'boldText f16'} style={{ color: '#38383A' }}>Delivery address</p>
                                        <p className={'semiBoldText f16'} style={{ color: '#919294' }}>{userProfile?.addresses[0]?.address}</p>
                                    </div>}
                                {userProfile?.role === 'VENDOR' &&
                                    <div className={'flexRow alignCenter justifyBetween'} style={{ marginBottom: '24px' }}>
                                        <p className={'boldText f16'} style={{ color: '#38383A' }}>Address</p>
                                        <p className={'semiBoldText f16'} style={{ color: '#919294' }}>{userProfile?.role}</p>
                                    </div>}
                            </section>
                        </UserCardContainer>
                    </div>}
                {selectedTab === 'Order history' &&
                    <div>
                        <UserCardContainer title={'All Orders'} icon={<BiFile color='#FFB049' style={{ marginRight: '10px' }} />}>
                            <div style={{ maxHeight: '400px', overflow: "scroll" }}>
                                {renderOrders()}
                            </div>

                        </UserCardContainer>
                    </div>
                }
            </div>
        </section >
    )
}

export default ViewModal