
import React from 'react'
import AuthContainer from '../../components/auth/AuthContainer'
import AuthHeader from '../../components/auth/AuthHeader'
import CustomButton from '../../utils/CustomButton'
import '../../assets/styles/authStyles.css';
import { useLocation, useNavigate } from 'react-router-dom'
import { AdminImage } from '../../assets/images/Index';

const ContactAdmin = (props) => {
    const params = useLocation()
    const message = params?.state?.message;
    const token = params?.state?.token
    const navigate = useNavigate()

    const toVerifyOtp = () => {
        navigate('/forgotPasswordOtp', {
            state: { token }
        })
    }


    return (
        <>
            <AuthContainer back={true}>
                <section style={{ display: 'flex', flexDirection: 'column', textAlign: 'center', }}>
                    <div>
                        <img src={AdminImage} alt='admin' style={{ width: '50%', }} />
                    </div>
                    <AuthHeader title={'Contact Dado Administrator'} subTitle={message} />
                    <div style={{ margin: '40px auto 0 auto', width: '100%', }}>
                        <CustomButton title={'Proceed to reset'} onClick={toVerifyOtp} bgColor={'#FFA024'} />
                    </div>
                </section>
            </AuthContainer>
        </>
    )
}

export default ContactAdmin;