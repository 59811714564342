import React from 'react'
import { RecentTransIcon } from '../../../assets/images/icons'
import { formatAmount } from '../../../utils/Helpers'

const RecentTransactionsRow = (props) => {
    const { data } = props
    // console.log(data)

    return (
        <section className={'flexRow alignCenter justifyBetween'} style={{ marginBottom: '16px' }}>
            <div className={'flexRow alignCenter'}>
                <img src={data?.order?.user?.avatar} alt='icon' style={{ marginRight: '8px', height: '40px', width: '40px', borderRadius: '50%', border: '1px solid #ffa024' }} />
                <div>
                    <p className={'boldText f12'}>
                        Payment from
                        <span style={{ color: '#919294', textTransform: 'capitalize' }}>{' ' + data?.order?.user?.firstName}</span>
                    </p>
                    <p className={'semiBoldText f10'}>
                        For order
                        <span style={{ color: '#FFA024' }}>{' ' + data?.orderId}</span>
                    </p>
                </div>
            </div>
            <div>
                <p className={'boldText f12'}>₦{formatAmount(data?.amount)}</p>
            </div>
        </section>
    )
}

export default RecentTransactionsRow