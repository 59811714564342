import React, { useState } from "react";
import { settingsTabs } from "../utils/Helpers";

const SettingsNav = ({ selectedTab, toggleTabs }) => {
  const renderTabs = () => {
    return settingsTabs?.map((item, index) => {
      return (
        <li key={index} className={`f14 regularText ${selectedTab === item ? "activeFilter" : "inactiveFilter"}`} onClick={() => toggleTabs(item)} style={{ cursor: "pointer" }}>
          {item}
        </li>
      );
    });
  };
  return (
    <nav className={"usersNavContainer"}>
      <section>
        <h3 className={"f24 boldText "} style={{ color: "#16192C" }}>
          Users
        </h3>
        <p className={"f16 regularText"} style={{ color: "#16192C" }}>
          Create & Manage users{" "}
        </p>
      </section>
      <section className={"flexRow alignCenter"} style={{ gap: "12px", marginTop: "20px" }}>
        {renderTabs()}
      </section>
    </nav>
  );
};

export default SettingsNav;
