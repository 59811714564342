import React, { useState } from "react";
import { useMediaQuery } from "react-responsive";
import CustomInput from "../../../utils/CustomInput";
import CustomButton from "../../../utils/CustomButton";
import { CloseIcon } from "../../../assets/images/icons";
import http from "../../../utils/utils";
import { showSuccess } from "../../../utils/Toast";
import OperationsDropDown from "./OperationsDropDown";
import { useDispatch, useSelector } from "react-redux";
import { getAdminProfile } from "../../../redux/actions/SettingsActions";

const EditProfileModal = (props) => {
  const { onDecline } = props;
  const { operationLocations } = useSelector((state) => state.settings);
  const [state, setState] = useState({
    loading: false,
  });
  const [location, setLocation] = useState("");
  const selectLocation = (val) => {
    setLocation(val);
  };

  const isDesktopOrLaptop = useMediaQuery({
    query: "(min-width: 1430px)",
  });

  const disabled = () => {
    if (location !== "") {
      return false;
    }
    return true;
  };
  const dispatch = useDispatch();

  const updateLocation = async () => {
    const id = location?.id;
    const obj = { operation_location_id: id };
    setState({ ...state, loading: true });
    try {
      const res = await http.put(`admin/auth/profile/update`, obj);
      setState({ ...state, loading: false });
      showSuccess(res?.message);
      onDecline();
      dispatch(getAdminProfile());
    } catch (error) {
      console.log(error);
      setState({ ...state, loading: false });
      // showError()
    }
  };

  return (
    <section className={`${isDesktopOrLaptop ? "modalContainer" : "mobileModalContainer"}`}>
      <div className={`${isDesktopOrLaptop ? "createOrderModal" : "mobileCreateOrderModal"}`} style={{ maxWidth: isDesktopOrLaptop ? "633px" : "100%", width: isDesktopOrLaptop ? "633px" : "100%" }}>
        <div className={"createOrderModalHeader flexRow alignCenter justifyBetween"}>
          <p className={"f20 boldText"}>Select Operation Location</p>
          <span onClick={onDecline}>
            <img src={CloseIcon} alt="icon" />
          </span>
        </div>
        <div>
          <OperationsDropDown options={operationLocations} data={location} selectData={selectLocation} />
        </div>
        <div className={"createOrderModalFooter flexRow alignCenter justifyBetween"} style={{ marginTop: "40px" }}>
          <div />
          <div className={"flexRow alignCenter footerButtons"} style={{ paddingBottom: !isDesktopOrLaptop && "40px" }}>
            <div style={{ marginRight: "8px" }}>
              <CustomButton title={"Cancel"} customStyle={"createOrderCancelBtn"} onClick={onDecline} height={"40px"} width={"88px"} />
            </div>
            <div>
              <CustomButton title={"Save"} bgColor={"#FFA024"} disabled={disabled()} height={"40px"} width={"127px"} onClick={updateLocation} loading={state.loading} />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default EditProfileModal;
