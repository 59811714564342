import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { DraftIcon } from '../../../assets/images/icons'
import CreateOrderForm from '../../../components/dashboard/orderManagement/createOrderForms/CreateOrderForm'
import { getOrders, getOrdersByStatus } from '../../../redux/actions/OrdersAction'
import { getUsers, getVendors } from '../../../redux/actions/OtherActions'
import { getRevenues, getTransactions } from '../../../redux/actions/TransactionsActions'
import { ORDER } from '../../../redux/Types'
import BackComponent from '../../../utils/BackComponent'
import CustomButton from '../../../utils/CustomButton'
import { showError, showSuccess } from '../../../utils/Toast'
import http from '../../../utils/utils'
import Navigation from '../../../widgets/Navigation'
import { BiLeftArrowAlt, BiRightArrowAlt } from 'react-icons/bi'
import { useMediaQuery } from 'react-responsive'




const CreateOrder = (props) => {

    const isDesktopOrLaptop = useMediaQuery({ query: '(min-width: 1200px)' })
    const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1200px)' })
    const [currentIndex, setCurrentIndex] = useState(0)
    const navigate = useNavigate()
    const { orderSummary } = useSelector(state => state.others)
    // console.log(orderSummary, 'ff');
    const dispatch = useDispatch()

    const next = () => {
        setCurrentIndex(currentIndex + 1)
        // dispatch({type:ORDER, payload:})
    }
    const back = () => {
        setCurrentIndex(currentIndex - 1)
    }
    const cancel = () => {
        navigate(-1)
    }
    const updateStore = () => {
        dispatch(getOrders())
        dispatch(getOrdersByStatus())
        dispatch(getVendors())
        dispatch(getUsers())
        dispatch(getTransactions())
        dispatch(getRevenues())
    }

    const submitOrder = async () => {
        try {
            const res = await http.post(`admin/order/create`, orderSummary)
            // console.log(res, 'order created')
            showSuccess(res?.message)
            updateStore()
            navigate('/orderManagement')

        } catch (error) {
            // console.log(error, 'order error')
            showError(error[1].message)
        }
    }

    const ButtonGroups = [
        [
            { label: 'Cancel', onClick: () => cancel(), style: 'Back', backIcon: false, },
            { label: 'Meal setup & Delivery information', onClick: () => setCurrentIndex(currentIndex + 1), style: 'Next', nextIcon: true }
        ],
        [
            { label: 'About customer', onClick: () => setCurrentIndex(currentIndex - 1), style: 'Back', backIcon: true, },
            { label: 'Billing & Payment', onClick: () => setCurrentIndex(currentIndex + 1), style: 'Next', nextIcon: true }
        ],
        [
            { label: 'Meal setup & Delivery information', onClick: () => setCurrentIndex(currentIndex - 1), style: 'Back', backIcon: true, },
            { label: 'Order summary', onClick: () => setCurrentIndex(currentIndex + 1), style: 'Next', nextIcon: true }
        ],
        [
            { label: 'Billing & Payment', onClick: () => setCurrentIndex(currentIndex - 1), style: 'Back', backIcon: true, },
            { label: 'Create order', onClick: () => submitOrder(), style: 'Next', nextIcon: true }
        ],
        // Add more button groups here
    ];

    // const handleClick = () => {
    //     setCurrentIndex(currentIndex + 1 >= ButtonGroups.length ? 0 : currentIndex + 1);
    // };


    const renderButtons = () => {
        return ButtonGroups[currentIndex].map(({ label, onClick, style, backIcon, nextIcon }, i) => (
            <button key={i} onClick={onClick} className={style === 'Back' ? 'createOrderBack' : 'createOrderNext'}>
                {backIcon && <BiLeftArrowAlt size={'14px'} />}
                <p className={'semiBoldText f14'}>{label}</p>
                {nextIcon && <BiRightArrowAlt size={'14px'} />}
            </button>
        ))

    }


    return (
        <>
            {isDesktopOrLaptop &&
                <main className={'createOrderContainer '}  >
                    <section className={'flexRow alignCenter justifyBetween'} style={{ zIndex: '20', }}>
                        <div className={'flexRow alignCenter'}>
                            <BackComponent />
                            <div style={{ marginLeft: '24px', }}>
                                <h3 className={'boldText f24'} >Create order</h3>
                                <p>Create & Manage orders</p>
                            </div>
                        </div>
                        <div className={'flexRow alignCenter'}>
                        </div>
                    </section>
                    <section className={'createOrderModal'}>
                        <CreateOrderForm currentIndex={currentIndex} />
                    </section>
                    <section className={'createOrderFooter'}>
                        <div className={'createOrderModalFooter flexRow alignCenter justifyBetween'}>
                            <div />
                            <div className={'flexRow alignCenter footerButtons'}>
                                {renderButtons()}
                            </div>
                        </div>
                    </section>
                </main>}
            {isTabletOrMobile && <>
                <main className={'mobileCreateOrderContainer '} >
                    <section className={'flexRow alignCenter justifyBetween'}>
                        <div className={'flexRow alignCenter'}>
                            <div>
                                <BackComponent />
                            </div>
                            <div style={{ marginLeft: '24px', justifySelf: 'center', }}>
                                <h3 className={'boldText f18 textCenter'} >Create order</h3>
                            </div>
                        </div>
                        <div className={'flexRow alignCenter'}>
                        </div>
                    </section>
                    <section className={'mobileCreateOrderModal'}>
                        <CreateOrderForm currentIndex={currentIndex} />
                    </section>

                    <section className={'mobileCreateOrderFooter'}>
                        <div className={'flexRow alignCenter justifyBetween'} style={{ width: "100%" }}>
                            {renderButtons()}
                        </div>
                    </section>
                </main>

            </>}

        </>
    )
}

export default CreateOrder