import React from 'react'
import MoreCards from '../../../components/dashboard/more/MoreCards'
import { IoServerOutline } from 'react-icons/io5'
import Navigation from '../../../widgets/Navigation'
import { FiUsers } from 'react-icons/fi'

const More = () => {
    return (
        <>
            <section className={'moreGrid'}>
                <MoreCards title={'Products'} icon={<IoServerOutline />} path={'/products'} />
                <MoreCards title={'Users'} icon={<FiUsers />} path={'/userManagement'} />
            </section>
            <Navigation />
        </>

    )
}

export default More