// admin / users / rider / invite

import React, { useState } from 'react'
import { CloseIcon } from '../../../assets/images/icons'
import CustomInput from '../../../utils/CustomInput'
import CustomButton from '../../../utils/CustomButton'
import http from '../../../utils/utils'
import { showError, showSuccess } from '../../../utils/Toast'
import { useMediaQuery } from 'react-responsive'

const InviteRiderModal = (props) => {
    const { onDecline } = props
    const [state, setState] = useState({
        rider_email: ''
    })
    const onChangeEmail = (e) => {
        setState({ ...state, rider_email: e.target.value })
    }

    const isDesktopOrLaptop = useMediaQuery({
        query: '(min-width: 1430px)'
    })

    const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1430px)' })

    const disabled = () => {
        if (state.rider_email !== '') {
            return false
        }
        return true
    }

    const inviteUser = async () => {
        const obj = { rider_email: state.rider_email }
        setState({ ...state, loading: true })
        try {
            const res = await http.post(`admin/users/rider/invite`, obj)
            setState({ ...state, loading: false })
            showSuccess(res?.message)
            onDecline()
        } catch (error) {
            console.log(error)
            setState({ ...state, loading: false })
            // showError()

        }
    }


    return (

        <>
            {isDesktopOrLaptop &&
                <section className={'modalContainer'}>
                    <div className={'createOrderModal'} style={{ maxWidth: '633px', width: '633px' }}>
                        <div className={'createOrderModalHeader flexRow alignCenter justifyBetween'}>
                            <p className={'f20 boldText'}>Invite user</p>
                            <span onClick={onDecline}>
                                <img src={CloseIcon} alt='icon' />
                            </span>
                        </div>
                        <div>
                            <CustomInput label={'Enter user email'} placeholder={' '}
                                value={state.rider_email}
                                onChange={onChangeEmail}

                            />
                        </div>
                        <div className={'createOrderModalFooter flexRow alignCenter justifyBetween'} style={{ marginTop: '40px' }}>
                            <div />
                            <div className={'flexRow alignCenter footerButtons'} >
                                <div style={{ marginRight: '8px' }}>
                                    <CustomButton title={'Cancel'} customStyle={'createOrderCancelBtn'} onClick={onDecline} height={'40px'} width={'88px'} />
                                </div>
                                <div >
                                    <CustomButton title={'Invite'} bgColor={'#FFA024'} disabled={disabled()} height={'40px'} width={'127px'} onClick={inviteUser} />
                                </div>

                            </div>
                        </div>
                    </div>
                </section>
            }
            {isTabletOrMobile &&
                <section className={'mobileModalContainer'}>
                    <div className={'mobileCreateOrderModal'} style={{ width: "100%", paddingBottom: '100px' }}>
                        <div className={'createOrderModalHeader flexRow alignCenter justifyBetween'}>
                            <p className={'f20 boldText'}>Invite Rider</p>
                            <span onClick={onDecline}>
                                <img src={CloseIcon} alt='icon' />
                            </span>
                        </div>
                        <div>
                            <CustomInput label={'Enter user email'} placeholder={' '}
                                value={state.rider_email}
                                onChange={onChangeEmail}

                            />
                        </div>
                        <div className={' flexRow alignCenter justifyBetween'} style={{ marginTop: '40px' }}>


                            <div style={{ marginRight: '8px' }}>
                                <CustomButton title={'Cancel'} customStyle={'createOrderCancelBtn'} onClick={onDecline} height={'40px'} />
                            </div>
                            <div >
                                <CustomButton title={'Invite'} bgColor={'#FFA024'} disabled={disabled()} height={'40px'} onClick={inviteUser} />
                            </div>


                        </div>
                    </div>
                </section>
            }
        </>
    )
}

export default InviteRiderModal