import React from 'react'
import { shortenXterLength } from '../../../utils/Helpers'
import moment from 'moment'

const OrderHistoryCard = (props) => {
    const { item } = props
    const formattedDate = moment(item?.createdAt).format('YYYY-MM-DD | LT');
    return (
        <section className={'orderHistoryCardContainer'}>
            <div className={'flexRow justifyBetween'} style={{ borderBottom: '0.5px solid #D6D6D8', paddingBottom: '8px', marginBottom: '16px' }}>
                <div>
                    <p className={'f14 boldText'}>Order {item?.id}</p>
                    <p className={'f10 regularText'} style={{ color: '#38383A' }}>{formattedDate}</p>
                </div>
                <div className={'orderHistoryCardStatusContainer'}>
                    <p className={'f10 boldText'} style={{ textTransform: 'capitalize', color: '#4C6FFF' }}>{item?.orderStatus}</p>
                </div>
            </div>
            <div >
                <div style={{ marginBottom: '8px' }}>
                    <p className={'f14 boldText'}>Vendor</p>
                    <p className={'f14 semiBoldText'}>{item?.originBusinessName}</p>
                </div>
                <div className={'flexRow alignCenter'} style={{ marginBottom: '12px' }}>
                    <ul style={{ width: '90%' }}>
                        {item?.orderItems?.map((list) => {
                            return <li className={'flexRow alignCenter justifyBetween'} style={{ marginBottom: '4px' }}>
                                <p className={'f12 regularText'}>{list?.name}</p>
                                <p className={'f12 regularText'} style={{ color: '#696A6B' }}>₦{list?.price}</p>
                            </li>
                        })}
                    </ul>
                    <div style={{ width: '10%' }}>
                        <p className={'f14 boldText'}></p>
                    </div>
                </div>
            </div>
            <div>
                <p className={'f14 boldText'}>Delivery address</p>
                <p className={'f14 regularText'}>{item?.shipping?.destinationAddress}</p>
            </div>
            {/* <div>
                <p className={'f14 boldText'}>Pricing</p>
                <div className={'flexRow alignCenter justifyBetween'}>
                    <p className={'f12 regularText'}>Meal pricing</p>
                    <p className={'f12 regularText'}></p>
                </div>
                <div className={'flexRow alignCenter justifyBetween'}>
                    <p className={'f12 regularText'}>Delivery price</p>
                    <p className={'f12 regularText'}></p>
                </div>
            </div> */}
        </section>
    )
}

export default OrderHistoryCard