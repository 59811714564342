import { ALL_PRODUCTS, ALL_PRODUCTS_FAIL, ALL_PRODUCTS_SUCCESSFUL, SINGLE_PRODUCT, SINGLE_PRODUCT_FAIL, SINGLE_PRODUCT_SUCCESSFUL, STORE_PRODUCT } from "../Types";


const INITIAL_STATE = {
    error: [],
    gettingProducts: false,
    products: {},
    storedProduct: {},
    gettingSingleProduct: false,
    singleProduct: {},
};

export default (state = INITIAL_STATE, { type, payload }) => {
    switch (type) {
        case ALL_PRODUCTS:
            return { ...state, gettingProducts: true };
        case ALL_PRODUCTS_SUCCESSFUL:
            return { ...state, gettingProducts: false, products: payload }
        case ALL_PRODUCTS_FAIL:
            return { ...state, gettingProducts: false, error: payload }
        case SINGLE_PRODUCT:
            return { ...state, gettingSingleProduct: true };
        case SINGLE_PRODUCT_SUCCESSFUL:
            return { ...state, gettingSingleProduct: false, singleProduct: payload }
        case SINGLE_PRODUCT_FAIL:
            return { ...state, gettingSingleProduct: false, error: payload }
        case STORE_PRODUCT:
            return { ...state, storedProduct: payload }
        default:
            return state;
    }
};