import React from 'react';
import { useSelector } from 'react-redux';
import '../../../assets/styles/orderManagementStyles.css';
import ActiveOrdersCard from './ActiveOrdersCard';
import NoData from '../../../assets/images/directions/bro.png';
import Loader from '../../../utils/Loader';
import Skeleton from 'react-loading-skeleton';




const ActiveOrders = (props) => {

    const { activeOrders, searchValue, searchedRestaurants, title } = props
    const { gettingOrders } = useSelector(state => state.orders)
    // console.log(activeOrders, 'me')

    const renderActiveOrders = () => {
        let arr = activeOrders
        if (arr && arr?.length !== 0) {
            if (searchValue) {
                arr = searchedRestaurants || []
            }
            return arr?.map((item, index) => {
                return (
                    <div key={index}>
                        <ActiveOrdersCard data={item} />
                    </div>
                )
            })
        }
        if (gettingOrders) {
            return (
                <>
                    <Skeleton height={200} style={{ borderRadius: "10px", marginBottom: '20px' }} />
                    <Skeleton height={200} style={{ borderRadius: "10px", marginBottom: '20px' }} />
                    <Skeleton height={200} style={{ borderRadius: "10px", marginBottom: '20px' }} />
                </>
            )
        }
        if (arr && arr?.length === 0) {
            return (
                <div style={{ display: 'flex', flexDirection: 'column', gap: '24px', alignItems: "center", justifyContent: "center", paddingTop: '40px' }}>
                    <img src={NoData} alt='bro' />
                    <p className={'boldText f16'}>No data</p>
                </div>
            )
        }
    }


    return (
        <section className={'activeOrdersContainer'}>
            <div className={'flexRow alignCenter justifyBetween activeOrdersHeader'}>
                <p className={'f16 boldText'} style={{ color: '#1F2D3D' }}>{title}</p>
                {props.total &&
                    <p className={'f14 semiBoldText'} style={{ color: ' #696A6B' }}>{activeOrders?.length} Orders</p>}
            </div>

            <div className={'activeOrderContent'}>
                {renderActiveOrders()}
            </div>
        </section>
    )
}

export default ActiveOrders