import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { useMediaQuery } from 'react-responsive'
import { AboutUserIcon, BillingIcon, Delivery, MealSetupIcon, Message, Payment } from '../../../../assets/images/icons'
import { ORDER } from '../../../../redux/Types'
import { formatAmount } from '../../../../utils/Helpers'
import OrderSummaryCards from '../OrderSummaryCards'

const OrderSummary = (props) => {

    const isDesktopOrLaptop = useMediaQuery({ query: '(min-width: 1430px)' })
    const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1430px)' })



    const { summary, obj, currentIndex, serviceCharge, getServiceCharge } = props
    const dispatch = useDispatch()
    console.log(summary, 'dd')

    useEffect(() => {
        getServiceCharge()
        if (currentIndex <= 3) {
            dispatch({ type: ORDER, payload: obj })
        }
    }, [dispatch])


    const returnTotal = () => {
        if (summary) {
            return formatAmount(Number(summary?.delivery_fee) + summary?.mealCost + serviceCharge?.serviceFee)

        }
    }

    return (

        <>
            {isDesktopOrLaptop &&
                <section style={{ padding: '24px 40px 40px 40px' }}>
                    <div style={{ paddingBottom: '24px' }}>
                        <h3 className={'textCenter f24 boldText'} style={{ color: '#565758' }}>Order summary</h3>
                    </div>

                    <div className={'orderDetailsGrid'}>
                        <OrderSummaryCards title={'About customer'} icon={AboutUserIcon}>
                            <div className={'flexRow alignCenter justifyBetween'} style={{ marginBottom: '16px' }}>
                                <p className={'f16 boldText'} style={{ color: ' #38383A' }}>Fullname</p>
                                <p className={'semiBoldText f16'} style={{ color: '#919294' }}>{summary?.customer_fullname}</p>
                            </div>
                            <div className={'flexRow alignCenter justifyBetween'} style={{ marginBottom: '16px' }}>
                                <p className={'f16 boldText'} style={{ color: ' #38383A' }}>Mobile number</p>
                                <p className={'semiBoldText f16'} style={{ color: '#919294' }}>{summary?.customer_phone_number}</p>
                            </div>
                        </OrderSummaryCards>
                        <OrderSummaryCards title={'Delivery information'} icon={Delivery} >
                            <div className={'flexRow alignCenter justifyBetween'} style={{ marginBottom: '16px' }}>
                                <p className={'f16 boldText'} style={{ color: ' #38383A' }}>State of residence</p>
                                <p className={'semiBoldText f16'} style={{ color: '#919294' }}>{summary?.state_of_residence}</p>
                            </div>
                            <div className={'flexRow alignCenter justifyBetween'} style={{ marginBottom: '16px' }}>
                                <p className={'f16 boldText'} style={{ color: ' #38383A' }}>Delivery address</p>
                                <p className={'semiBoldText f16'} style={{ color: '#919294' }}>{summary?.delivery_address}</p>
                            </div>
                        </OrderSummaryCards>
                        <OrderSummaryCards title={'Meal setup'} icon={MealSetupIcon}>
                            <p className={'boldText f14'} style={{ color: '#38383A', marginBottom: '20px' }}>Vendor - {summary?.business_name}</p>
                            {summary?.order_items?.map((item, index) => {
                                return (
                                    <li className={'flexRow alignCenter justifyBetween'} key={index} style={{ marginBottom: '16px' }}>
                                        <div>
                                            <p className={'boldText f14'} style={{ color: '#696A6B' }}>{item?.name}</p>
                                            <span className={'f14 semiBoldText'} style={{ color: '#919294' }}>₦{formatAmount(item?.price)}</span>
                                        </div>
                                        <p className={'f14 semiBoldText'} style={{ color: '#919294' }}>x{item?.quantity}</p>
                                    </li>
                                )
                            })}
                        </OrderSummaryCards>
                        <OrderSummaryCards title={'Message for restaurant / courier'} icon={Message}>
                            <div>
                                <p className={'f14 semiBoldText'} style={{ marginBottom: "16px", color: "#38383A" }}>Message</p>
                                <p className={'f16 regularText'} style={{ fontStyle: 'italic', color: '#999CA0' }}>{summary?.message}</p>
                            </div>
                        </OrderSummaryCards>
                        <OrderSummaryCards title={'Billing'} icon={BillingIcon}>
                            <div className={'flexRow alignCenter justifyBetween'} style={{ marginBottom: '16px' }}>
                                <p className={'f16 boldText'} style={{ color: ' #38383A' }}>Meal cost</p>
                                <p className={'semiBoldText f16'} style={{ color: '#919294' }}>₦{formatAmount(summary?.mealCost)}</p>
                            </div>
                            <div className={'flexRow alignCenter justifyBetween'} style={{ marginBottom: '16px' }}>
                                <p className={'f16 boldText'} style={{ color: ' #38383A' }}>Service fee</p>
                                <p className={'semiBoldText f16'} style={{ color: '#919294' }}>₦{formatAmount(serviceCharge?.serviceFee)}</p>
                            </div>
                            <div className={'flexRow alignCenter justifyBetween'} style={{ marginBottom: '16px' }}>
                                <p className={'f16 boldText'} style={{ color: ' #38383A' }}>Delivery fee</p>
                                <p className={'semiBoldText f16'} style={{ color: '#919294' }}>₦{formatAmount(Number(summary?.delivery_fee))}</p>
                            </div>
                            <div className={'flexRow alignCenter justifyBetween'} style={{ marginBottom: '16px', borderBottom: ' 0.5px solid #E6E7E9', paddingBottom: '16px' }}>
                                <p className={'f16 boldText'} style={{ color: ' #38383A' }}>Discount(20%)</p>
                                <p className={'semiBoldText f16'} style={{ color: '#919294' }}>₦0</p>
                            </div>
                            <div className={'flexRow alignCenter justifyBetween'} style={{ marginBottom: '16px', }}>
                                <p className={'f16 boldText'} style={{ color: ' #38383A' }}>Grand total</p>
                                <p className={'semiBoldText f16'} style={{ color: '#919294' }}>₦{returnTotal()}</p>
                            </div>

                        </OrderSummaryCards>
                        <OrderSummaryCards title={'Payment method'} icon={Payment}>
                            <div className={'flexRow alignCenter justifyBetween'} style={{ marginBottom: '16px' }}>
                                <p className={'f16 boldText'} style={{ color: ' #38383A' }}>Payment method</p>
                                <p className={'semiBoldText f16'} style={{ color: '#919294' }}>{summary?.payment_method}</p>
                            </div>
                            <div className={'flexRow alignCenter justifyBetween'} style={{ marginBottom: '16px' }}>
                                <p className={'f16 boldText'} style={{ color: ' #38383A' }}>Payment status</p>
                                <p className={'semiBoldText f16'} style={{ color: '#919294' }}>{summary?.payment_status}</p>
                            </div>
                        </OrderSummaryCards>
                    </div>

                </section>
            }
            {isTabletOrMobile &&
                <section style={{ paddingBottom: '150px' }}>
                    <div style={{ paddingBottom: '24px' }}>
                        <h3 className={'textCenter f16 boldText'} style={{ color: '#565758' }}>Order summary</h3>
                    </div>

                    <div style={{ display: 'grid', gridTemplateColumns: '100%', gap: '16px' }}>
                        <OrderSummaryCards title={'About customer'} icon={AboutUserIcon}>
                            <div style={{ marginBottom: '16px' }}>
                                <p className={'f16 boldText'} style={{ color: ' #38383A' }}>Fullname</p>
                                <p className={'semiBoldText f16'} style={{ color: '#919294' }}>{summary?.customer_fullname}</p>
                            </div>
                            <div style={{ marginBottom: '16px' }}>
                                <p className={'f16 boldText'} style={{ color: ' #38383A' }}>Mobile number</p>
                                <p className={'semiBoldText f16'} style={{ color: '#919294' }}>{summary?.customer_phone_number}</p>
                            </div>
                        </OrderSummaryCards>
                        <OrderSummaryCards title={'Delivery information'} icon={Delivery} >
                            <div style={{ marginBottom: '16px' }}>
                                <p className={'f16 boldText'} style={{ color: ' #38383A' }}>State of residence</p>
                                <p className={'semiBoldText f16'} style={{ color: '#919294' }}>{summary?.state_of_residence}</p>
                            </div>
                            <div style={{ marginBottom: '16px' }}>
                                <p className={'f16 boldText'} style={{ color: ' #38383A' }}>Delivery address</p>
                                <p className={'semiBoldText f16'} style={{ color: '#919294' }}>{summary?.delivery_address}</p>
                            </div>
                        </OrderSummaryCards>
                        <OrderSummaryCards title={'Meal setup'} icon={MealSetupIcon}>
                            <p className={'boldText f14'} style={{ color: '#38383A', marginBottom: '20px' }}>Vendor - {summary?.business_name}</p>
                            {summary?.order_items?.map((item, index) => {
                                return (
                                    <li className={'flexRow alignCenter justifyBetween'} key={index} style={{ marginBottom: '16px' }}>
                                        <div>
                                            <p className={'boldText f14'} style={{ color: '#696A6B' }}>{item?.name}</p>
                                            <span className={'f14 semiBoldText'} style={{ color: '#919294' }}>₦{formatAmount(item?.price)}</span>
                                        </div>
                                        <p className={'f14 semiBoldText'} style={{ color: '#919294' }}>x{item?.quantity}</p>
                                    </li>
                                )
                            })}
                        </OrderSummaryCards>
                        <OrderSummaryCards title={'Message for restaurant / courier'} icon={Message}>
                            <div>
                                <p className={'f14 semiBoldText'} style={{ marginBottom: "16px", color: "#38383A" }}>Message</p>
                                <p className={'f16 regularText'} style={{ fontStyle: 'italic', color: '#999CA0' }}>{summary?.message}</p>
                            </div>
                        </OrderSummaryCards>
                        <OrderSummaryCards title={'Billing'} icon={BillingIcon}>
                            <div style={{ marginBottom: '16px' }}>
                                <p className={'f16 boldText'} style={{ color: ' #38383A' }}>Meal cost</p>
                                <p className={'semiBoldText f16'} style={{ color: '#919294' }}>₦{formatAmount(summary?.mealCost)}</p>
                            </div>
                            <div style={{ marginBottom: '16px' }}>
                                <p className={'f16 boldText'} style={{ color: ' #38383A' }}>Service fee</p>
                                <p className={'semiBoldText f16'} style={{ color: '#919294' }}>₦{formatAmount(serviceCharge?.serviceFee)}</p>
                            </div>
                            <div style={{ marginBottom: '16px' }}>
                                <p className={'f16 boldText'} style={{ color: ' #38383A' }}>Delivery fee</p>
                                <p className={'semiBoldText f16'} style={{ color: '#919294' }}>₦{formatAmount(Number(summary?.delivery_fee))}</p>
                            </div>
                            <div style={{ marginBottom: '16px', borderBottom: ' 0.5px solid #E6E7E9', paddingBottom: '16px' }}>
                                <p className={'f16 boldText'} style={{ color: ' #38383A' }}>Discount(20%)</p>
                                <p className={'semiBoldText f16'} style={{ color: '#919294' }}>₦0</p>
                            </div>
                            <div style={{ marginBottom: '16px', }}>
                                <p className={'f16 boldText'} style={{ color: ' #38383A' }}>Grand total</p>
                                <p className={'semiBoldText f16'} style={{ color: '#919294' }}>₦{returnTotal()}</p>
                            </div>

                        </OrderSummaryCards>
                        <OrderSummaryCards title={'Payment method'} icon={Payment}>
                            <div style={{ marginBottom: '16px' }}>
                                <p className={'f16 boldText'} style={{ color: ' #38383A' }}>Payment method</p>
                                <p className={'semiBoldText f16'} style={{ color: '#919294' }}>{summary?.payment_method}</p>
                            </div>
                            <div style={{ marginBottom: '16px' }}>
                                <p className={'f16 boldText'} style={{ color: ' #38383A' }}>Payment status</p>
                                <p className={'semiBoldText f16'} style={{ color: '#919294' }}>{summary?.payment_status}</p>
                            </div>
                        </OrderSummaryCards>
                    </div>

                </section>
            }
        </>



    )
}

export default OrderSummary