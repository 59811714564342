import { AdvancedMarker, APIProvider, Map } from "@vis.gl/react-google-maps";

function MapContainer() {
  const position = { lat: 9.1322242, lng: 7.3872307 };
  console.log(process.env.REACT_APP_GOOGLE_MAP_ID);
  

  return (
    <APIProvider apiKey={process.env.REACT_APP_GOOGLE_API_KEY}>
      <div style={{ height: "100vh" }}>
        <Map
          zoom={18}
          defaultCenter={position}
          defaultZoom={10}
          mapId={process.env.REACT_APP_GOOGLE_MAP_ID}
        >
          <AdvancedMarker position={position} />
        </Map>
      </div>
    </APIProvider>
  );
}

export default MapContainer;
