import React from 'react'
import Skeleton from 'react-loading-skeleton'
import { useSelector } from 'react-redux'
import { CouriersIcon, UserManagementIcon, VendorsIcon, WalletIcon } from '../../../assets/images/icons'
import { formatAmount } from '../../../utils/Helpers'
import StatsCard from '../../all/StatsCard'

const StatsCardContainer = (props) => {

    const { users, gettingUsers } = useSelector(state => state.others)
    const { revenues, gettingRevenue } = useSelector(state => state.transactions)

    const renderStatusCards = () => {
        if (gettingRevenue || gettingUsers) {
            return (
                <div className={'statsGrid'}>
                    <div>
                        <Skeleton className={'statsCardSkeleton'} />
                    </div>
                    <div>
                        <Skeleton className={'statsCardSkeleton'} />
                    </div>
                    <div>
                        <Skeleton className={'statsCardSkeleton'} />
                    </div>
                    <div>
                        <Skeleton className={'statsCardSkeleton'} />
                    </div>
                </div>


            )
        }
        if (revenues && users) {
            return (
                <div className={'statsGrid'}>
                    <div>
                        <StatsCard icon={WalletIcon} title={'Revenue'} count={'N' + formatAmount(revenues?.data?.revenues?.completedTransactionsTotal)} rate={'0'} rateColor={'#4CAF50'} rateBg={'#E8F5E9'} bgColor={'#FF92AE'} />
                    </div>
                    <div>
                        <StatsCard icon={UserManagementIcon} title={'Users'} count={users?.data?.totalUsers} rate={'0'} rateColor={'#4CAF50'} rateBg={'#E8F5E9'} bgColor={'#4C6FFF'} />
                    </div>
                    <div>
                        <StatsCard icon={VendorsIcon} title={'Vendors'} count={users?.data?.totalVendors} rate={'0'} rateColor={'#4CAF50'} rateBg={'#E8F5E9'} bgColor={'#68DBF2'} />
                    </div>
                    <div>
                        <StatsCard icon={CouriersIcon} title={'Couriers'} count={users?.data?.totalRiders} rate={'0'} rateColor={'#4CAF50'} rateBg={'#E8F5E9'} bgColor={'#F7936F'} />
                    </div>
                </div>
            )
        }
    }


    return (
        <>
            {renderStatusCards()}
        </>
    )
}

export default StatsCardContainer