import React, { useEffect } from 'react'
import { useState } from 'react';
import '../../assets/styles/searchStyles.css';
import ProductsSearch from './ProductsSearch';
import UsersSearch from './UserSearch';
import { useDispatch } from 'react-redux';
import { getVendors } from '../../redux/actions/OtherActions';
import Navigation from '../../widgets/Navigation';

const Search = () => {
    const [tab, setTab] = useState(1);
    const onTabChange = (val) => {
        setTab(val)
    }
    const dispatch = useDispatch()
    useEffect(() => {
        dispatch(getVendors())
    }, [])


    const renderTabs = () => {
        return (
            <div className={'searchTabContainer'}>
                <button className={tab === 1 ? 'boldText f24 activeTabButton' : 'boldText f24'} onClick={() => onTabChange(1)}>Products</button>
                <button className={tab === 2 ? 'boldText f24 activeTabButton' : 'boldText f24'} onClick={() => onTabChange(2)}>User</button>
            </div>
        )
    }

    return (
        <>
            <Navigation />
            <main>
                <section className={'searchContainer'} >
                    {renderTabs()}
                    {tab === 1 && <ProductsSearch />}
                    {tab === 2 && <UsersSearch />}
                </section>
            </main>

        </>
    )
}

export default Search