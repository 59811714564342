import React from 'react'
import { AiOutlineMinusCircle, AiOutlinePlusCircle } from 'react-icons/ai'

const MealSetupCounter = ({ mealQuantity, addMealQuantity, decreaseMealQuantity }) => {
    return (
        <div className='mealSetupCounterContainer'>
            <AiOutlineMinusCircle size={22} onClick={decreaseMealQuantity} />
            <span>{mealQuantity}</span>
            <AiOutlinePlusCircle size={22} onClick={addMealQuantity} />
        </div>
    )
}

export default MealSetupCounter