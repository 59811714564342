import React from 'react'
import { useSelector } from 'react-redux'
import CheckBox from '../../../utils/CheckBox'
import { formatAmount } from '../../../utils/Helpers'

const VerifyProducts = (props) => {
    const { storedProduct } = useSelector(state => state.products)
    const { confirm, confirmProduct, image } = props
    console.log(storedProduct, image)
    return (
        <>
            <section className={'previewProductContainer'}>
                <div>
                    <p className={'f16 boldText'} style={{ color: '#38383A' }}>Product information</p>
                </div>
                <div className={'previewGrid'}>
                    <div>
                        <ul>
                            <li className={'flexRow flexWrap alignCenter justifyBetween'}>
                                <p style={{ color: '#565758' }} className={'f14 boldText'}>Title</p>
                                <p style={{ color: '#919294' }} className={'f14 semiBoldText'}>{storedProduct?.product_name}</p>
                            </li>
                            <li className={'flexRow flexWrap alignCenter justifyBetween'}>
                                <p style={{ color: '#565758' }} className={'f14 boldText'}>Description</p>
                                <p style={{ color: '#919294' }} className={'f14 semiBoldText'}>{storedProduct?.product_description}</p>
                            </li>
                            <li className={'flexRow flexWrap  justifyBetween'}>
                                <p style={{ color: '#565758' }} className={'f14 boldText'}>Tags</p>
                                <div className={'previewTagsContainer'}>
                                    {storedProduct && storedProduct?.product_tags?.length !== 0 &&
                                        storedProduct?.product_tags?.map((item, i) => {
                                            return (
                                                <p style={{ color: '#919294' }} className={'f14 semiBoldText'} key={i}>{item}, </p>
                                            )
                                        })
                                    }
                                </div>

                            </li>
                        </ul>
                    </div>
                    <div>
                        <ul>
                            <li className={'flexRow flexWrap alignCenter justifyBetween'}>
                                <p style={{ color: '#565758' }} className={'f14 boldText'}>Meal combo options ({storedProduct?.product_options?.length})</p>
                            </li>
                            {storedProduct && storedProduct?.product_options?.length !== 0 &&
                                storedProduct?.product_options?.map((item, i) => {
                                    return (
                                        <li className={'flexRow flexWrap alignCenter justifyBetween'}>
                                            <p style={{ color: '#565758' }} className={'f14 boldText'}>{item?.name}</p>
                                            <p style={{ color: '#919294' }} className={'f14 semiBoldText'}>₦{formatAmount(item?.price)}</p>
                                        </li>
                                    )
                                })
                            }
                        </ul>
                    </div>
                    <div>
                        {image ? <img src={URL.createObjectURL(image)} alt="Uploaded image" className={'previewImage'} /> : <img src={storedProduct?.product_images[0]} alt="Uploaded image" className={'previewImage'} />}
                        {/* {storedProduct && storedProduct?.product_images?.length !== 0 &&} */}
                    </div>
                </div>

            </section>
            <div style={{ marginTop: '16px' }}>
                <CheckBox title={'I certify that the information provided above is correct and up to date'} saveForLater={confirm} onClick={confirmProduct} />
            </div>
        </>

    )
}

export default VerifyProducts



