import React, { useState } from 'react'
import { connect, useDispatch } from 'react-redux'
import { useMediaQuery } from 'react-responsive'
import { CloseIcon } from '../../../assets/images/icons'
import { getOrdersById } from '../../../redux/actions/OrdersAction'
import { getRiders } from '../../../redux/actions/OtherActions'
import CustomButton from '../../../utils/CustomButton'
import CustomInput from '../../../utils/CustomInput'
import CustomAgenciesDropDown from '../../../utils/CustomAgenciesDropDown'
import { showError, showSuccess } from '../../../utils/Toast'
import http from '../../../utils/utils'

const AssignAgencyModal = (props) => {
    const isDesktopOrLaptop = useMediaQuery({
        query: '(min-width: 1430px)'
    })
    const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1430px)' })

    const [state, setState] = useState({
        agency: null,
    })
    const [open, setOpen] = useState(false)

    const { orderId, onDecline, id } = props
    const dispatch = useDispatch()

    const selectAgency = (val) => {
        setState((prevState) => ({ ...prevState, agency: val }))
        // console.log(val)
    }

    const assignAgency = async () => {
        const obj = {
            order_id: orderId,
            user_id: state?.agency?.userId
        }
        console.log(obj)
        try {
            const res = await http.post(`admin/order/assign_logistic_partner`, obj)
            showSuccess(res?.message)
            onDecline()
            dispatch(getOrdersById(id))

        } catch (error) {
            console.log(error)
            showError()
        }
    }


    return (

        <>
            {isDesktopOrLaptop &&
                <section className={'modalContainer'}>
                    <div className={'createOrderModal'} style={{ maxWidth: '633px', width: '633px' }}>
                        <div className={'createOrderModalHeader flexRow alignCenter justifyBetween'}>
                            <p className={'f20 boldText'}>Assign agency</p>
                            <span onClick={props.onDecline}>
                                <img src={CloseIcon} alt='icon' />
                            </span>
                        </div>
                        <div>
                            <div style={{ width: '100%' }}>
                                <p className={'f14 grey semiBoldText'} style={{ marginBottom: '8px' }}>Agencies</p>
                                <CustomAgenciesDropDown agency={state.agency} selectAgency={selectAgency} open={open} setOpen={setOpen} />
                            </div>
                        </div>
                        <div className={'createOrderModalFooter flexRow alignCenter justifyBetween'} style={{ marginTop: '40px' }}>
                            <div />
                            <div className={'flexRow alignCenter footerButtons'} >
                                <div style={{ marginRight: '8px' }}>
                                    <CustomButton title={'Cancel'} customStyle={'createOrderCancelBtn'} onClick={onDecline} height={'40px'} width={'88px'} />
                                </div>
                                <div >
                                    <CustomButton title={'Assign'} bgColor={'#FFA024'} onClick={assignAgency} height={'40px'} width={'127px'} />
                                </div>

                            </div>
                        </div>
                    </div>
                </section>
            }
            {isTabletOrMobile &&
                <section className={'mobileModalContainer'}>
                    <div className={'mobileCreateOrderModal'} style={{ width: "100%", maxHeight: "300px", height: open ? '500px' : 'auto', transition: '0.3s ease-in-out all ' }} >
                        <div className={'createOrderModalHeader flexRow alignCenter justifyBetween'}>
                            <p className={'f16 boldText'}>Assign agency</p>
                            <span onClick={props.onDecline}>
                                <img src={CloseIcon} alt='icon' />
                            </span>
                        </div>
                        <div style={{marginTop:'10px'}}>
                            <div style={{ width: '100%' }}>
                                <p className={'f14 grey semiBoldText'} style={{ marginBottom: '8px' }}>Agencies</p>
                                <CustomAgenciesDropDown agency={state.agency} selectAgency={selectAgency} open={open} setOpen={setOpen} />
                            </div>
                        </div>
                        <div className={'flexRow alignCenter justifyBetween'} style={{ marginTop: '40px' }}>
                            {/* <div /> */}

                            <div style={{ marginRight: '8px' }}>
                                <CustomButton title={'Cancel'} customStyle={'createOrderCancelBtn'} onClick={onDecline} height={'40px'} />
                            </div>
                            <div >
                                <CustomButton title={'Assign'} bgColor={'#FFA024'} onClick={assignAgency} height={'40px'} />
                            </div>
                        </div>
                    </div>
                </section>
            }
        </>

    )
}

export default connect(null, { getRiders })(AssignAgencyModal)
