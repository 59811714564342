import React, { useEffect, useRef, useState } from 'react'
import AuthContainer from '../../components/auth/AuthContainer'
import AuthHeader from '../../components/auth/AuthHeader'
import CustomButton from '../../utils/CustomButton'
import '../../assets/styles/authStyles.css';
import OtpInput from '../../utils/OtpInput'
import { showError, showSuccess } from '../../utils/Toast';
import { connect, useSelector } from 'react-redux';
import { getOtp, verifyOtp } from '../../redux/actions/AuthActions';
import { useLocation, useNavigate } from 'react-router-dom';
import { setSession } from '../../utils/Helpers';

const PhoneVerification = (props) => {

    const [pin, setPin] = useState('');
    const onChange = (value) => { setPin(value) };
    const Ref = useRef(null);
    const [timer, setTimer] = useState('00:00');
    const params = useLocation()
    const email = params?.state?.email
    const token = params?.state?.token
    const password = params?.state?.password
    const rememberMe = params?.state?.rememberMe
    const [state, setState] = useState({
        token: ''
    })
    const navigate = useNavigate()
    const { loadingUser } = useSelector(state => state.auth)


    const getTimeRemaining = (e) => {
        const total = Date.parse(e) - Date.parse(new Date());
        const seconds = Math.floor((total / 1000) % 60);
        const minutes = Math.floor((total / 1000 / 60) % 60);
        return {
            total, minutes, seconds
        };
    }
    const startTimer = (e) => {
        let { total, minutes, seconds }
            = getTimeRemaining(e);
        if (total >= 0) {
            setTimer(
                (minutes > 9 ? minutes : '0' + minutes) + ':'
                + (seconds > 9 ? seconds : '0' + seconds)
            )
        }
    }
    const clearTimer = (e) => {
        setTimer('01:30');
        if (Ref.current) clearInterval(Ref.current);
        const id = setInterval(() => {
            startTimer(e);
        }, 1000)
        Ref.current = id;
    }
    const getDeadTime = () => {
        let deadline = new Date();
        deadline.setSeconds(deadline.getSeconds() + 90);
        return deadline;
    }

    useEffect(() => {
        clearTimer(getDeadTime());

    }, []);

    const requestOTP = async () => {
        const obj = { email, password }
        try {
            const res = await props.getOtp(obj)
            // console.log('request otp', res)
            showSuccess(res?.message);
            setState((prevState) => ({ ...prevState, token: res?.data?.authToken }))
        } catch (error) {
            // console.log('request otp', error)
            // console.log(error[1].message)
            showError(error[1].message)
        }
    }


    const verifyOTP = async () => {

        if (state.token) {
            const obj = { otp: pin, token: state.token }
            try {
                const res = await props.verifyOtp(obj)
                // console.log('request otp na me', res)
                showSuccess(res?.message);
                navigate('/')
                // if (res && rememberMe) {
                //     setSession(res?.accessToken)
                // }
            } catch (error) {
                // console.log('request otp', error)
                showError(error[1].message)
            }
        }
        if (token) {
            const obj = { otp: pin, token }
            try {
                const res = await props.verifyOtp(obj)
                // console.log('request otp na me ', res)
                showSuccess(res?.message);
                navigate('/')
                // if (res && rememberMe) {
                //     setSession(res?.accessToken)
                // }
            } catch (error) {
                // console.log('request otp', error)
                showError(error[1].message)
            }
        }

    }


    const onClickReset = () => {
        clearTimer(getDeadTime());
        requestOTP()
    }

    const disabled = () => {
        if (pin === '') {
            return true
        }
        return false
    }




    return (
        <>
            <AuthContainer logo={true}>
                <AuthHeader title={'Let’s sign you in'} subTitle={'Enter OTP sent to your email address to continue'} />
                <div style={{ marginBottom: '28px' }}>
                    <p className={'f14 semiBoldText'} style={{ color: '#08080C', marginBottom: '8px' }}>OTP</p>
                    <OtpInput value={pin} valueLength={6} onChange={onChange} />
                </div>
                <div style={{ marginBottom: '52px' }} className={'otpVerifyBtn'}>
                    <p className={'textCenter semiBoldText f14'}>Didn’t receive OTP?
                        {timer != '00:00' ? <span className={'semiBoldText f14'} style={{ color: timer != '00:00' ? '#FFD092' : '' }}> Resend OTP in {timer}</span> :
                            <span className={'semiBoldText f14'} style={{ color: '#FFA024' }} onClick={onClickReset}> Resend OTP </span>}
                    </p>
                </div>
                <div className={'otpVerifyBtn'}>
                    <CustomButton title={'Verify'} onClick={verifyOTP} loading={loadingUser} bgColor={'#FFA024'} disabled={disabled()} />
                </div>
            </AuthContainer>
        </>
    )
}

export default connect(null, { getOtp, verifyOtp })(PhoneVerification);



