import React from 'react';
import { BackIcon } from '../assets/images/Index';
import './utils.css';
import { useNavigate } from 'react-router-dom';

const BackComponent = (props) => {
    const navigate = useNavigate()
    const goBack = () => {
        navigate(-1)

    }
    return (
        <div onClick={goBack} className={'backComponentContainer'}>
            <img src={BackIcon} alt />
        </div>
    )
}

export default BackComponent