import React, { useState } from 'react'
import { formatAmount, shortenXterLength } from '../../../utils/Helpers';
import { Edit2, Trash } from 'iconsax-react';
import { showSuccess } from '../../../utils/Toast';
import CustomButton from '../../../utils/CustomButton';

const CreateWithAIPreviewModal = ({ isOpen, closeModal, menuData, setMenuData, setCurrentItem, openEditModal, closeEditModal, proceed, loading }) => {



    // Open the modal with the current item
    const handleEditClick = (item, index) => {
        setCurrentItem({ ...item, index });
        openEditModal()
    };

    // Handle saving changes from the modal
    const handleSaveChanges = (updatedItem) => {
        const updatedItems = [...menuData];
        updatedItems[updatedItem.index] = updatedItem;
        setMenuData(updatedItems);
        closeEditModal()// Close the modal
    };

    // Handle deleting an item
    const handleDelete = (index) => {
        const updatedItems = menuData?.filter((_, itemIndex) => itemIndex !== index);
        setMenuData(updatedItems);
        showSuccess(`Item deleted successfully`)
    };




    return (
        <div className={`createWithAIModal ${isOpen ? 'open' : ''}`} onClick={closeModal}>
            <div className="createWithAIModalContent" onClick={(e) => e.stopPropagation()}>
                <header style={{ position: 'sticky', top: '-40px', background: 'white', padding: '20px 0' }}>
                    <h3 className={'boldText f16'}>Preview AI Extracted Menu</h3>
                </header>
                <div style={{ margin: '20px 0 16px 0' }}>
                    <ul>
                        {menuData?.map((item, index) => {
                            // Handle the image source (either File or a fallback URL)
                            let imageUrl;
                            if (item.image && item.image instanceof File) {
                                // If the image is a File object, create a URL
                                imageUrl = URL.createObjectURL(item.image);
                            } else if (typeof item.image === 'string') {
                                // If the image is a URL string, use it directly
                                imageUrl = item.image;
                            } else {
                                // Fallback to a default image if there's no valid image
                                imageUrl = 'https://via.placeholder.com/80';
                            }

                            return (
                                <li
                                    key={index}
                                    style={{
                                        marginBottom: '10px',
                                        borderBottom: index === menuData?.length - 1 ? 'none' : '1px solid #F9F9FB',
                                        paddingBottom: '10px'
                                    }}
                                    className={'flexRow alignCenter justifyBetween'}
                                >
                                    <div className={'flexRow alignCenter'}>
                                        <div>
                                            <img
                                                src={imageUrl}
                                                alt='food_item'
                                                style={{ width: '80px', height: '80px', objectFit: 'cover' }}
                                            />
                                        </div>
                                        <div className={''} style={{ marginLeft: '10px' }}>
                                            <p className={'regularText f16'}>
                                                {shortenXterLength(item.name, 25)}
                                            </p>
                                            <p className={'boldText f14'} style={{ color: '#696A6B' }}>
                                                Price - ₦{formatAmount(item?.price)}
                                            </p>
                                            <p className={'regularText f12'} style={{ color: '#696A6B' }}>
                                                Desc - {shortenXterLength(item?.description, 25)}
                                            </p>
                                            <p className={'regularText f12'} style={{ color: '#696A6B' }}>
                                                Category - {shortenXterLength(item?.category, 25)}
                                            </p>
                                        </div>
                                    </div>
                                    <div className={'flexRow alignCenter'} style={{ gap: '10px' }}>
                                        <button onClick={() => handleEditClick(item, index)} style={{ background: 'inherit', border: 'none' }}>
                                            <Edit2 size="16" color="#696A6B" />
                                        </button>
                                        <button onClick={() => handleDelete(index)} style={{ background: 'inherit', border: 'none' }}>
                                            <Trash size="16" color="#F73838" />
                                        </button>
                                    </div>
                                </li>
                            );
                        })}
                    </ul>

                </div>
                <div style={{ margin: "20px 0 60px 0" }}>
                    <CustomButton title={'Continue'} onClick={proceed} loading={loading} bgColor={'#FFA024'} />
                </div>
            </div>
        </div>
    )
}

export default CreateWithAIPreviewModal