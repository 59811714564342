import React, { useEffect, useState } from 'react';
import Navigation from '../../../widgets/Navigation';

import { useMediaQuery } from 'react-responsive';
import ProductsNav from '../../../widgets/ProductsNav';
import FoodCard from '../../../components/dashboard/products/FoodCard';
import { useDispatch, useSelector } from 'react-redux';
import { getProducts } from '../../../redux/actions/ProductsActions';
import CreateProducts from '../../../components/dashboard/products/CreateProducts';
import DeleteProductModal from '../../../components/dashboard/products/DeleteProductModal';
import { showError, showSuccess } from '../../../utils/Toast';
import http from '../../../utils/utils';
import EditProducts from '../../../components/dashboard/products/EditProducts';
import { BackWardIcon, ForwardIcon } from '../../../assets/images/icons';
import CreateWithAIModal from '../../../components/dashboard/products/CreateWithAIModal';
import CreateWithAIPreviewModal from '../../../components/dashboard/products/CreateWithAIPreviewModal';
import EditAIProductsModal from '../../../components/dashboard/products/EditAIProductsModal';

const ProductsList = (props) => {
    const isDesktopOrLaptop = useMediaQuery({ query: '(min-width: 1200px)' })
    const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1200px)' })
    const dispatch = useDispatch()
    useEffect(() => {
        dispatch(getProducts(1))
    }, [])
    const { products } = useSelector(state => state.products)
    // console.log(products)
    const [state, setState] = useState({
        showCreateProducts: false, deleteModal: false, selectedProduct: null, loading: false, editModal: false,
    })

    const [meta, setMeta] = useState({
        count: products?.data?.meta.count, currentPage: products?.data?.meta.currentPage,
        perPage: products?.data?.meta.perPage, total: products?.data?.meta.total, totalPages: products?.data?.meta.totalPages
    });

    const handleScroll = (hide) => {
        document.body.style.overflow = hide ? 'hidden' : 'unset';
    };


    //region Ai Menu

    const [isModalOpen, setModalOpen] = useState(false);
    const [isPreviewModalOpen, setPreviewModalOpen] = useState(false)
    const [menuData, setMenuData] = useState(null);
    const [currentItem, setCurrentItem] = useState(null);
    const [isEditModalOpen, setIsEditModalOpen] = useState(false);
    const [business, setBusiness] = useState(null);



    const openModal = () => {
        setModalOpen(true);
        handleScroll(true)

    }
    const closeModal = () => {
        setModalOpen(false);
        handleScroll(false)
    }


    const openPreviewModal = () => {
        setPreviewModalOpen(true);
        setModalOpen(false)
        handleScroll(true)
    }
    const closePreviewModal = () => {
        setPreviewModalOpen(false);
        handleScroll(false)
    }

    const openEditModal = () => {
        setIsEditModalOpen(true);
        handleScroll(true)

    }
    const closeEditModal = () => {
        setIsEditModalOpen(false);
    }

    const handleSaveChanges = (updatedItem) => {
        const updatedItems = [...menuData];
        updatedItems[updatedItem.index] = updatedItem;
        setMenuData(updatedItems);
        closeEditModal()
    };


    // admin / business /: business_id / product / create_multiple

    const createProductWithAI = async () => {
        const products = menuData; // Assuming menuData is the array of product objects
        setState({ ...state, loading: true });

        try {
            const res = await http.post(`admin/business/${business?.id}/product/create_multiple`, {
                products  // Wrap the products array inside an object with a "products" key
            });

            // console.log(res);
            setState({ ...state, loading: false });
            showSuccess(res?.message);
            dispatch(getProducts(1));
            closePreviewModal()

        } catch (error) {
            // console.log(error[1]);
            if (error) {
                const validationErrors = error[1]?.data?.errors;

                // Map through the validation errors and display the message
                validationErrors.forEach(err => {
                    const { msg, value, param } = err;

                    // console.log(`Error: ${msg}`);
                    // console.log(`Param: ${param}`);
                    // console.log(`Invalid value(s):`, value);

                    // You can customize how you want to display the error in your UI
                    showError(`Error: ${msg} Naira`);
                });
            } else {
                // Handle other types of errors (if any)
                showError('An unexpected error occurred. Please try again.');
            }

            setState({ ...state, loading: false });
        }
    };







    const pageNumbers = Array.from({ length: products?.data?.meta?.totalPages }, (_, index) => index + 1);
    const maxVisibleNumbers = 5; // Maximum visible numbers on the screen

    let visiblePageNumbers = [];

    if (pageNumbers.length <= maxVisibleNumbers) {
        visiblePageNumbers = pageNumbers; // Show all numbers if the array length is smaller or equal to the maximum visible numbers
    } else {
        const halfVisibleNumbers = Math.floor((maxVisibleNumbers - 1) / 2);
        const startPage = meta?.currentPage - halfVisibleNumbers;
        const endPage = meta?.currentPage + halfVisibleNumbers;

        if (startPage <= 1) {
            visiblePageNumbers = [...pageNumbers.slice(0, maxVisibleNumbers - 1), '...'];
        } else if (endPage >= pageNumbers.length) {
            visiblePageNumbers = ['...', ...pageNumbers.slice(pageNumbers.length - maxVisibleNumbers + 2)];
        } else {
            visiblePageNumbers = ['...', ...pageNumbers.slice(startPage, endPage + 1), '...'];
        }
    }

    const handlePageChange = pageNumber => {
        setMeta({ ...meta, currentPage: pageNumber });
        dispatch(getProducts(pageNumber))

    };



    const openCreateProductsModal = () => {
        setState((prevState) => ({ ...prevState, showCreateProducts: true }))
        window.scrollTo(0, 0)
    }
    const closeCreateProductsModal = () => {
        setState((prevState) => ({ ...prevState, showCreateProducts: false }))
    }

    const openDeleteProductModal = (val) => {
        setState((prevState) => ({ ...prevState, deleteModal: true, selectedProduct: val }))

    }
    const closeDeleteProductModal = () => {
        setState((prevState) => ({ ...prevState, deleteModal: false }))
    }

    const openEditProductModal = (val) => {
        setState((prevState) => ({ ...prevState, editModal: true, selectedProduct: val }))
    }
    const closeEditProductModal = () => {
        setState((prevState) => ({ ...prevState, editModal: false }))
    }





    const deleteProduct = async (val) => {
        setState({ ...state, loading: true })

        try {
            const res = await http.delete(`admin/business/${val?.businessId}/product/${val?.id}/delete`)
            // closeDeleteProductModal()
            if (res) {
                dispatch(getProducts(1))
                showSuccess('Product Deleted Successfully')
            }
            setState({ ...state, loading: false, deleteModal: false })

        } catch (error) {
            console.log(error)
            setState({ ...state, loading: false })

        }
    }

    const unListProduct = async (val) => {
        setState({ ...state, loading: true })
        const obj = { listing_status: false, }
        try {
            const res = await http.put(`admin/business/${val?.businessId}/product/${val?.id}/update`, obj)
            // closeDeleteProductModal()
            if (res) {
                dispatch(getProducts(1))
                showSuccess('Product Unlisted Successfully')
            }
            setState({ ...state, loading: false, deleteModal: false })

        } catch (error) {
            console.log(error)
            setState({ ...state, loading: false })

        }
    }

    const enListProduct = async (val) => {
        setState({ ...state, loading: true })
        const obj = { listing_status: true, }
        try {
            const res = await http.put(`admin/business/${val?.businessId}/product/${val?.id}/update`, obj)
            // closeDeleteProductModal()
            if (res) {
                dispatch(getProducts(1))
                showSuccess('Product Enlisted Successfully')
            }
            setState({ ...state, loading: false, deleteModal: false })

        } catch (error) {
            console.log(error)
            setState({ ...state, loading: false })

        }
    }


    const renderProducts = () => {
        if (products?.data?.products.length !== 0) {
            return products?.data?.products?.map((item, index) => {
                return (
                    <div key={index} style={{ justifySelf: isDesktopOrLaptop ? 'center' : '' }}>
                        <FoodCard data={item} openDeleteProductModal={() => openDeleteProductModal(item)} openEditProductModal={() => openEditProductModal(item)}
                            unListProduct={() => unListProduct(item)} enListProduct={() => enListProduct(item)}
                        />
                    </div>
                )
            })
        }
    }

    return (
        <>
            <Navigation />
            <ProductsNav openCreateProductsModal={openCreateProductsModal} closeCreateProductsModal={closeCreateProductsModal} closeEditProductModal={closeEditProductModal} openModal={openModal} />
            <section style={{ paddingBottom: isTabletOrMobile ? '100px' : '0px' }}>
                <section className={'foodGrid'}>{renderProducts()}</section>
                <div className={'flexRow alignCenter '} style={{ padding: '40px 20px', justifyContent: 'center', width: isDesktopOrLaptop ? '60%' : 'auto', margin: isDesktopOrLaptop ? "auto" : '0px', }}>
                    <button disabled={meta.currentPage === 1} onClick={() => handlePageChange(meta.currentPage - 1)} className={'prevBtn'}>
                        <img src={BackWardIcon} alt='icon' />
                    </button>
                    <ul className={'flexRow alignCenter'}>
                        {visiblePageNumbers.map(pageNumber => (
                            <li key={pageNumber} className={'pageNumberContainer regularText f14'}>
                                <button onClick={() => handlePageChange(pageNumber)} className={meta.currentPage === pageNumber ? "active" : ""}>
                                    {pageNumber}
                                </button>
                            </li>
                        ))}
                    </ul>
                    <button disabled={meta.currentPage === meta.totalPages} onClick={() => handlePageChange(meta.currentPage + 1)} className={'nextBtn'}>
                        <img src={ForwardIcon} alt='icon' />
                    </button>

                </div>
            </section>
            {state.showCreateProducts && <CreateProducts onDecline={closeCreateProductsModal} />}
            {state.deleteModal && <DeleteProductModal onDecline={closeDeleteProductModal} selectedProduct={state.selectedProduct} deleteProduct={deleteProduct} loading={state.loading} />}
            {state.editModal && <EditProducts onDecline={closeEditProductModal} selectedProduct={state.selectedProduct} />}
            <CreateWithAIModal isOpen={isModalOpen} onClose={closeModal} openPreviewModal={openPreviewModal} menuData={menuData} setMenuData={setMenuData} business={business} setBusiness={setBusiness} />
            <CreateWithAIPreviewModal isOpen={isPreviewModalOpen} closeModal={closePreviewModal} menuData={menuData} setMenuData={setMenuData} openEditModal={openEditModal} closeEditModal={closeEditModal}
                setCurrentItem={setCurrentItem} proceed={createProductWithAI} loading={state.loading}
            />
            <EditAIProductsModal isOpen={isEditModalOpen} closeModal={closeEditModal} item={currentItem} onSave={handleSaveChanges} />
        </>
    )
}

export default ProductsList;