import { ADMIN_PROFILE, ADMIN_PROFILE_SUCCESSFUL, ADMIN_PROFILE_FAIL, OPERATION_LOCATIONS, OPERATION_LOCATIONS_SUCCESSFUL, OPERATION_LOCATIONS_FAIL } from '../Types';

const INITIAL_STATE = {
    error: [],
    adminProfile: {},
    gettingAdminProfile: false,
    operationLocations: {},
    gettingOperationLocations: false,
};

export default (state = INITIAL_STATE, { type, payload }) => {
    switch (type) {
        case ADMIN_PROFILE:
            return { ...state, gettingAdminProfile: true };
        case ADMIN_PROFILE_SUCCESSFUL:
            return { ...state, gettingAdminProfile: false, adminProfile: payload }
        case ADMIN_PROFILE_FAIL:
            return { ...state, gettingAdminProfile: false, error: payload }
        case OPERATION_LOCATIONS:
            return { ...state, gettingOperationLocations: true };
        case OPERATION_LOCATIONS_SUCCESSFUL:
            return { ...state, gettingOperationLocations: false, operationLocations: payload }
        case OPERATION_LOCATIONS_FAIL:
            return { ...state, gettingOperationLocations: false, error: payload }
        default:
            return state;
    }
};