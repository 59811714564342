import React, { useState } from 'react'
import { IoIosArrowDown } from 'react-icons/io';
import '../../../assets/styles/productsStyles.css';

const FilterDropDown = (props) => {
    const { list, icon, activeIcon, title, onClick } = props
    const [show, setShow] = useState(false)
    const toggle = () => {
        setShow(prevState => !prevState)
    }

    const select = (item) => {
        toggle()
        onClick(item)
    }
    const renderList = () => {
        if (list?.length !== 0) {
            return list?.map((item, index) => {
                // console.log(item)
                return (
                    <li key={index} className={'filterListItem boldText f14 '} onClick={() => select(item)}>{item?.businessName || item}</li>
                )
            }
            )
        }
    }
    return (

        <>
            <div style={{ position: 'relative', cursor: 'pointer' }}>
                <div className={'filterDropContainer flexRow alignCenter justifyCenter'} onClick={toggle}>
                    {show ? icon : activeIcon}
                    <p className={'f16 semiBoldText'} style={{ color: '#919294' }}>{title}</p>
                    <IoIosArrowDown color={'#919294'} />
                </div>
                {show && <ul className={'filterListContainer'}>
                    {renderList()}
                </ul>}
            </div>
        </>
    )
}

export default FilterDropDown

// background: #F9F9FB;