import React from 'react';
import '../../assets/styles/textStyles.css';

const AuthHeader = (props) => {
    return (
        <section className={'authHeader'}>
            <h2 className={'boldText'}>{props.title}</h2>
            <p className={'regularText'}>{props.subTitle}</p>
        </section>
    )
}

export default AuthHeader