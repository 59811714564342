import React, { useState } from "react";
import { ArrowDown } from "../../../assets/images/icons";
import "../../../utils/utils.css";
const OperationsDropDown = (props) => {
  const [open, setOpen] = useState(false);
  const { selectData, data, options } = props;

  const toggler = () => {
    setOpen((previousState) => !previousState);
  };

  return (
    <>
      <div className={"flexRow alignCenter justifyBetween customSelectContainer"} onClick={toggler}>
        <div>
          <span className={"f14 regularText"} style={{ color: "#999CA0", marginBottom: "0" }}>
            {data ? data?.location?.state : "Select location"}
          </span>
        </div>
        <div>
          <img src={ArrowDown} alt={"icon"} />
        </div>
        {open && (
          <ul className={"customSelectListContainer"}>
            {options?.locations?.map((item, index) => {
              return (
                <li className={"customSelectListItem regularText f16"} onClick={() => selectData(item)} key={index} style={{ color: "#000000", cursor: "pointer" }}>
                  {item?.location?.state}
                </li>
              );
            })}
          </ul>
        )}
      </div>
    </>
  );
};

export default OperationsDropDown;
