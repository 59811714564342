import moment from 'moment';
import React from 'react';
import Skeleton from 'react-loading-skeleton';
import { useDispatch, useSelector } from 'react-redux';
import '../../../assets/styles/extraStyles.css';
import { shortenXterLength } from '../../../utils/Helpers';
import { getUserProfile } from '../../../redux/actions/UsersActions';

const ListView = (props) => {

    const { users, gettingUsers } = useSelector(state => state.users)
    const { openModal } = props

    const header = [
        { title: 'ID', accessor: 'id' },
        { title: 'CUSTOMER NAME', accessor: 'customer' },
        { title: 'TYPE', accessor: 'type' },
        { title: 'GENDER', accessor: 'gender' },
        { title: 'MOBILE NUMBER', accessor: 'number' },
        { title: 'EMAIL ADDRESS', accessor: 'email' },
        { title: 'STATUS', accessor: 'status' },
        { title: 'DATE JOINED', accessor: 'date' },
        { title: 'ACTION', accessor: 'action' }
    ]


    const renderTableHead = () => {
        if (header) {
            return header?.map((item, index) => {
                return (
                    <td className={'boldText'} key={index}>{item?.title}</td>
                )
            })
        }
    }

    const renderName = (item) => {
        if (item?.firstName) {
            return item?.firstName
        }
        if (item?.riderFirstName) {
            return item?.riderFirstName
        }
        if (item?.vendorFirstName) {
            return item?.vendorFirstName
        }
        return 'Nil'
    }
    const renderEmail = (item) => {
        if (item?.email) {
            return item?.email
        }
        if (item?.riderEmailAddress) {
            return item?.riderEmailAddress
        }
        if (item?.vendorEmailAddress) {
            return item?.vendorEmailAddress
        }
        return 'Nil'
    }
    const renderPhoneNumber = (item) => {
        if (item?.phone) {
            return item?.phone
        }
        if (item?.riderPhoneNumber) {
            return item?.riderPhoneNumber
        }
        if (item?.vendorPhoneNumber) {
            return item?.vendorPhoneNumber
        }
        return 'Nil'
    }

    const renderRole = (item) => {
        if (item?.role) {
            return item?.role
        }
        if (item?.userType) {
            return item?.userType
        }
        return 'Nil'
    }

    const dispatch = useDispatch()
    const getUserDetails = (id) => {
        dispatch(getUserProfile(id))
        openModal()
    }

    const renderTableRow = () => {
        if (gettingUsers) {
            return <>
                <Skeleton height={'40px'} width={'100%'} style={{ marginBottom: '20px' }} />
                <Skeleton height={'40px'} width={'100%'} style={{ marginBottom: '20px' }} />
                <Skeleton height={'40px'} width={'100%'} style={{ marginBottom: '20px' }} />
                <Skeleton height={'40px'} width={'100%'} style={{ marginBottom: '20px' }} />
                <Skeleton height={'40px'} width={'100%'} style={{ marginBottom: '20px' }} />
                <Skeleton height={'40px'} width={'100%'} style={{ marginBottom: '20px' }} />
                <Skeleton height={'40px'} width={'100%'} style={{ marginBottom: '20px' }} />
            </>;
        }

        if (users && users.users && users.users.length !== 0) {
            return users.users.map((item, index) => {
                const formattedDate = moment(item?.createdAt).format('YYYY-MM-DD hh:mm')
                return (
                    <tr key={index}>
                        <td className={'regularText f12 capitalize'}>{shortenXterLength(item?.id, 3)}</td>
                        <td className={'regularText f12 capitalize'}>{renderName(item)}</td>
                        <td className={'regularText f12 capitalize'}>{renderRole(item)}</td>
                        <td className={'regularText f12 capitalize'}>{item?.gender || 'Nil'}</td>
                        <td className={'regularText f12 capitalize'}>{renderPhoneNumber(item)}</td>
                        <td className={'regularText f12 capitalize '}> {renderEmail(item)}</td>
                        <td className={'semiBoldText f12 capitalize'} style={{ color: '#FFA024', cursor: 'pointer', textAlign: 'left' }} >
                            <p style={{ width: '88px' }}>{item?.status || 'Nil'}</p>
                        </td>
                        <td className={'regularText f12 capitalize '}> {formattedDate}</td>
                        <td className={'semiBoldText f12 capitalize'} style={{ color: '#FFA024', cursor: 'pointer', textAlign: 'left' }} >
                            <p style={{ width: '88px' }} onClick={() => getUserDetails(item?.id)}>View</p>
                        </td>
                    </tr>
                )
            });
        }

        if (users && users.length !== 0) {
            return users.map((item, index) => {
                const formattedDate = moment(item?.createdAt).format('YYYY-MM-DD hh:mm')
                return (
                    <tr key={index}>
                        <td className={'regularText f12 capitalize'}>{shortenXterLength(item?.id, 3)}</td>
                        <td className={'regularText f12 capitalize'}>{renderName(item)}</td>
                        <td className={'regularText f12 capitalize'}>{renderRole(item)}</td>
                        <td className={'regularText f12 capitalize'}>{item?.gender || 'Nil'}</td>
                        <td className={'regularText f12 capitalize'}>{renderPhoneNumber(item)}</td>
                        <td className={'regularText f12 capitalize '}> {renderEmail(item)}</td>
                        <td className={'semiBoldText f12 capitalize'} style={{ color: '#FFA024', cursor: 'pointer', textAlign: 'left' }} >
                            <p style={{ width: '88px' }}>{item?.status || 'Nil'}</p>
                        </td>
                        <td className={'regularText f12 capitalize '}> {formattedDate}</td>
                        <td className={'semiBoldText f12 capitalize'} style={{ color: '#FFA024', cursor: 'pointer', textAlign: 'left' }} >
                            <p style={{ width: '88px' }} onClick={() => getUserDetails(item?.id)}>View</p>
                        </td>
                    </tr>
                )
            });
        }

        return (
            <div style={{ textAlign: "center", width: "100%" }}>
                <p className={"boldText f20"}>No data</p>
            </div>
        );
    };

    return (
        <section className={'tableContainer'} style={{
            boxShadow: ' 0px 0px 1px rgba(12, 26, 75, 0.24), 0px 3px 8px - 1px rgba(50, 50, 71, 0.05)',
            borderRadius: '16px', margin: '40px 0'
        }}>
            <table className={'tableContentContainer'}>
                <thead >
                    <tr>
                        {renderTableHead()}
                    </tr>
                </thead>
                <tbody className={'tableBodyContainer'}>
                    {renderTableRow()}
                </tbody>
            </table>

        </section>
    )
}

export default ListView



