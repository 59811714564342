import React, { useState } from 'react'
import { AiOutlineClose } from 'react-icons/ai'
import CustomInput from '../../../utils/CustomInput'
import MultiButton from '../../../utils/MultiButton'
import { useMediaQuery } from 'react-responsive'

const DeleteProductModal = (props) => {
    const { selectedProduct, deleteProduct, loading } = props
    // console.log(selectedProduct, 'delete')
    const [productName, setProductName] = useState('')
    const isMobile = useMediaQuery({ query: '(max-width: 1200px)' })


    const onChangeProductName = (e) => {
        setProductName(e.target.value)
    }


    return (
        <section className={'modalContainer'}>
            <section className={'deleteProductFormContainer'} >
                <div className={'createProductHeader flexRow justifyBetween'}>
                    <p className={'boldText'} style={{ color: '#38383A' }}>Delete product</p>
                    <AiOutlineClose onClick={props.onDecline} />
                </div>
                <div style={{ padding: "40px" }}>
                    <CustomInput label={`Type the product name in the input field below to confirm deleting "${selectedProduct?.name}" from the catalogue?`}
                        onChange={onChangeProductName} value={productName}
                    />
                </div>
                <div className={'createProductFooter'}>
                    <div style={{ marginRight: '8px' }}>
                        <MultiButton title={'Cancel'} bgColor={'inherit'} height={'40px'} width={'88px'} color={'#000'}
                            brd={'1px solid #999CA0'} onClick={props.onDecline} />
                    </div>
                    <div>
                        <MultiButton title={'Yes, Delete'} bgColor={'#F84E4E'} height={'40px'} width={'127px'} color={'#fff'}
                            brd={'1px solid #F84E4E'} onClick={() => deleteProduct(selectedProduct)} disabled={selectedProduct?.name !== productName}
                            loading={loading}
                        />
                    </div>

                </div>
            </section>
        </section>

    )
}

export default DeleteProductModal