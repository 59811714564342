import React from 'react'
import { CloseIcon } from '../../../assets/images/icons'
import MultiButton from '../../../utils/MultiButton'

const RiderModal = (props) => {
    const { rider, onDecline, onAssign } = props
    return (
        <section className={'riderModalContainer'}>
            <div className={'riderModal'} >
                <div className={'createOrderModalHeader flexRow alignCenter justifyBetween'}>
                    <p className={'f20 boldText'}>Assign Rider</p>
                    <span onClick={onDecline}>
                        <img src={CloseIcon} alt='icon' />
                    </span>
                </div>
                <p><strong>Rider ID:</strong> {rider.riderId}</p>
                <br />
                <br />
                <p><strong>Name:</strong> {rider.riderName}</p>
                <br />
                <br />
                <p><strong>Phone:</strong> {rider.riderPhoneNumber}</p>
                <br />
                <br />
                <div style={{ display: "flex", justifyContent: "space-between", gap: "10px" }}>

                    <MultiButton
                        title="Assign To Rider"
                        bgColor={"#66BB6A"}
                        onClick={onAssign}
                        height="40px"

                    />
                    <MultiButton title={'Cancel'} icon={false} bgColor={'#F96868'} onClick={onDecline} height={'40px'} />
                </div>
            </div>
        </section>
    )
}

export default RiderModal