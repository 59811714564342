import React from 'react'
import { useMediaQuery } from 'react-responsive'
import CustomInput from '../../../../utils/CustomInput'

const AboutCustomerForm = (props) => {

    const isDesktopOrLaptop = useMediaQuery({ query: '(min-width: 1430px)' })
    const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1430px)' })

    const { onChangeName, onChangePhone, phone, name } = props

    const renderComponents = () => {
        if (isDesktopOrLaptop) {
            return (
                <section>
                    <div className={'createOrderModalHeader'}>
                        <h3 className={'f24 boldText'} style={{ color: '#565758' }}>About customer</h3>
                    </div>
                    <div className={'flexRow alignCenter'} style={{ gap: '16px' }}>
                        <div style={{ width: '50%' }}>
                            <CustomInput label={'Full name'} placeholder={'Enter fullname'} onChange={onChangeName} value={name} />
                        </div>
                        <div style={{ width: '50%' }}>
                            <CustomInput label={'Mobile number'} placeholder={'Enter mobile number'} onChange={onChangePhone} value={phone} type={'tel'} />
                        </div>
                    </div>
                </section>
            )
        }
        if (isTabletOrMobile) {
            return (
                <section>
                    <div className={'mobileCreateOrderModalHeader'}>
                        <h3 className={'f16 boldText textCenter'} style={{ color: '#565758' }}>About customer</h3>
                    </div>
                    <div >
                        <div >
                            <CustomInput label={'Full name'} placeholder={'Enter fullname'} onChange={onChangeName} value={name} />
                        </div>
                        <div >
                            <CustomInput label={'Mobile number'} placeholder={'Enter mobile number'} onChange={onChangePhone} value={phone} type={'tel'} />
                        </div>
                    </div>
                </section>
            )
        }
    }


    return (

        <>
            {renderComponents()}
        </>



    )
}

export default AboutCustomerForm