import React, { useState } from 'react';
import CreateOrderButton from '../assets/images/icons/dashboard/createOrder.svg';
import SearchIcon from '../assets/images/icons/dashboard/searchIcon.svg';
import FilterIcon from '../assets/images/icons/dashboard/filterIcon.svg';
import CustomInput from '../utils/CustomInput';
import { useDispatch, useSelector } from 'react-redux';
import { getOrdersAll } from '../redux/actions/OrdersAction';
import { Logo } from '../assets/images/Index';
import NotifyIcon from '../assets/images/icons/dashboard/notifyIcon.svg';


const OverviewMobileNav = (props) => {
    return (
        <section className={'OrderWidgetContainer flexRow justifyBetween'}>
            <img src={Logo} alt='icn' style={{ width: "40px", height: "40px" }} />
            <img src={NotifyIcon} alt='icon' style={{ width: "40px", height: "40px" }} />
        </section>
    )
}

export default OverviewMobileNav;