export const LOADING_USER = "loading_user";
export const LOGIN_SUCCESS = "login_success";
export const LOGIN_FAIL = "login_fail";

export const GETTING_OTP = 'getting_otp';
export const GETTING_OTP_SUCCESSFUL = 'getting_otp_successful';
export const GETTING_OTP_FAIL = 'getting_otp_fail';

export const SIGNOUT_USER = 'signout_user';
export const SIGNOUT_USER_SUCCESSFUL = 'signout_user_successful';
export const SIGNOUT_USER_FAIL = 'signout_user_fail';


export const VERIFY_OTP = 'verify_otp';
export const VERIFY_OTP_SUCCESSFUL = 'verify_otp_successful';
export const VERIFY_OTP_FAIL = 'verify_otp_fail';

export const RESET_PASSWORD = 'reset_password';
export const RESET_PASSWORD_SUCCESSFUL = 'reset_password_successful';
export const RESET_PASSWORD_FAIL = 'reset_password_fail';

export const ADMIN_PROFILE = 'admin_profile';
export const ADMIN_PROFILE_SUCCESSFUL = 'admin_profile_successful';
export const ADMIN_PROFILE_FAIL = 'admin_profile_fail';

export const OPERATION_LOCATIONS = "operation_locations";
export const OPERATION_LOCATIONS_SUCCESSFUL = "operation_locations_successful";
export const OPERATION_LOCATIONS_FAIL = "operation_locations_fail";




export const GETTING_ORDERS = 'getting_orders';
export const GETTING_ORDERS_SUCCESSFUL = 'getting_orders_successful';
export const GETTING_ORDERS_FAIL = 'getting_orders_fail';



export const ACTIVE_ORDERS = 'active_orders';
export const ACTIVE_ORDER_SUCCESSFUL = 'active_orders_successful';
export const ACTIVE_ORDER_FAIL = 'active_orders_fail';



export const REVENUES = 'revenues';
export const REVENUES_SUCCESSFUL = 'revenues_successful';
export const REVENUES_FAIL = 'revenues_fail';

export const TRANSACTIONS = 'transactions';
export const TRANSACTIONS_SUCCESSFUL = 'transactions_successful';
export const TRANSACTIONS_FAIL = 'transactions_fail';


export const GET_USERS = 'get_users';
export const GET_USERS_SUCCESSFUL = 'get_users_successful';
export const GET_USERS_FAIL = 'get_users_fail';

export const GET_VENDORS = 'get_vendors';
export const GET_VENDORS_SUCCESSFUL = 'get_vendors_successful';
export const GET_VENDORS_FAIL = 'get_vendors_fail';

export const GET_RIDERS = 'get_riders';
export const GET_RIDERS_SUCCESSFUL = 'get_riders_successful';
export const GET_RIDERS_FAIL = 'get_riders_fail';

export const GET_CLOSEST_RIDERS = "get_closest_riders";
export const GET_CLOSEST_RIDERS_SUCCESSFUL = "get_closest_riders_successful";
export const GET_CLOSEST_RIDERS_FAIL = "get_closest_riders_fail";

export const GET_AGENCIES = 'get_agencies';
export const GET_AGENCIES_SUCCESSFUL = 'get_agencies_successful';
export const GET_AGENCIES_FAIL = 'get_agencies_fail';

export const CART = 'cart';
export const ORDER = 'order';

export const GET_SINGLE_ORDER = 'get_single_order';
export const GET_SINGLE_ORDER_SUCCESSFUL = 'get_single_order_successful';
export const GET_SINGLE_ORDER_FAIL = 'get_single_order_fail';


export const ALL_PRODUCTS = 'all_products';
export const ALL_PRODUCTS_SUCCESSFUL = 'all_products_successful';
export const ALL_PRODUCTS_FAIL = 'all_products_fail';

export const SINGLE_PRODUCT = 'single_product';
export const SINGLE_PRODUCT_SUCCESSFUL = 'single_product_successful';
export const SINGLE_PRODUCT_FAIL = 'single_product_fail';


export const STORE_PRODUCT = 'store_products';


export const FETCH_USERS = 'fetch_users';
export const FETCH_USERS_SUCCESSFUL = 'fetch_users_successful';
export const FETCH_USERS_FAIL = 'fetch_users_fail';


export const USER_PROFILE = 'user_profile';
export const USER_PROFILE_SUCCESSFUL = 'user_profile_successful';
export const USER_PROFILE_FAIL = 'user_profile_fail';

export const USER_ORDER_HISTORY = 'user_order_history';
export const USER_ORDER_HISTORY_SUCCESSFUL = 'user_order_history_successful';
export const USER_ORDER_HISTORY_FAIL = 'user_order_history_fail';


export const UPDATE_META = 'update_meta';







