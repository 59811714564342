import { ACTIVE_ORDERS, ACTIVE_ORDER_FAIL, ACTIVE_ORDER_SUCCESSFUL, GETTING_ORDERS, GETTING_ORDERS_FAIL, GETTING_ORDERS_SUCCESSFUL, GET_SINGLE_ORDER, GET_SINGLE_ORDER_FAIL, GET_SINGLE_ORDER_SUCCESSFUL } from "../Types";

const INITIAL_STATE = {
    error: [],
    orders: [],
    ordersError: [],
    gettingOrders: false,
    activeOrders: [],
    gettingActiveOrders: false,
    singleOrder: null,
    gettingSingleOrder: false,
    singleOrderError: []



};

export default (state = INITIAL_STATE, { type, payload }) => {
    switch (type) {
        case GETTING_ORDERS:
            return { ...state, gettingOrders: true };
        case GETTING_ORDERS_SUCCESSFUL:
            return { ...state, gettingOrders: false, orders: payload }
        case GETTING_ORDERS_FAIL:
            return { ...state, gettingOrders: false, ordersError: payload }
        case ACTIVE_ORDERS:
            return { ...state, gettingActiveOrders: true };
        case ACTIVE_ORDER_SUCCESSFUL:
            return { ...state, gettingActiveOrders: false, activeOrders: payload }
        case ACTIVE_ORDER_FAIL:
            return { ...state, gettingActiveOrders: false, error: payload }
        case GET_SINGLE_ORDER:
            return { ...state, gettingSingleOrder: true }
        case GET_SINGLE_ORDER_SUCCESSFUL:
            return { ...state, gettingSingleOrder: false, singleOrder: payload }
        case GET_SINGLE_ORDER_FAIL:
            return { ...state, gettingSingleOrder: false, singleOrderError: payload }

        default:
            return state;
    }
};