import React from 'react';
import './utils.css';
import '../assets/styles/textStyles.css';
import '../assets/styles/generalStyles.css';
const CustomInput = (props) => {

    const { label, type, name, value, onChange, placeholder, disabled, maxLength, customLabel, inputStyle, customInputContainer,
        multiple, height, icon, togglePassword, autoComplete } = props

    return (
        <>
            <div className={`inputContainer ${customInputContainer}`}>
                <label className={`f14 grey semiBoldText flex alignCenter ${customLabel}`} >
                    {label}
                </label>
                <div className={`input flexRow alignCenter justifyBetween ${inputStyle}`}>
                    <input type={type} name={name} value={value} onChange={onChange}
                        placeholder={placeholder} className={'inputBox'}
                        disabled={disabled} maxLength={maxLength} multiple={multiple}
                        style={{ height: height, }} autoComplete={autoComplete}
                    />
                    {icon && icon}
                </div>


            </div>
        </>
    )
}

export default CustomInput