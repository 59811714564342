import React, { useState } from 'react'
import Skeleton from 'react-loading-skeleton';
import { connect, useSelector } from 'react-redux';
import { ArrowDown } from '../assets/images/icons';
import { getRiders } from '../redux/actions/OtherActions';
import './utils.css';
const CustomRidersDropDown = (props) => {

    const { riders, gettingRiders } = useSelector(state => state.others)
    // console.log(riders, 'fj')
    const { rider, selectRider, setOpen, open } = props
    // console.log(rider, 'rider')
    const fetchRiders = async () => {
        if (riders?.length === 0) {
            try {
                const res = await props.getRiders()
                // console.log(res)

            } catch (error) {
                console.log(error)
            }
        }
    }

    const toggler = () => {
        fetchRiders()
        setOpen(previousState => !previousState);

    }


    const renderRiders = () => {

        if (gettingRiders) {
            return (
                <>
                    <Skeleton width={'100%'} height={'40px'} style={{ margin: "10px auto" }} />
                    <Skeleton width={'100%'} height={'40px'} />
                    <Skeleton width={'100%'} height={'40px'} style={{ margin: "10px auto" }} />
                    <Skeleton width={'100%'} height={'40px'} />
                </>

            )
        }

        if (riders && riders?.data?.users?.length !== 0) {
            return riders?.data?.users?.map((item, index) => {
                // console.log(item, 'us')
                return (
                    <li className={'customRiderSelectList regularText f16 flexRow alignCenter justifyBetween'}
                        onClick={() => selectRider(item)} key={index}
                        style={{ color: '#000000', cursor: 'pointer' }}
                    >
                        <div className={'flexRow alignCenter'}>
                            <div className={'userOrderAvatar'}>
                                <img src={`${item?.avatar}`} style={{ width: '100%', height: '100%', borderRadius: '100px' }} />
                            </div>
                            <div>
                                <p className={'f12 boldText'}>{item?.firstName || 'null null'}</p>
                                <div className={'flexRow alignCenter flexWrap'}>
                                    <p className={'semiBold f10'} style={{ marginRight: '2px', color: ' #919294' }}>{item?.email || 'null'}</p>
                                    <p className={'semiBold f10'} style={{ marginRight: '2px', color: ' #919294' }}>{item?.phone || 'null'}</p>
                                    <p className={'semiBold f10'} style={{ marginRight: '2px', color: ' #919294' }}> | {item?.Rider?.riderOperationLocation || 'null'}</p>
                                    <p className={'semiBold f10'} style={{ marginRight: '2px', color: ' #919294' }}> | {'Bike'}</p>
                                </div>
                            </div>
                        </div>
                        <div>
                            <p className={'availabilityContainer boldText f10'}
                                style={{ color: item?.Rider?.isRiderActive ? '#66cb9f' : '#F16063', backgroundColor: item?.Rider?.isRiderActive ? '#deffee' : '#FFE6E4' }}>
                                {item?.Rider?.isRiderActive ? 'Available' : 'Not Available'}</p>
                        </div>
                    </li>
                )
            })
        }
    }

    return (
        <>
            <div className={'flexRow alignCenter justifyBetween customSelectContainer'} onClick={toggler}>
                <div>
                    <span className={'f14 regularText'} style={{ color: '#999CA0', marginBottom: '0' }}>{rider ? rider?.email : 'Select rider'}</span>
                </div>
                <div>
                    <img src={ArrowDown} alt={'icon'} />
                </div>
                {open && riders &&
                    <ul className={'customSelectListContainer'} >
                        {renderRiders()}
                    </ul>
                }
            </div>
        </>
    )
}

export default connect(null, { getRiders })(CustomRidersDropDown);