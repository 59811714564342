import React, { useState } from 'react';
import { NavLink, useNavigate } from 'react-router-dom';
import { Logo } from '../assets/images/Index';
import './widgetStyles.css';
import { BiCog, BiHomeAlt } from 'react-icons/bi'
import { AiOutlineFileText, AiOutlineSearch } from 'react-icons/ai';
import { IoServerOutline } from 'react-icons/io5'
import { FiUsers } from 'react-icons/fi';
import { useDispatch } from 'react-redux';
import { SIGNOUT_USER_SUCCESSFUL } from '../redux/Types';
import SearchIcon from '../assets/images/icons/searchNavIcon.svg';
import NotifyIcon from '../assets/images/icons/notifyNavIcon.svg';
import UserIcon from '../assets/images/icons/userNavIcon.svg';
import { useMediaQuery } from 'react-responsive';
import { HiOutlineMenuAlt3 } from 'react-icons/hi'
import { getUsers } from '../redux/actions/UsersActions';


const Navigation = () => {
    const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1200px)' })
    const [dropDown, setDropDown] = useState(false);
    const navigate = useNavigate()
    const toggleDropDown = () => {
        setDropDown(prevState => !prevState)
    }
    const dispatch = useDispatch()

    const logout = () => {
        // dispatch({ type: SIGNOUT_USER_SUCCESSFUL })
        // localStorage.clear()
        // sessionStorage.clear()
        navigate('/')
    }

    const toSearch = () => {
        navigate('/search')
    }

    const fetchUsers = () => {
        dispatch(getUsers())
    }

    return (
        <>
            <nav className={'navContainer'}>
                <section className={'logoContainer'}>
                    <img src={Logo} alt="logo" />
                </section>
                <section className={'linksContainer'}>
                    <ul>
                        <li>
                            <NavLink to={'/'}
                                className={({ isActive }) => (isActive ? 'navLinkActive flexRow alignCenter' : 'navLinkInactive flexRow alignCenter')} >
                                <BiHomeAlt style={{ marginRight: '10px' }} />
                                <p className={'f16 semiBoldText'}>Dashboard</p>
                            </NavLink>
                        </li>
                        {isTabletOrMobile ?
                            <li>
                                <NavLink to={'/search'}
                                    className={({ isActive }) => (isActive ? 'navLinkActive flexRow alignCenter' : 'navLinkInactive flexRow alignCenter')} >
                                    <AiOutlineSearch style={{ marginRight: '10px' }} />
                                    <p className={'f16 semiBoldText'}>Search</p>
                                </NavLink>
                            </li>

                            : null}
                        <li>
                            <NavLink to={'/orderManagement'}
                                className={({ isActive }) => (isActive ? 'navLinkActive flexRow alignCenter' : 'navLinkInactive flexRow alignCenter')} >
                                <AiOutlineFileText style={{ marginRight: '10px' }} />
                                <p className={'f16 semiBoldText'}>Order management</p>
                            </NavLink>
                        </li>
                        {isTabletOrMobile ?

                            <li>
                                <NavLink to={'/more'}
                                    className={({ isActive }) => (isActive ? 'navLinkActive flexRow alignCenter' : 'navLinkInactive flexRow alignCenter')} >
                                    <HiOutlineMenuAlt3 style={{ marginRight: '10px' }} />
                                    {/* <p className={'f16 semiBoldText'}>Order management</p> */}
                                </NavLink>
                            </li> : null
                        }

                        {!isTabletOrMobile &&
                            <li>
                                <NavLink to={'/products'}
                                    className={({ isActive }) => (isActive ? 'navLinkActive flexRow alignCenter' : 'navLinkInactive flexRow alignCenter')} >
                                    <IoServerOutline style={{ marginRight: '10px' }} />
                                    {/* <img src={UserManagementIcon} alt="icon" /> */}
                                    <p className={'f16 semiBoldText'}>Products</p>
                                </NavLink>
                            </li>}

                        {!isTabletOrMobile &&
                            <li>
                                <NavLink to={'/userManagement'}
                                    className={({ isActive }) => (isActive ? 'navLinkActive flexRow alignCenter' : 'navLinkInactive flexRow alignCenter')} onClick={fetchUsers}>
                                    <FiUsers style={{ marginRight: '10px' }} />
                                    {/* <img src={UserManagementIcon} alt="icon" /> */}
                                    <p className={'f16 semiBoldText'}>Users</p>
                                </NavLink>
                            </li>}
                        {!isTabletOrMobile &&
                            <li>
                                <NavLink to={'/settings'}
                                    className={({ isActive }) => (isActive ? 'navLinkActive flexRow alignCenter' : 'navLinkInactive flexRow alignCenter')} onClick={fetchUsers}>
                                    <BiCog style={{ marginRight: '10px' }} />
                                    {/* <img src={UserManagementIcon} alt="icon" /> */}
                                    <p className={'f16 semiBoldText'}>Settings</p>
                                </NavLink>
                            </li>}

                    </ul>
                </section>
                {!isTabletOrMobile &&
                    <section>
                        <ul className={'flexRow alignCenter'}>
                            <li onClick={toSearch}>
                                <img src={SearchIcon} alt='icon' />
                            </li>
                            <li>
                                <img src={NotifyIcon} alt='icon' />
                            </li>
                            <li onClick={logout}>
                                <img src={UserIcon} alt='icon' />
                            </li>
                        </ul>
                    </section>}

            </nav>
        </>
    )
}

export default Navigation