import React from 'react'
import Skeleton from 'react-loading-skeleton'
import { useSelector } from 'react-redux'

const UserCardContainer = (props) => {

    const { icon, title, children } = props
    const { gettingUserProfile } = useSelector(state => state.users)

    return (

        <>
            {gettingUserProfile ? <Skeleton className={'userLargeCardContainer'} style={{ height: '400px', width: '613px' }} /> :
                <section className={'userLargeCardContainer'}>
                    <div className={'userLargeCardHeader'}>
                        {icon}
                        <h5 className={'f16 boldText'} style={{ color: '#38383A' }}>{title}</h5>
                    </div>
                    <div>
                        {children}
                    </div>
                </section>}
        </>

    )
}

export default UserCardContainer