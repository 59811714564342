import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import useKeyboardAwareHook from '../../../hooks/useKeyboardAwareHook';
import CustomDropDown from '../../../utils/CustomDropDown';
import ImageUpload from '../../../utils/ImageUploadInput';
import http from '../../../utils/utils';
import axios from 'axios';
import AIImageUpload from '../../../utils/AIImageUpload';
import CustomButton from '../../../utils/CustomButton';

const CreateWithAIModal = ({ isOpen, onClose, openPreviewModal, menuData, setMenuData, business, setBusiness }) => {
    useKeyboardAwareHook()

    const [file, setFile] = useState(null);
    const [loading, setLoading] = useState(false);


    const dispatch = useDispatch();
    const selectBusiness = (val) => {
        setBusiness(val)
    }


    const handleFileChange = async (event) => {
        const files = event.target.files;
        if (!files || files.length === 0) {
            console.error('No file selected');
            return;
        }

        const file = files[0];
        setFile(file);

        const formData = new FormData();
        formData.append("file", file);

        // Log formData to verify the file is appended correctly
        for (let [key, value] of formData.entries()) {
            console.log(`${key}:`, value);
        }

        setLoading(true);

        try {
            const response = await axios.post('https://ai.dado.ng/menu-extractor', formData);

            if (response.status >= 200 && response.status < 300) {
                console.log('Upload Successful:', response.data);
                setMenuData(response.data?.menu?.items);
            } else {
                console.error(`Unexpected response status: ${response.status}`);
            }
        } catch (error) {
            if (error.response) {
                console.error('Server Error:', error.response.data);
                console.error('Status Code:', error.response.status);
            } else if (error.request) {
                console.error('No Response:', error.request);
            } else {
                console.error('Error:', error.message);
            }
        } finally {
            setLoading(false);
        }
    };

    const handleRemove = (hiddenFileInput) => {
        setFile(null); // Reset the file state
        if (hiddenFileInput.current) {
            hiddenFileInput.current.value = ''; // Reset the file input field
        }
    };

    const disabled = () => {

        return !file || !business || !loading;
    };


    const renderButton = () => {
        if (menuData) {
            return (
                <CustomButton title={'Preview menu'} bgColor={'#FFA024'} onClick={openPreviewModal} />
            )
        }
        return <CustomButton title={'Create menu'} disable={disabled()} />
    }



    return (
        <div className={`createWithAIModal ${isOpen ? 'open' : ''}`} onClick={onClose}>
            <div className="createWithAIModalContent" onClick={(e) => e.stopPropagation()}>
                <header>
                    <h3 className={'boldText f16'}>Create Products With AI</h3>
                </header>
                <div style={{ margin: '20px 0 16px 0' }}>
                    <p className={`f14 grey semiBoldText flex alignCenter`} style={{ marginBottom: "10px" }} >
                        Vendors
                    </p>
                    <CustomDropDown business={business} selectBusiness={selectBusiness} label={'Vendor'} />
                </div>
                <div>
                    <AIImageUpload label={'Upload menu image'} handleImageChange={handleFileChange} image={file} loading={loading} handleRemove={handleRemove} />
                </div>

                <div style={{ margin: '30px 0' }}>
                    {renderButton()}
                </div>
            </div>
        </div>
    );
};

export default CreateWithAIModal;