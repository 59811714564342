import React from 'react';
import Logo from '../assets/images/logo.svg';
import Navigation from '../widgets/Navigation';

const ComingSoon = () => {
    return (
        <>
            <div className={'loaderContainer'}>
                <img src={Logo} alt='logo' />
                <p className={'boldText f20'}>Coming soon!!!!</p>

            </div>
            <Navigation />
        </>

    )
}

export default ComingSoon