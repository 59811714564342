import React, { useEffect, useState } from 'react';
import { OrderIcon, PlusIcon } from '../../../assets/images/icons';
import MultiButton from '../../../utils/MultiButton';
import Navigation from '../../../widgets/Navigation';
import '../../../assets/styles/orderManagementStyles.css';
import StatsCard from '../../../components/all/StatsCard';
import CustomOrderTable from '../../../components/all/CustomOrdersTable';
import { getOrders, getOrdersByPage, getOrdersByStatus } from '../../../redux/actions/OrdersAction';
import { connect, useDispatch, useSelector } from 'react-redux';
import ActiveOrders from '../../../components/dashboard/orderManagement/ActiveOrders';
import CreateOrderModal from '../../../components/dashboard/orderManagement/CreateOrderModal';
import CreateOrderForm from '../../../components/dashboard/orderManagement/createOrderForms/CreateOrderForm';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import { Outlet, useNavigate } from 'react-router-dom';
import { useMediaQuery } from 'react-responsive';
import OrderManagementMobileWidget from '../../../widgets/OrderManagementMobileWidget';
import { BackWardIcon, ForwardIcon, } from '../../../assets/images/icons';


const OrderManagementHome = (props) => {
    const isDesktopOrLaptop = useMediaQuery({ query: '(min-width: 1200px)' })
    const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1200px)' })

    const { orders, gettingOrders, gettingActiveOrders } = useSelector(state => state.orders)
    // console.log(orders, 'orders')
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const [meta, setMeta] = useState({
        count: orders?.data?.meta?.count, currentPage: orders?.data?.meta?.currentPage,
        perPage: orders?.data?.meta?.perPage, total: orders?.data?.meta?.total, totalPages: orders?.data?.meta?.totalPages
    });
    // console.log(meta)
    const [state, setState] = useState({
        searchValue: '', searchedRestaurants: [],
    })

    const handlePageChange = pageNumber => {
        setMeta({ ...meta, currentPage: pageNumber });
        dispatch(getOrdersByPage(pageNumber))
    };


    const pageNumbers = Array.from({ length: orders?.data?.meta?.totalPages }, (_, index) => index + 1);
    // console.log(pageNumbers, orders?.meta?.totalPages)
    const maxVisibleNumbers = 5; // Maximum visible numbers on the screen


    let visiblePageNumbers = [];

    if (pageNumbers.length <= maxVisibleNumbers) {
        visiblePageNumbers = pageNumbers; // Show all numbers if the array length is smaller or equal to the maximum visible numbers
    } else {
        const halfVisibleNumbers = Math.floor((maxVisibleNumbers - 1) / 2);
        const startPage = meta?.currentPage - halfVisibleNumbers;
        const endPage = meta?.currentPage + halfVisibleNumbers;

        if (startPage <= 1) {
            visiblePageNumbers = [...pageNumbers.slice(0, maxVisibleNumbers - 1), '...'];
        } else if (endPage >= pageNumbers.length) {
            visiblePageNumbers = ['...', ...pageNumbers.slice(pageNumbers.length - maxVisibleNumbers + 2)];
        } else {
            visiblePageNumbers = ['...', ...pageNumbers.slice(startPage, endPage + 1), '...'];
        }
    }

    const openCreateOrderModal = () => {
        navigate('createOrder')
    }


    const fetchOrders = async () => {
        try {
            const allOrders = await props.getOrders()
            const activeOrders = await props.getOrdersByStatus()
            // console.log(activeOrders)
        } catch (error) {
            // console.log(error)
        }
    }

    const fetchActiveOrders = async () => {
        try {
            const activeOrders = await props.getOrdersByStatus()
            // console.log(activeOrders)
        } catch (error) {
            // console.log(error)
        }
    }


    useEffect(() => {
        fetchOrders()
        fetchActiveOrders()
    }, [])



    const renderStatusCards = () => {
        if (gettingOrders) {
            return (
                <div className={'statsGrid'}>
                    <div>
                        <Skeleton className={'statsCardSkeleton'} />
                    </div>
                    <div>
                        <Skeleton className={'statsCardSkeleton'} />
                    </div>
                    <div>
                        <Skeleton className={'statsCardSkeleton'} />
                    </div>
                    <div>
                        <Skeleton className={'statsCardSkeleton'} />
                    </div>
                </div>
            )
        }
        if (orders && orders?.data) {
            return (
                <div className={'statsGrid'}>
                    <StatsCard icon={OrderIcon} title={'All orders'} count={orders?.data?.totalOrders} rate={'0'} rateColor={'#4CAF50'} rateBg={'#E8F5E9'} bgColor={'#FF92AE'} />
                    <StatsCard icon={OrderIcon} title={'Online orders'} count={orders?.data?.totalOnlineOrders} rate={'0'} rateColor={'#4CAF50'} rateBg={'#E8F5E9'} bgColor={'#4C6FFF'} />
                    <StatsCard icon={OrderIcon} title={'Offline orders'} count={orders?.data?.totalOfflineOrders} rate={'0'} rateColor={'#4CAF50'} rateBg={'#E8F5E9'} bgColor={'#68DBF2'} />
                    <StatsCard icon={OrderIcon} title={'Completed orders'} count={orders?.data?.totalOrdersDelivered} rate={'0'} rateColor={'#4CAF50'} rateBg={'#E8F5E9'} bgColor={'#F7936F'} />
                </div>
            )
        }
    }


    const onChangeSearch = (e) => {
        let arr = orders?.data?.orders
        const searched = arr?.filter((x) =>
            x?.orderType.toLowerCase().includes(e.target.value?.toLowerCase()) ||
            x?.orderStatus.toLowerCase().includes(e.target.value?.toLowerCase()) ||
            x?.orderNo.toLowerCase().includes(e.target.value?.toLowerCase()) ||
            x?.paymentStatus.toLowerCase().includes(e.target.value?.toLowerCase()) ||
            x?.originBusinessName.toLowerCase().includes(e.target.value?.toLowerCase())
        );
        setState((prevState) => ({ ...prevState, searchValue: e.target.value, searchedRestaurants: searched }))
        // console.log(searched)

    }

    const [activeOrders, setActiveOrders] = useState([]);

    useEffect(() => {
        // Function to filter and set active orders
        const returnActiveOrders = () => {
            if (!orders || !orders.data || !orders.data.orders) {
                setActiveOrders([]);
                return;
            }

            const arr = orders.data.orders;
            const filterActiveOrders = arr.filter((x) =>
                ['ORDER_ASSIGNED', 'ORDER_ACCEPTED', 'ORDER_REJECTED', 'ORDER_PICKED_UP', 'ORDER_CONFIRMED'].includes(
                    x?.orderStatus
                )
            );
            setActiveOrders(filterActiveOrders);
        };

        returnActiveOrders();
    }, [orders]);







    return (
        <>
            {isDesktopOrLaptop &&
                <>
                    <Navigation />
                    <main className={'orderManagementContainer'}>
                        <section className={'flexRow alignCenter justifyBetween miniNav'}>
                            <div>
                                <h3 className={'boldText f24'}>Order management</h3>
                                <p className={'f16 regularText'}>Create & Manage orders</p>
                            </div>
                            <div>
                                <MultiButton title={'Create order'} icon={PlusIcon} bgColor={'#FFA024'} onClick={openCreateOrderModal} height={'48px'} width={'167px'} />
                            </div>
                        </section>
                        <section >
                            {renderStatusCards()}
                        </section>
                        <section className={'ordersSection'}>
                            <CustomOrderTable orders={orders?.data} gettingOrders={gettingOrders} meta={meta} setMeta={setMeta} onPageChange={handlePageChange} />
                            <ActiveOrders activeOrders={activeOrders} gettingActiveOrders={gettingOrders} title={'Active orders'} total={false} />
                        </section>
                    </main>
                </>
            }
            {isTabletOrMobile && <>
                <Navigation />
                <OrderManagementMobileWidget openCreateOrderModal={openCreateOrderModal} searchValue={state.searchValue} onChange={onChangeSearch} page={meta?.currentPage} />
                <section style={{ padding: "0 20px 100px 20px" }}>
                    <ActiveOrders activeOrders={orders?.data?.orders} gettingActiveOrders={gettingOrders} title={'All orders'} total={false} searchedRestaurants={state.searchedRestaurants}
                        searchValue={state.searchValue}
                    />
                    <div className={'flexRow alignCenter paginationContainer'}>
                        <button disabled={meta?.currentPage === 1} onClick={() => handlePageChange(meta?.currentPage - 1)} className={'prevBtn'}>
                            <img src={BackWardIcon} alt='icon' />
                        </button>
                        <ul className={'flexRow alignCenter'}>
                            {visiblePageNumbers.map(pageNumber => (
                                <li key={pageNumber} className={'pageNumberContainer regularText f14'}>
                                    <button onClick={() => handlePageChange(pageNumber)} className={meta?.currentPage === pageNumber ? "active" : ""}>
                                        {pageNumber}
                                    </button>
                                </li>
                            ))}
                        </ul>
                        <button disabled={meta?.currentPage === orders?.data?.meta?.totalPages} onClick={() => handlePageChange(meta?.currentPage + 1)} className={'nextBtn'}>
                            <img src={ForwardIcon} alt='icon' />
                        </button>

                    </div>
                </section>
            </>}

        </>
    )
}

export default connect(null, { getOrders, getOrdersByStatus })(OrderManagementHome)