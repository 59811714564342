import React, { useState } from 'react'
import CustomButton from '../../utils/CustomButton'
import CustomInput from '../../utils/CustomInput'
import '../../assets/styles/authStyles.css';
import AuthContainer from '../../components/auth/AuthContainer';
import AuthHeader from '../../components/auth/AuthHeader';
import { connect } from 'react-redux';
import { resetPassword } from '../../redux/actions/AuthActions'
import { showError } from '../../utils/Toast';
import { useLocation, useNavigate } from 'react-router-dom';
import { AiOutlineEyeInvisible, AiOutlineEye } from 'react-icons/ai'


const ResetPassword = (props) => {

    const [state, setState] = useState({
        confirmPassword: '', password: '', showPassword: false,
        showConfirmPassword: false,
    })

    const navigate = useNavigate()
    const { confirmPassword, password, } = state
    const params = useLocation()
    const token = params?.state?.token

    const onChangePassword = (e) => {
        setState({ ...state, password: e.target.value })
    }

    const onChangeConfirmPassword = (e) => {
        setState({ ...state, confirmPassword: e.target.value })
    }

    const togglePassword = () => {
        if (state.showPassword) {
            setState((prevState) => ({ ...prevState, showPassword: false }))
        } else {
            setState((prevState) => ({ ...prevState, showPassword: true }))
        }
    }

    const toggleConfirmPassword = () => {
        if (state.showConfirmPassword) {
            setState((prevState) => ({ ...prevState, showConfirmPassword: false }))
        } else {
            setState((prevState) => ({ ...prevState, showConfirmPassword: true }))
        }
    }

    const passwordCheck = async () => {
        if (password !== '' && confirmPassword !== '') {
            if (password === confirmPassword) {
                const obj = { token, new_password: password }
                try {
                    const res = await props.resetPassword(obj)
                    console.log('request otp', res)
                    // showSuccess(res?.message)
                    if (res) {
                        navigate('/resetPasswordDone', {
                            state: { message: res?.message }
                        })
                    }
                } catch (error) {
                    console.log('request otp', error)
                    showError(error[1].message)
                }
            } else {
                showError('Passwords do not match')
            }
        }
    }

    const reset = async () => {
        passwordCheck()
    }

    const disabled = () => {
        if (state.password === '' || state.confirmPassword === '') {
            return true
        }
        return false
    }



    return (
        <>
            <AuthContainer logo={true}>
                <AuthHeader title={'Password reset'} subTitle={'Enter code to reset password'} />
                <CustomInput label={'Password'} onChange={onChangePassword} value={state.password} placeholder={'Enter new password '}
                    type={state.showPassword ? 'text' : 'password'} icon={state.showPassword ? <AiOutlineEye color='#B1B2B4' onClick={togglePassword} /> : <AiOutlineEyeInvisible color='#B1B2B4' onClick={togglePassword} />}
                />
                <CustomInput label={'Confirm password'} onChange={onChangeConfirmPassword} value={state.confirmPassword} placeholder={'Confirm password'}
                    type={state.showConfirmPassword ? 'text' : 'password'} icon={state.showConfirmPassword ? <AiOutlineEye color='#B1B2B4' onClick={togglePassword} /> : <AiOutlineEyeInvisible color='#B1B2B4' onClick={togglePassword} />} />
                <div style={{ marginTop: '40px' }}>
                    <CustomButton title={'Reset'} onClick={reset} bgColor={'#FFA024'} disabled={disabled()} />
                </div>
            </AuthContainer>
        </>
    )
}

export default connect(null, { resetPassword })(ResetPassword)