import React, { useState } from "react";
import GooglePlacesAutocomplete from "react-google-places-autocomplete";
import { AddToCart, AddToCartMobile } from "../../../../assets/images/Index";
import CityDropDown from "../../../../utils/CityDropDown";
import CustomDropDown from "../../../../utils/CustomDropDown";
import CustomInput from "../../../../utils/CustomInput";
import { AiOutlineClose } from "react-icons/ai";
import { useDispatch, useSelector } from "react-redux";
import CustomMessageInput from "../../../../utils/CustomMessageInput";
import { useMediaQuery } from "react-responsive";
import MealSetupCounter from "./MealSetupCounter";

const MealSetupForm = (props) => {
  const isDesktopOrLaptop = useMediaQuery({ query: "(min-width: 1430px)" });
  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 1430px)" });

  const { onChangeMealPrice, onChangeMealTitle, delivery_address, setDeliveryAddress, options, addToCart, mealPrice, mealTitle, quantity, cart, onChangeQuantity,
    deleteFromCart, business_address, setBusinessAddress, business,
    setBusiness, selectBusiness, selectStateOfResidence, state_of_residence, message, onChangeMessage, bizName, onChangeBizName,
    addQuantity,
    decreaseQuantity
  } = props;

  const disabled = () => {
    if (bizName === '') {
      return true
    }
    return false
  }
  return (
    <>
      {isDesktopOrLaptop && (
        <section>
          <div style={{ paddingBottom: "24px" }}>
            <div className={"createOrderModalHeader"}>
              <h3 className={"f24 boldText"} style={{ color: "#565758" }}>
                Meal setup & Delivery information
              </h3>
            </div>
            <div className={"flexRow alignCenter"} style={{ gap: "16px" }}>
              <div style={{ width: "100%" }}>
                <p className={"f14 grey semiBoldText"} style={{ marginBottom: "8px" }}>
                  Vendor
                </p>
                <CustomDropDown business={business} setBusiness={setBusiness} selectBusiness={selectBusiness} bizName={bizName} onChangeBizName={onChangeBizName} />
              </div>
              <div style={{ width: "100%" }}>
                <p className={"f14 grey semiBoldText"} style={{ marginBottom: "8px" }}>
                  Vendor address
                </p>
                <GooglePlacesAutocomplete
                  apiKey="AIzaSyDUfMsEQrsuKTq2KpyUZZ8KGsPmTkekGW0"

                  selectProps={{
                    business_address,
                    onChange: setBusinessAddress,
                    isDisabled: disabled(),
                    styles: {
                      control: (provided) => ({
                        ...provided,
                        border: "1px solid #EBEBEB",
                        borderRadius: "8px",
                        outline: "none",
                        hover: "none",
                        padding: "8px 12px",
                      }),
                      placeholder: (provided) => ({
                        ...provided,
                        color: " #999CA0",
                        fontFamily: "Nunito",
                        fontSize: "14px",
                      }),
                    },
                    placeholder: "Enter delivery address",
                  }}
                  autocompletionRequest={{
                    componentRestrictions: {
                      country: ["ng"],
                    },
                  }}
                />
              </div>
            </div>
            <div className={"flexRow alignCenter"} style={{ gap: "16px", marginTop: "24px" }}>
              <div style={{ width: "100%" }}>
                <CustomInput label="Meal title" placeholder={"Enter meal title"} onChange={onChangeMealTitle} value={mealTitle} autoComplete={true} />
              </div>
              <div style={{ width: "100%" }}>
                <CustomInput label="Meal price" placeholder={"Enter meal price"} onChange={onChangeMealPrice} value={mealPrice} autoComplete={true} />
              </div>
              <div style={{ width: "100%" }}>
                {/* <CustomInput label="Quantity(Packs)" placeholder={"Enter meal quantity(packs)"} onChange={onChangeQuantity} value={quantity} autoComplete={true} /> */}
                <MealSetupCounter mealQuantity={quantity} addMealQuantity={addQuantity} decreaseMealQuantity={decreaseQuantity} />

              </div>
            </div>
            <div className={"flexRow justifyBetween"} style={{ position: "relative", marginBottom: "24px" }}>
              {cart?.length !== 0 && (
                <div className={"cartList"}>
                  {cart?.map((item, index) => {
                    return (
                      <div key={index} className={"flexRow alignCenter justifyBetween cartListItem"}>
                        <p className={"f12 boldText"} style={{ marginRight: "5px" }}>
                          x{item?.quantity}
                        </p>
                        <p className={"f12 boldText"} style={{ marginRight: "5px" }}>
                          {item?.name}
                        </p>
                        <p className={"f12 boldText"} style={{ marginRight: "5px" }}>
                          ₦{item?.price}
                        </p>
                        <AiOutlineClose style={{ justifySelf: "flex-end", fontSize: "12px", cursor: "pointer" }} onClick={() => deleteFromCart(index)} />
                      </div>
                    );
                  })}
                </div>
              )}
              <div style={{ position: "absolute", right: "10px", top: "0" }}>
                <img src={AddToCart} alt={"button"} onClick={addToCart} style={{ maxWidth: "150px", height: "35px" }} />
              </div>
            </div>
            <div className={"flexRow"} style={{ gap: "16px", marginTop: "24px" }}>
              <div style={{ width: "100%" }}>
                <div style={{ width: "100%", marginBottom: "24px" }}>
                  <p className={"f14 grey semiBoldText"} style={{ marginBottom: "8px" }}>
                    State of residence
                  </p>
                  <CityDropDown selectData={selectStateOfResidence} data={state_of_residence} options={options} />
                </div>
                <div style={{ width: "100%" }}>
                  <p className={"f14 grey semiBoldText"} style={{ marginBottom: "8px" }}>
                    Delivery address
                  </p>
                  <GooglePlacesAutocomplete
                    apiKey="AIzaSyDUfMsEQrsuKTq2KpyUZZ8KGsPmTkekGW0"
                    selectProps={{
                      delivery_address,
                      onChange: setDeliveryAddress,
                      styles: {
                        control: (provided) => ({
                          ...provided,
                          border: "1px solid #EBEBEB",
                          borderRadius: "8px",
                          outline: "none",
                          hover: "none",
                          padding: "8px 12px",
                        }),
                        placeholder: (provided) => ({
                          ...provided,
                          color: " #999CA0",
                          fontFamily: "Nunito",
                          fontSize: "14px",
                        }),
                      },
                      placeholder: "Enter delivery address",
                    }}
                    autocompletionRequest={{
                      componentRestrictions: {
                        country: ["ng"],
                      },
                    }}
                  />
                </div>
              </div>
              <div style={{ width: "100%" }}>
                <CustomMessageInput label={"Message"} height={"120px"} onChange={onChangeMessage} value={message} />
              </div>
            </div>
          </div>
        </section>
      )}

      {isTabletOrMobile && (
        <section style={{ paddingBottom: "150px" }}>
          <div style={{ paddingBottom: "24px" }}>
            <div className={"mobileCreateOrderModalHeader"}>
              <h3 className={"f16 boldText textCenter"} style={{ color: "#565758" }}>
                Meal setup & Delivery information
              </h3>
            </div>
            <div>
              <div style={{ width: "100%", marginBottom: "16px" }}>
                <p className={"f14 grey semiBoldText"} style={{ marginBottom: "8px" }}>
                  Vendor
                </p>
                <CustomDropDown business={business} setBusiness={setBusiness} selectBusiness={selectBusiness} bizName={bizName} onChangeBizName={onChangeBizName} />
              </div>
              <div style={{ width: "100%" }}>
                <p className={"f14 grey semiBoldText"} style={{ marginBottom: "8px" }}>
                  Vendor address
                </p>
                <GooglePlacesAutocomplete
                  apiKey="AIzaSyDUfMsEQrsuKTq2KpyUZZ8KGsPmTkekGW0"
                  selectProps={{
                    business_address,
                    onChange: setBusinessAddress,
                    isDisabled: disabled(),
                    styles: {
                      control: (provided) => ({
                        ...provided,
                        border: "1px solid #EBEBEB",
                        borderRadius: "8px",
                        outline: "none",
                        hover: "none",
                        padding: "8px 12px",
                      }),
                      placeholder: (provided) => ({
                        ...provided,
                        color: " #999CA0",
                        fontFamily: "Nunito",
                        fontSize: "14px",
                      }),
                    },
                    placeholder: "Enter delivery address",
                  }}
                  autocompletionRequest={{
                    componentRestrictions: {
                      country: ["ng"],
                    },
                  }}
                />
              </div>
            </div>
            <div style={{ gap: "16px", marginTop: "16px" }}>
              <div style={{ width: "100%" }}>
                <CustomInput label="Meal title" placeholder={"Enter meal title"} onChange={onChangeMealTitle} value={mealTitle} autoComplete={true} />
              </div>
              <div style={{ width: "100%" }}>
                <CustomInput label="Meal price" placeholder={"Enter meal price"} onChange={onChangeMealPrice} value={mealPrice} autoComplete={true} />
              </div>
              <div style={{ width: "100%", marginBottom: '10px' }}>
                {/* <CustomInput label="Quantity(Packs)" placeholder={"Enter meal quantity(packs)"} onChange={onChangeQuantity} value={quantity} autoComplete={true} /> */}
                <MealSetupCounter mealQuantity={quantity} addMealQuantity={addQuantity} decreaseMealQuantity={decreaseQuantity} />
              </div>
            </div>
            <div style={{ position: "relative", marginBottom: "24px" }}>
              {cart?.length !== 0 && (
                <div className={"cartList"}>
                  {cart?.map((item, index) => {
                    return (
                      <div key={index} className={"flexRow alignCenter justifyBetween flexWrap cartListItem"}>
                        <p className={"f12 boldText"}>
                          x{item?.quantity}
                        </p>
                        <p className={"f12 boldText"}>
                          {item?.name}
                        </p>
                        <p className={"f12 boldText"} >
                          ₦{item?.price}
                        </p>
                        <AiOutlineClose style={{ justifySelf: "flex-end", fontSize: "12px", cursor: "pointer" }} onClick={() => deleteFromCart(index)} />
                      </div>
                    );
                  })}
                </div>
              )}
              <div >
                <img src={AddToCartMobile} alt={"button"} onClick={addToCart} style={{ width: '100%', height: '38px', objectFit: 'contain' }} />
              </div>
            </div>
            <div style={{ marginTop: "16px" }}>
              <div style={{ width: "100%" }}>
                <div style={{ width: "100%", marginBottom: "16px" }}>
                  <p className={"f14 grey semiBoldText"} style={{ marginBottom: "8px" }}>
                    State of residence
                  </p>
                  <CityDropDown selectData={selectStateOfResidence} data={state_of_residence} options={options} />
                </div>
                <div style={{ width: "100%" }}>
                  <p className={"f14 grey semiBoldText"} style={{ marginBottom: "8px" }}>
                    Delivery address
                  </p>
                  <GooglePlacesAutocomplete
                    apiKey="AIzaSyDUfMsEQrsuKTq2KpyUZZ8KGsPmTkekGW0"
                    selectProps={{
                      delivery_address,
                      onChange: setDeliveryAddress,
                      styles: {
                        control: (provided) => ({
                          ...provided,
                          border: "1px solid #EBEBEB",
                          borderRadius: "8px",
                          outline: "none",
                          hover: "none",
                          padding: "8px 12px",
                        }),
                        placeholder: (provided) => ({
                          ...provided,
                          color: " #999CA0",
                          fontFamily: "Nunito",
                          fontSize: "14px",
                        }),
                      },
                      placeholder: "Enter delivery address",
                    }}
                    autocompletionRequest={{
                      componentRestrictions: {
                        country: ["ng"],
                      },
                    }}
                  />
                </div>
              </div>
              <div style={{ width: "100%", marginTop: "16px" }}>
                <CustomMessageInput label={"Message"} height={"120px"} onChange={onChangeMessage} value={message} />
              </div>
            </div>
          </div>
        </section>
      )}
    </>
  );
};

export default MealSetupForm;
