
import React from 'react'
import AuthContainer from '../../components/auth/AuthContainer'
import AuthHeader from '../../components/auth/AuthHeader'
import CustomButton from '../../utils/CustomButton'
import '../../assets/styles/authStyles.css';
import { useLocation, useNavigate } from 'react-router-dom'
import { ResetDone } from '../../assets/images/Index';

const ResetPasswordDone = (props) => {
    const navigate = useNavigate()
    const params = useLocation();
    const message = params?.state?.message

    const toLogin = () => {
        navigate('/')
    }


    return (
        <>
            <AuthContainer back={true}>
                <section style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', textAlign: 'center' }}>
                    <div>
                        <img src={ResetDone} alt='admin' />
                    </div>
                    <AuthHeader title={'Password reset successful'} subTitle={message} />
                    <div style={{ margin: '40px auto 0 auto', width: '100%', }}>
                        <CustomButton title={'Proceed to login'} onClick={toLogin} bgColor={'#FFA024'} />
                    </div>
                </section>
            </AuthContainer>
        </>
    )
}

export default ResetPasswordDone;