import React from 'react';
import Skeleton from 'react-loading-skeleton';
import { ArrowDown } from '../assets/images/icons';
import './utils.css';

const CustomStatusDropDown = ({ options, selectedOption, setSelectedOption, open, setOpen }) => {

    const toggler = () => {
        setOpen(prevState => !prevState);
    };

    const renderOptions = () => {
        if (!options || options.length === 0) {
            return (
                <>
                    <Skeleton width={'100%'} height={'40px'} style={{ margin: "10px auto" }} />
                    <Skeleton width={'100%'} height={'40px'} />
                </>
            );
        }

        return options.map((item, index) => (
            <li className={'customRiderSelectList regularText f16 flexRow alignCenter justifyBetween'}
                onClick={() => selectOption(item.value)} key={index}
                style={{ color: '#000000', cursor: 'pointer' }}>
                <div>
                    <p className={'f12 boldText'}>{item.key}</p>
                </div>
            </li>
        ));
    };

    const selectOption = (value) => {
        setSelectedOption(value);  // Set the selected option in state
        setOpen(false);            // Close the dropdown immediately after selection
    };

    return (
        <>
            <div style={{ position: "relative" }}>
                <div className={'flexRow alignCenter justifyBetween customSelectContainer'} onClick={toggler}>
                    <div>
                        <span className={'f14 regularText'} style={{ color: '#999CA0', marginBottom: '0' }}>
                            {selectedOption ? options.find(option => option.value === selectedOption)?.key : 'Select status'}
                        </span>
                    </div>
                    <div>
                        <img src={ArrowDown} alt={'icon'} />
                    </div>
                </div>
                {open && options && (
                    <ul className={'customSelectListContainer'}>
                        {renderOptions()}
                    </ul>
                )}
            </div>





        </>
    );
};

export default CustomStatusDropDown;
