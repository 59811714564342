import { useEffect } from 'react';

const useKeyboardAwareHook = () => {
    useEffect(() => {
        const adjustModalPosition = () => {
            const modalContent = document.querySelector('.riderAgentModalContent');

            // Check if modalContent exists before applying styles
            if (modalContent) {
                if (window.innerHeight < 600) {
                    modalContent.style.transform = 'translateY(-50px)';
                } else {
                    modalContent.style.transform = 'translateY(0)';
                }
            }
        };

        window.addEventListener('resize', adjustModalPosition);

        // Run the function once on mount to set initial position
        adjustModalPosition();

        return () => {
            window.removeEventListener('resize', adjustModalPosition);
        };
    }, []);
};

export default useKeyboardAwareHook;
