import { REVENUES, REVENUES_FAIL, REVENUES_SUCCESSFUL, TRANSACTIONS, TRANSACTIONS_FAIL, TRANSACTIONS_SUCCESSFUL } from "../Types";


const INITIAL_STATE = {
    error: [],
    revenues: [],
    gettingRevenue: false,
    gettingTransactions: false,
    transactions: []
};

export default (state = INITIAL_STATE, { type, payload }) => {
    switch (type) {
        case REVENUES:
            return { ...state, gettingRevenue: true };
        case REVENUES_SUCCESSFUL:
            return { ...state, gettingRevenue: false, revenues: payload }
        case REVENUES_FAIL:
            return { ...state, gettingRevenue: false, error: payload }
        case TRANSACTIONS:
            return { ...state, gettingTransactions: true };
        case TRANSACTIONS_SUCCESSFUL:
            return { ...state, gettingRevenue: false, transactions: payload }
        case TRANSACTIONS_FAIL:
            return { ...state, gettingTransactions: false, error: payload }
        default:
            return state;
    }
};