import React from 'react'
import { useSelector } from 'react-redux';
import '../../../assets/styles/overviewStyles.css';
import RecentTransactionsRow from './RecentTransactionsRow';

const RecentTransactionsCard = () => {
    const { transactions, gettingTransactions } = useSelector(state => state.transactions)
    const renderTransactions = () => {
        if (transactions && transactions?.transactions?.length !== 0) {
            return transactions?.transactions?.map((item, index) => {
                return (
                    <div key={index}>
                        <RecentTransactionsRow data={item} />
                    </div>
                )
            })
        }
    }

    return (
        <>
            <section className={'recentTransactionsContainer'}>
                <div className={'flexRow alignCenter justifyBetween recentTransactionsHeader'}>
                    <p className={'f16 boldText'}>Recent transactions</p>
                    {/* <p className={'f12 semiBoldText'} style={{ color: '#FFA024' }}>See all</p> */}
                </div>
                <div style={{ padding: '16px' }}>
                    {renderTransactions()}
                </div>
            </section>
        </>
    )
}

export default RecentTransactionsCard