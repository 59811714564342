import React, { useRef, useState, useEffect } from 'react'
import http from '../../utils/utils';
import '../../assets/styles/searchStyles.css';
import { AiOutlineClose, } from 'react-icons/ai';
import SearchIcon from '../../assets/images/icons/searchBtn.svg';
import SearchEmpty from '../../assets/images/icons/noSearch.svg';
import { useMediaQuery } from 'react-responsive';
import FoodCard from '../../components/dashboard/products/FoodCard';
import { useDispatch, useSelector } from 'react-redux';
import GridCard from '../../components/dashboard/userManagement/GridCard';



const UsersSearch = () => {
    const [query, setQuery] = useState('');
    const [results, setResults] = useState([]);
    const isDesktopOrLaptop = useMediaQuery({ query: '(min-width: 1200px)' })
    const dispatch = useDispatch()
    const [filteredProducts, setFilteredProducts] = useState([])
    const { vendors } = useSelector(state => state.others)
    const handleInputChange = async (event) => {
        const query = event.target.value;
        setQuery(query);

        const obj = { query }

        try {
            const res = await http.post(`admin/users/search?page=1&limit=100`, obj)
            const data = res?.data
            setResults(data)
            console.log(res)
        } catch (error) {
            console.log(error)
        }
    };

    const handleSearchButtonClick = async (query) => {
        setQuery(query);
        const obj = { query }
        try {
            const res = await http.post(`admin/users/search?page=1&limit=15`, obj)
            const data = res?.data
            setResults(data)
        } catch (error) {
            console.log(error)
        }
    };
    const predefinedSearchWords = ['Intercontinental', 'Native kitchen', 'Bakery', 'Chinese food', 'Fit fam', 'Pastries', 'Promo', 'Quick bites', 'Sea food', 'Home chef',];

    const renderResult = () => {
        let allProducts = results?.users
        if (allProducts?.length !== 0) {
            if (filteredProducts?.length !== 0) {
                allProducts = filteredProducts
            }
            return allProducts?.map((item, index) => {
                return (
                    <div key={index} style={{ justifySelf: isDesktopOrLaptop ? 'center' : '' }}>
                        <GridCard item={item} />
                    </div>
                )
            })
        }

    }
    const renderEmptyState = () => {
        if (results?.users?.length === 0) {
            return (
                <div className={'emptySearchContainer'} style={{ paddingBottom: '100px' }}>
                    <img src={SearchEmpty} alt='ICON' />
                    <p className={'f18 mediumText'} style={{ color: "#919294", marginTop: "40px", textAlign: "center" }}>Your search results will be displayed here, toggle the categories above to see meals we have listed. </p>
                </div>
            )
        }
    }

    const renderHeader = () => {
        if (results?.length !== 0 && results?.products?.length !== 0 && filteredProducts.length !== 0) {
            return (
                <div style={{ marginTop: '40px', marginBottom: '24px', paddingLeft: isDesktopOrLaptop ? '40px' : '0' }}>
                    <p className={'boldText f16'}>Search results</p>
                </div>
            )
        }
    }

    const renderPredefined = () => {
        if (results?.length === 0 && results?.products?.length === 0) {
            return predefinedSearchWords.map((word) => (
                <button key={word} onClick={() => handleSearchButtonClick(word)} className={'predefinedWordBtn'}>
                    <img src={SearchIcon} alt='icon' style={{ marginRight: '6px' }} />
                    <p className={'f16 semiBoldText'} style={{ color: '#919294' }}>{word}</p>
                </button>
            ))
        }
    }

    const clearSearch = () => {
        setQuery('')
        // setResults([])
    }



    const returnClass = () => {
        if (results?.length !== 0 && results?.products?.length !== 0) {
            return 'brdBottomCurved'
        }
        return ''
    }


    return (
        <section>
            <div className={returnClass()} id='searchHeaderContainer'>
                <div className='searchInputContainer'>
                    <input
                        type="text"
                        placeholder={'Search for customers, vendors & riders '}
                        value={query}
                        onChange={handleInputChange}
                    />
                    {query !== '' &&
                        <AiOutlineClose size={26} onClick={clearSearch} />}
                </div>
                <div className={'searchFilterContainer'}>

                    {/* <p className={'f18 boldText'}>Filter by</p> */}

                </div>
            </div>

            <section className={'searchContentContainer'}>
                <div className={'predefinedWordContainer'}>
                    {renderPredefined()}
                </div>
                <div>
                    {renderHeader()}
                </div>
                <div className={'searchGrid'}>
                    {renderResult()}
                </div>
                {/* <div>{renderPagination()}</div> */}
                <div>
                    {renderEmptyState()}
                </div>
            </section>
        </section>
    )
}

export default UsersSearch