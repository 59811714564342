import React, { useEffect, useState } from 'react'

import CheckBox from '../../utils/CheckBox'
import CustomButton from '../../utils/CustomButton'
import CustomInput from '../../utils/CustomInput'
import '../../assets/styles/authStyles.css';
import AuthContainer from '../../components/auth/AuthContainer';
import AuthHeader from '../../components/auth/AuthHeader';
import { connect, useDispatch, useSelector } from 'react-redux';
import { getOtp, verifyOtp } from '../../redux/actions/AuthActions'
import { showError, showSuccess } from '../../utils/Toast';
import { useNavigate } from 'react-router-dom';
import { AiOutlineEyeInvisible, AiOutlineEye } from 'react-icons/ai'
import { LOGIN_SUCCESS } from '../../redux/Types';

const SignIn = (props) => {

    const dispatch = useDispatch()
    const [state, setState] = useState({ email: '', password: '', rememberMe: false, showPassword: false })
    const { gettingOtp } = useSelector(state => state.auth)
    const navigate = useNavigate()

    useEffect(() => {
        const accessToken = localStorage.getItem('accessToken');
        if (accessToken) {
            dispatch({ type: LOGIN_SUCCESS })
        }
    }, [])

    const onChangePassword = (e) => {
        setState({ ...state, password: e.target.value })
    }

    const onChangeEmail = (e) => {
        setState({ ...state, email: e.target.value })
    }

    const toForgotPassword = () => {
        navigate('/forgotPassword')
    }

    const toggleRemember = () => {
        if (state.rememberMe) {
            setState((prevState) => ({ ...prevState, rememberMe: false }))
        } else {
            setState((prevState) => ({ ...prevState, rememberMe: true }))
        }
    }


    const togglePassword = () => {
        if (state.showPassword) {
            setState((prevState) => ({ ...prevState, showPassword: false }))
        } else {
            setState((prevState) => ({ ...prevState, showPassword: true }))
        }
    }


    const requestOTP = async () => {

        const email = state.email.trim()
        const password = state.password.trim()
        const obj = { email, password }

        try {
            const res = await props.getOtp(obj)
            showSuccess(res?.message)
            if (res) {
                navigate('/phoneVerify', {
                    state: {
                        email: email,
                        password: password,
                        token: res?.data?.authToken,
                        rememberMe: state.rememberMe
                    }
                })
            }
        } catch (error) {
            showError(error[1].message)
        }
    }


    const disabled = () => {
        if (state.email === '' || state.password === '') {
            return true
        }
        return false
    }



    return (
        <>
            <AuthContainer logo={true}>
                <AuthHeader title={'Let’s sign you in'} subTitle={'Enter your details to gain access'} />
                <CustomInput label={'Email address'} onChange={onChangeEmail} value={state.email} placeholder={'Enter email address'} />
                <CustomInput label={'Password'} onChange={onChangePassword} value={state.password} placeholder={'Enter password '}
                    type={state.showPassword ? 'text' : 'password'} icon={state.showPassword ? <AiOutlineEye color='#B1B2B4' onClick={togglePassword} /> : <AiOutlineEyeInvisible color='#B1B2B4' onClick={togglePassword} />}
                />
                <div className={'flexRow alignCenter justifyBetween authRememberContainer'}>
                    <CheckBox title={'Remember me'} onClick={toggleRemember} saveForLater={state.rememberMe} />
                    <p className={'semiBoldText forgotPasswordLink f16'} style={{ cursor: "pointer" }} onClick={toForgotPassword}>Forgot Password</p>
                </div>
                <CustomButton title={'Sign In'} onClick={requestOTP} loading={gettingOtp} bgColor={'#FFA024'} disabled={disabled()} />
            </AuthContainer>
        </>
    )
}

export default connect(null, { getOtp, verifyOtp })(SignIn)