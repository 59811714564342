import React, { useState } from 'react'
import Skeleton from 'react-loading-skeleton';
import { connect, useSelector, useDispatch } from 'react-redux';
import { ArrowDown } from '../assets/images/icons';
import { getAgencies } from '../redux/actions/OtherActions';
import './utils.css';
const CustomAgenciesDropDown = (props) => {

    const { agencies, gettingAgencies } = useSelector(state => state.others)
    console.log(agencies, 'fj')
    const { agency, selectAgency, setOpen, open } = props
  const dispatch = useDispatch()
    // console.log(rider, 'rider')
    const fetchAgencies = async () => {
        
            try {
                const res = await dispatch(getAgencies())
                // console.log(res)

            } catch (error) {
                console.log(error)
            }
        
    }

    const toggler = () => {
        fetchAgencies()
        setOpen(previousState => !previousState);

    }


    const renderAgencies = () => {

        if (gettingAgencies) {
            return (
                <>
                    <Skeleton width={'100%'} height={'40px'} style={{ margin: "10px auto" }} />
                    <Skeleton width={'100%'} height={'40px'} />
                    <Skeleton width={'100%'} height={'40px'} style={{ margin: "10px auto" }} />
                    <Skeleton width={'100%'} height={'40px'} />
                </>

            )
        }


        if (agencies && agencies?.data?.data?.length !== 0) {
            return agencies?.data?.data?.map((item, index) => {
                // console.log(item, 'us')
                return (
                    <li className={'customRiderSelectList regularText f16 flexRow alignCenter justifyBetween'}
                        onClick={() => selectAgency(item)} key={item?.id}
                        style={{ color: '#000000', cursor: 'pointer' }}
                    >
                        <div className={'flexRow alignCenter'}>
                            <div className={'userOrderAvatar'}>
                                <img src={`${item?.image}`} style={{ width: '100%', height: '100%', borderRadius: '100px' }} />
                            </div>
                            <div>
                                <p className={'f12 boldText'}>{item?.name|| 'null null'}</p>
                            </div>
                        </div>
                    </li>
                )
            })
        }
    }

    return (
        <>
            <div className={'flexRow alignCenter justifyBetween customSelectContainer'} onClick={toggler}>
                <div>
                    <span className={'f14 regularText'} style={{ color: '#999CA0', marginBottom: '0' }}>{agency ? agency?.name : 'Select agency'}</span>
                </div>
                <div>
                    <img src={ArrowDown} alt={'icon'} />
                </div>
                {open && agencies &&
                    <ul className={'customSelectListContainer'} >
                        {renderAgencies()}
                    </ul>
                }
            </div>
        </>
    )
}

export default CustomAgenciesDropDown;
