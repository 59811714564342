
import * as React from 'react';

const getOnLineStatus = () =>
    typeof navigator !== 'undefined' && typeof navigator.onLine === 'boolean'
        ? navigator.onLine
        : true;

const getConnectionQuality = () =>
    typeof navigator !== 'undefined' && 'connection' in navigator
        ? navigator.connection.effectiveType // This property gives an indication of the network quality
        : 'unknown';

const useNavigatorOnLine = () => {
    const [status, setStatus] = React.useState(getOnLineStatus());
    const [connectionQuality, setConnectionQuality] = React.useState(getConnectionQuality());

    const setOnline = () => {
        setStatus(true);
        setConnectionQuality(getConnectionQuality());
    };

    const setOffline = () => {
        setStatus(false);
        setConnectionQuality('unknown');
    };

    React.useEffect(() => {
        window.addEventListener('online', setOnline);
        window.addEventListener('offline', setOffline);

        return () => {
            window.removeEventListener('online', setOnline);
            window.removeEventListener('offline', setOffline);
        };
    }, []);

    return { status, connectionQuality };
};

export default useNavigatorOnLine;
