import { setToken } from "../../utils/Helpers";
import http from "../../utils/utils";
import { GETTING_OTP, GETTING_OTP_FAIL, GETTING_OTP_SUCCESSFUL, LOADING_USER, LOGIN_FAIL, LOGIN_SUCCESS, RESET_PASSWORD, RESET_PASSWORD_FAIL, RESET_PASSWORD_SUCCESSFUL, VERIFY_OTP, VERIFY_OTP_FAIL, VERIFY_OTP_SUCCESSFUL } from "../Types";



export const getOtp = (obj) => {
    return dispatch => {
        dispatch({ type: GETTING_OTP })
        return new Promise(async (resolve, reject) => {
            try {
                const res = await http.post("admin/auth/login", obj)
                const data = res
                // console.log('get OTP ', res)
                if (res.statusCode === 400) {
                    console.log(res.message, 'djd')
                }
                dispatch({ type: GETTING_OTP_SUCCESSFUL });
                resolve(data);
            } catch (error) {
                // console.log('dd', error)
                dispatch({ type: GETTING_OTP_FAIL, payload: error });
                reject(error);
            }
        });
    };
};


// auth / customer / profile

export const verifyOtp = (obj) => {
    return dispatch => {
        dispatch({ type: LOADING_USER })
        return new Promise(async (resolve, reject) => {
            try {
                const res = await http.post("admin/auth/verify_login", obj)
                const data = res.data
                setToken(data)
                // console.log('verify OTP', res)
                dispatch({ type: LOGIN_SUCCESS, payload: data });
                resolve(data);
            } catch (error) {
                // console.log(error)
                dispatch({ type: LOGIN_FAIL, payload: error });
                reject(error);
            }
        });
    };

};

export const getResetOtp = (obj) => {
    return dispatch => {
        dispatch({ type: GETTING_OTP })
        return new Promise(async (resolve, reject) => {
            try {
                const res = await http.post("admin/auth/reset_password/send_confirmation_code", obj)
                const data = res
                // console.log('get reset OTP ', res)
                dispatch({ type: GETTING_OTP_SUCCESSFUL });
                resolve(data);
            } catch (error) {
                // console.log('get reset OTP', error)
                dispatch({ type: GETTING_OTP_FAIL, payload: error });
                reject(error);
            }
        });
    };
};


export const verifyResetOtp = (obj) => {
    return dispatch => {
        dispatch({ type: VERIFY_OTP })
        return new Promise(async (resolve, reject) => {
            try {
                const res = await http.post("admin/auth/reset_password/verify_confirmation_code", obj)
                const data = res
                // console.log('verify reset OTP ', res)
                dispatch({ type: VERIFY_OTP_SUCCESSFUL });
                resolve(data);
            } catch (error) {
                // console.log('verify reset OTP', error)
                dispatch({ type: VERIFY_OTP_FAIL, payload: error });
                reject(error);
            }
        });
    };
};



export const resetPassword = (obj) => {
    return dispatch => {
        dispatch({ type: RESET_PASSWORD })
        return new Promise(async (resolve, reject) => {
            try {
                const res = await http.post("admin/auth/reset_password/enter_new_password", obj)
                const data = res
                // console.log('verify reset OTP ', res)
                dispatch({ type: RESET_PASSWORD_SUCCESSFUL });
                resolve(data);
            } catch (error) {
                // console.log('verify reset OTP', error)
                dispatch({ type: RESET_PASSWORD_FAIL, payload: error });
                reject(error);
            }
        });
    };
};
