import React from 'react';
import { Logo } from '../../assets/images/Index';
import '../../assets/styles/authStyles.css';
import BackComponent from '../../utils/BackComponent';
import AuthFooter from './AuthFooter';

const AuthContainer = (props) => {
    return (
        <main className={'authContainer'}>
            <section className={'authLeft'}>
                <div>
                    {props.logo && <div className={'authLogo'}><img src={Logo} alt='logo' /></div>}
                    {props.back && <div className={'authBackBtn'}><BackComponent /></div>}
                </div>
                <div>
                    {props.children}
                </div>
                <div>
                    <AuthFooter />
                </div>
            </section>
            <section className={'authRight'} />
        </main>
    )
}

export default AuthContainer