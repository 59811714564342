export const RE_DIGIT = new RegExp(/^\d+$/);

export const setToken = async (data) => {
    try {
        const accessToken = data.accessToken;
        localStorage.setItem("accessToken", accessToken);
    } catch (error) {
        console.log("Could not set accessToken ", error.message);
    }
}


export const shortenXterLength = (string, number = 10) => {
    if (string) {
        if (string.length <= number) {
            return string
        }
        return `${string.slice(0, number)}..`
    }
    return ''
}


export const setLocation = async (data) => {
    try {
        localStorage.setItem("location", data);
    } catch (error) {
        console.log("Could not set location ", error.message);
    }
}


export const formatAmount = (amount, maxDigit = 2) => {
    if (Number(amount) === 0 || amount) {
        return Number(amount).toLocaleString(undefined, {
            minimumFractionDigits: 2,
            maximumFractionDigits: maxDigit,
        });
    }

    return '***';
}


export const setSession = (token) => {
    localStorage.setItem('accessToken', token);
    // localStorage.setItem('username', username);
};



export const usersFilter = [
    'All', 'Customers', 'Vendors', 'Riders', 'Waitlist',
]

export const settingsTabs = [
    'Personal information'
]


// statusConfig.js

const STATUS_CONFIG = {
    ORDER_CREATED: { text: 'PENDING', bgColor: '#EBF2FA', textColor: '#A6B7D4' },
    ORDER_CONFIRMED: { text: 'CONFIRMED', bgColor: '#DEFFEE', textColor: '#66CB9F' },
    ORDER_AVAILABLE: { text: 'ORDER AVAILABLE', bgColor: '#DEFFEE', textColor: '#66CB9F' },
    ORDER_READY: { text: 'ORDER READY', bgColor: '#DEFFEE', textColor: '#66CB9F' },
    ORDER_AWAITING_ASSIGNMENT: { text: 'AWAITING ASSIGNMENT', bgColor: '#DEFFEE', textColor: '#66CB9F' },
    ORDER_ASSIGNED: { text: 'ASSIGNED', bgColor: '#E1E8FF', textColor: '#4C6FFF' },
    ORDER_ACCEPTED: { text: 'ACCEPTED', bgColor: '#DEFFEE', textColor: '#66CB9F' },
    ORDER_REJECTED: { text: 'REJECTED', bgColor: '#FFE6E4', textColor: '#F16063' },
    ORDER_PICKED_UP: { text: 'TRANSIT', bgColor: '#FFF8E1', textColor: '#FFD54F' },
    ORDER_DELIVERED: { text: 'DELIVERED', bgColor: '#DEFFEE', textColor: '#66CB9F' },
    ORDER_CANCELLED: { text: 'CANCELLED', bgColor: '#FFE6E4', textColor: '#F16063' },
    DEFAULT: { text: 'CANCELLED', bgColor: '#FFE6E4', textColor: '#F16063' },
};

export const getStatusConfig = (status) => STATUS_CONFIG[status] || STATUS_CONFIG.DEFAULT;
