import { FETCH_USERS, FETCH_USERS_FAIL, FETCH_USERS_SUCCESSFUL, UPDATE_META, USER_ORDER_HISTORY, USER_ORDER_HISTORY_FAIL, USER_ORDER_HISTORY_SUCCESSFUL, USER_PROFILE, USER_PROFILE_FAIL, USER_PROFILE_SUCCESSFUL } from "../Types";

const INITIAL_STATE = {
    error: [],
    users: [],
    gettingUsers: false,
    userProfile: null,
    gettingUserProfile: false,
    userType: null,
    gettingOrderHistory: false,
    orderHistory: [],
};

export default (state = INITIAL_STATE, { type, payload }) => {
    switch (type) {
        case FETCH_USERS:
            return { ...state, gettingUsers: true };
        case FETCH_USERS_SUCCESSFUL:
            return { ...state, gettingUsers: false, users: payload }
        case FETCH_USERS_FAIL:
            return { ...state, gettingUsers: false, error: payload }
        case USER_PROFILE:
            return { ...state, gettingUserProfile: true };
        case USER_PROFILE_SUCCESSFUL:
            return { ...state, gettingUserProfile: false, userProfile: payload }
        case USER_PROFILE_FAIL:
            return { ...state, gettingUserProfile: false, error: payload }
        case USER_ORDER_HISTORY:
            return { ...state, gettingOrderHistory: true };
        case USER_ORDER_HISTORY_SUCCESSFUL:
            return { ...state, gettingOrderHistory: false, orderHistory: payload }
        case USER_ORDER_HISTORY_FAIL:
            return { ...state, gettingOrderHistory: false, error: payload }
        case UPDATE_META:
            return { ...state, userType: payload };

        default:
            return state;
    }
};