import React, { useState } from 'react'
import AuthContainer from '../../components/auth/AuthContainer'
import AuthHeader from '../../components/auth/AuthHeader'
import CustomButton from '../../utils/CustomButton'
import CustomInput from '../../utils/CustomInput'
import '../../assets/styles/authStyles.css';
import { getResetOtp } from '../../redux/actions/AuthActions'
import { connect, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { showError, showSuccess } from '../../utils/Toast'

const ForgotPassword = (props) => {

    const [state, setState] = useState({
        email: '',
    })
    const { email } = state

    const navigate = useNavigate()
    const { gettingOtp } = useSelector(state => state.auth)

    const onChangeEmail = (e) => {
        setState({ ...state, email: e.target.value })
    }

    const requestOTP = async () => {
        const email = state.email.trim()
        const obj = { email }
        try {
            const res = await props.getResetOtp(obj)
            // console.log('reset otp', res)
            if (res?.data?.role === 'SUPERADMIN' || res?.data?.role === 'STAFF') {
                navigate('/contactAdmin', {
                    state: {
                        token: res?.data?.authToken,
                        message: res?.message,
                        role: res?.data?.role
                    }
                })
            }
            if (res?.data?.role === 'ADMIN') {
                showSuccess(res?.message)
                navigate('/forgotPasswordOtp', {
                    state: {
                        token: res?.data?.authToken,
                        role: res?.data?.role,
                        email: email,
                    }
                })
            }
        } catch (error) {
            // console.log('reset otp', error)
            showError(error[1].message)
        }
    }


    const disabled = () => {
        if (state.email === '') {
            return true
        }
        return false
    }


    return (
        <>
            <AuthContainer back={true}>
                <AuthHeader title={'Forgot password'} subTitle={'Let us help you retrieve your account'} />
                <CustomInput label={'Email address'} onChange={onChangeEmail} value={state.email} placeholder={'Enter email address'} />
                <div style={{ marginTop: '40px' }}>
                    <CustomButton title={'Submit'} onClick={requestOTP} loading={gettingOtp} bgColor={'#FFA024'} disabled={disabled()} />
                </div>
            </AuthContainer>
        </>
    )
}

export default connect(null, { getResetOtp })(ForgotPassword);