import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import BackComponent from "../../../utils/BackComponent";
import MultiButton from "../../../utils/MultiButton";
import Navigation from "../../../widgets/Navigation";
import "../../../assets/styles/orderManagementStyles.css";
import CardsWithHeader from "../../../components/all/CardsWithHeader";
import {
  AboutUserIcon,
  BillingIcon,
  MealSetupIcon,
  Message,
  RiderIcon,
} from "../../../assets/images/icons";
import { formatAmount, getStatusConfig } from "../../../utils/Helpers";
import ConfirmPaymentModal from "../../../components/dashboard/orderManagement/ConfirmPaymentModal";
import AssignRiderModal from "../../../components/dashboard/orderManagement/AssignRiderModal";
import AssignAgencyModal from "../../../components/dashboard/orderManagement/AssignAgencyModal";
import CancelOrderModal from "../../../components/dashboard/orderManagement/CancelOrderModal";
import { useDispatch, useSelector } from "react-redux";
import { getOrdersById } from "../../../redux/actions/OrdersAction";
import Skeleton from "react-loading-skeleton";
import { useMediaQuery } from "react-responsive";
import UpdateOrderStatusModal from "../../../components/dashboard/orderManagement/UpdateOrderStatus";
import { More } from "iconsax-react";
import SliderModal from "./OrderDetailsSideBar";
import http from "../../../utils/utils";
import { showError, showSuccess } from "../../../utils/Toast";
import MapModal from "../../../components/dashboard/orderManagement/MapModal";
import { getClosestRiders } from "../../../redux/actions/OtherActions";
import RiderModal from "../../../components/dashboard/orderManagement/RiderModal";

function getStatusFromTimeDifference(timeDifference) {
  const roundedTime = timeDifference.toFixed(2); // Round to 2 decimal places

  if (timeDifference <= 30) {
    return {
      status: `Normal ${roundedTime} mins`,
      color: "rgba(102, 203, 159, 1)",
      bgColor: "rgba(222, 255, 238, 1)",
      time: roundedTime,
    };
  } else if (timeDifference > 30 && timeDifference <= 40) {
    return {
      status: `Potential Delay ${roundedTime} mins`,
      color: "rgba(255, 213, 79, 1)",
      bgColor: "rgba(255, 248, 225, 1)",
      time: roundedTime,
    };
  } else if (timeDifference > 40 && timeDifference <= 60) {
    return {
      status: `Delay ${roundedTime} mins`,
      color: "rgba(255, 213, 79, 1)",
      bgColor: "rgba(255, 248, 225, 1)",
      time: roundedTime,
    };
  } else if (timeDifference > 60) {
    return {
      status: `Critical ${roundedTime} mins`,
      color: "rgba(241, 96, 99, 1)",
      bgColor: "rgba(255, 230, 228, 1)",
      time: roundedTime,
    };
  } else {
    return {
      status: `Unknown classification ${roundedTime} mins`,
      color: "rgba(241, 96, 99, 1)",
      bgColor: "rgba(255, 230, 228, 1)",
      time: roundedTime,
    };
  }
}

const ClassifyOrder = (whenCustomerPaid, whenOrderDelivered, orderStatus) => {
  const currentTime = new Date().getTime();
  const paymentTime = new Date(whenCustomerPaid).getTime();
  const deliveredTime = new Date(whenOrderDelivered).getTime();
  const timeDifference = (currentTime - paymentTime) / 60000;
  const deliveredTimeDifference = (deliveredTime - paymentTime) / 60000;

  if (!whenCustomerPaid) {
    return {
      status: `Unpaid`,
      color: "rgba(166, 183, 212, 1)",
      bgColor: "rgba(235, 242, 250, 1)",
    };
  }
  if (whenOrderDelivered) {
    return {
      status: `Delivered ${deliveredTimeDifference.toFixed(2)} mins`,
      color: "#000",
      bgColor: "rgba(240, 241, 242, 1)",
    };
  }
  if (orderStatus === "ORDER_CANCELLED") {
    return {
      status: `Cancelled`,
      color: "#000",
      bgColor: "rgba(240, 241, 242, 1)",
    };
  }
  return getStatusFromTimeDifference(timeDifference);
};

const OrderDetails = (props) => {
  const isDesktopOrLaptop = useMediaQuery({ query: "(min-width: 1430px)" });
  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 1430px)" });
  const params = useLocation();

  const [state, setState] = useState({
    confirmModal: false,
    assignModal: false,
    cancelModal: false,
    agencyModal: false,
    updateOrderStatusModal: false,
    loading: false,
    mapModal: false,
    riderModal: false,
  });
  const { singleOrder, gettingSingleOrder } = useSelector(
    (state) => state.orders
  );
  console.log(singleOrder, "order");

  const recievingCustomerName = singleOrder?.data?.order?.recievingCustomerName;
  const recievingCustomerPhone =
    singleOrder?.data?.order?.recievingCustomerPhone;
  const destinationAddress =
    singleOrder?.data?.order?.shipping?.destinationAddress;
  const whenCustomerPaid =
    singleOrder?.data?.order?.whenCustomerPayedForOrder || null;
  const whenOrderDelivered =
    singleOrder?.data?.order?.whenOrderGotDelivered || null;
  const orderStatus = singleOrder?.data?.order?.orderStatus || null;
  const orderClassification = ClassifyOrder(
    whenCustomerPaid,
    whenOrderDelivered,
    orderStatus
  );
  const paymentMethod =
    singleOrder?.data?.order?.transactionDetails[
      singleOrder?.data?.order?.transactionDetails.length - 1
    ];
  const statusConfig = getStatusConfig(singleOrder?.data?.order?.orderStatus);

  const hideScroll = () => {
    document.body.style.overflow = "hidden";
  };
  const unhideScroll = () => {
    document.body.style.overflow = "unset";
  };

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getOrdersById(params?.state?.id));
  }, []);

  const [isModalOpen, setIsModalOpen] = useState(false);

  const toggleModal = () => {
    setIsModalOpen((prev) => !prev);
  };

  const openConfirmModal = () => {
    setState((prevState) => ({ ...prevState, confirmModal: true }));
    hideScroll();
  };
  const closeConfirmModal = () => {
    setState((prevState) => ({ ...prevState, confirmModal: false }));
    unhideScroll();
  };

  const fetchAvailabelRiders = async () => {
    dispatch(getClosestRiders(200));
  };

  const openMapModal = () => {
    fetchAvailabelRiders();
    setState((prevState) => ({ ...prevState, mapModal: true }));
  };

  const closeMapModal = () => {
    setState((prevState) => ({ ...prevState, mapModal: false }));
  };

  const openAssignModal = () => {
    setState((prevState) => ({ ...prevState, assignModal: true }));
    toggleModal();
    hideScroll();
  };
  const closeAssignModal = () => {
    setState((prevState) => ({ ...prevState, assignModal: false }));
    unhideScroll();
  };

  const openAssignToAgencyModal = () => {
    setState((prevState) => ({ ...prevState, agencyModal: true }));
    toggleModal();
    hideScroll();
  };
  const closeAssignToAgencyModal = () => {
    setState((prevState) => ({ ...prevState, agencyModal: false }));
    unhideScroll();
  };

  const openCancelModal = () => {
    setState((prevState) => ({ ...prevState, cancelModal: true }));
    toggleModal();
    hideScroll();
  };
  const closeCancelModal = () => {
    setState((prevState) => ({ ...prevState, cancelModal: false }));
    unhideScroll();
  };

  const openUpdateModal = () => {
    setState((prevState) => ({ ...prevState, updateOrderStatusModal: true }));
    toggleModal();
    hideScroll();
  };
  const closeUpdateModal = () => {
    setState((prevState) => ({ ...prevState, updateOrderStatusModal: false }));
    unhideScroll();
  };

  const updateStatus = async (val) => {
    const obj = { delivery_status: val };
    setState((prevState) => ({ ...prevState, loading: true }));
    const orderId = params?.state?.id;
    try {
      const res = await http.put(`admin/order/${orderId}`, obj);
      showSuccess(res?.message);
      toggleModal();
      dispatch(getOrdersById(params?.state?.id));
      setState((prevState) => ({ ...prevState, loading: false }));
    } catch (error) {
      console.log(error);

      // Check if the error has a response with a message and display it using showError
      const errorMessage = error[1]?.message || "An unexpected error occurred";
      showError(errorMessage);

      setState((prevState) => ({ ...prevState, loading: false }));
    }
  };

  const renderButtons = () => {
    if (
      singleOrder?.data?.order?.paymentStatus === "PROCESSING" &&
      singleOrder?.data?.order?.orderType !== "ONLINE_ORDER"
    ) {
      return (
        <MultiButton
          title={"Confirm payment"}
          icon={false}
          bgColor={"#66BB6A"}
          onClick={openConfirmModal}
          height={"40px"}
        />
      );
    }
    if (
      singleOrder?.data?.order?.orderStatus === "ORDER_AWAITING_ASSIGNMENT" ||
      singleOrder?.data?.order?.orderStatus === "ORDER_AVAILABLE"
    ) {
      return (
        <div className={"flexColumn"} style={{ gap: "16px", margin: "16px 0" }}>
          <MultiButton
            title={"Assign to rider"}
            icon={false}
            bgColor={"#66BB6A"}
            onClick={openAssignModal}
            height={"40px"}
          />
          <MultiButton
            title={"Assign to agency"}
            icon={false}
            bgColor={"#66BB6A"}
            onClick={openAssignToAgencyModal}
            height={"40px"}
          />
        </div>
      );
    }

    if (
      singleOrder?.data?.order?.orderStatus === "ORDER_ASSIGNED" ||
      singleOrder?.data?.order?.orderStatus === "ORDER_REJECTED" ||
      singleOrder?.data?.order?.orderStatus === "ORDER_ACCEPTED" ||
      singleOrder?.data?.order?.orderStatus === "ORDER_PICKED_UP"
    ) {
      return (
        <div
          className={"flexColumn "}
          style={{ gap: "16px", margin: "16px 0" }}
        >
          <MultiButton
            title={"Reassign to rider"}
            icon={false}
            bgColor={"#66BB6A"}
            onClick={openAssignModal}
            height={"40px"}
          />{" "}
          
          <MultiButton
            title={"Reassign to agency"}
            icon={false}
            bgColor={"#66BB6A"}
            onClick={openAssignToAgencyModal}
            height={"40px"}
          />
        </div>
      );
    }
    if (singleOrder?.data?.order?.orderStatus === "ORDER_ASSIGNED") {
      return (
        <MultiButton
          title={"Update order status"}
          icon={false}
          bgColor={"#F96868"}
          onClick={openCancelModal}
          height={"40px"}
        />
      );
    }
  };

  return (
    <>
      {isDesktopOrLaptop && (
        <>
          <Navigation />
          <main className={"orderManagementContainer"}>
            <section className={"flexRow alignCenter justifyBetween miniNav"}>
              <div className={"flexRow alignCenter"}>
                <BackComponent />
                {gettingSingleOrder ? (
                  <>
                    <Skeleton width={"200px"} />
                  </>
                ) : (
                  <>
                    <h3
                      className={"boldText f24"}
                      style={{ margin: "0 8px 0 24px" }}
                    >
                      {singleOrder?.data?.order?.id}
                    </h3>
                    <p
                      className={
                        "orderStatusContainer textCenter f10 regularText"
                      }
                      style={{
                        backgroundColor: statusConfig.bgColor,
                        color: statusConfig.textColor,
                        width: "auto",
                      }}
                    >
                      {statusConfig.text}
                    </p>
                  </>
                )}
              </div>
              <div>
                {singleOrder?.data?.order?.orderStatus !== "ORDER_DELIVERED" &&
                  singleOrder?.data?.order?.orderStatus !==
                    "ORDER_CANCELLED" && (
                    <MultiButton
                      title="Actions"
                      bgColor="inherit"
                      onClick={toggleModal}
                      height="40px"
                      width="128px"
                      color="#000"
                      brd="1px solid #000"
                      iconArt={
                        <div style={{ transform: "rotate(90deg)" }}>
                          <More size="20" color="#000" variant="bold" />
                        </div>
                      }
                    />
                  )}

                <SliderModal isOpen={isModalOpen} toggleModal={toggleModal}>
                  <div>
                    <div
                      className="flexColumn"
                      style={{ gap: "10px", margin: "16px 0" }}
                    >
                      {(singleOrder?.data?.order?.orderStatus ===
                        "ORDER_ASSIGNED" ||
                        singleOrder?.data?.order?.orderStatus ===
                          "ORDER_ACCEPTED" ||
                        singleOrder?.data?.order?.orderStatus ===
                          "ORDER_PICKED_UP") && (
                        <MultiButton
                          title="Update order status"
                          bgColor="#66BB6A"
                          onClick={openUpdateModal}
                          height="40px"
                          // width="160px"
                        />
                      )}
                    </div>
                    <div style={{ margin: "16px 0 " }}>
                      {singleOrder?.data?.order?.orderStatus ===
                        "ORDER_CONFIRMED" && (
                        <MultiButton
                          title="Order available"
                          bgColor="#66BB6A"
                          onClick={() => updateStatus("ORDER_AVAILABLE")}
                          height="40px"
                          // width="160px"
                        />
                      )}
                    </div>
                    <div style={{ margin: "16px 0" }}>
                      {(singleOrder?.data?.order?.orderStatus ===
                        "ORDER_AVAILABLE" ||
                        singleOrder?.data?.order?.orderStatus ===
                          "ORDER_ASSIGNED") && (
                        <MultiButton
                          title="Order ready"
                          bgColor="#66BB6A"
                          onClick={() =>
                            updateStatus("ORDER_AWAITING_ASSIGNMENT")
                          }
                          height="40px"
                        />
                      )}
                    </div>
                    <div style={{ margin: "16px 0" }}>
                      {renderButtons()}
                      <MultiButton
                        title={"Find closest rider"}
                        icon={false}
                        bgColor={"#66BB6A"}
                        onClick={openMapModal}
                        height={"40px"}
                      />
                    </div>
                    {singleOrder?.data?.order?.orderStatus !==
                      "ORDER_DELIVERED" &&
                      singleOrder?.data?.order?.orderStatus !==
                        "ORDER_CANCELLED" && (
                        <MultiButton
                          title="Cancel order"
                          bgColor="#F96868"
                          onClick={openCancelModal}
                          height="40px"
                          // width="128px"
                        />
                      )}
                  </div>
                </SliderModal>

                {/**/}
              </div>
            </section>
            <section className={"orderDetailsGrid"}>
              {gettingSingleOrder ? (
                <>
                  <Skeleton height={"300px"} style={{ borderRadius: "8px" }} />
                  <Skeleton height={"300px"} style={{ borderRadius: "8px" }} />
                  <Skeleton height={"300px"} style={{ borderRadius: "8px" }} />
                  <Skeleton height={"300px"} style={{ borderRadius: "8px" }} />
                </>
              ) : (
                <>
                  <CardsWithHeader
                    title={"About customer"}
                    icon={AboutUserIcon}
                  >
                    <div
                      className={"flexRow alignCenter justifyBetween"}
                      style={{ marginBottom: "16px" }}
                    >
                      <p
                        className={"f16 boldText"}
                        style={{ color: " #38383A" }}
                      >
                        Fullname
                      </p>
                      <p
                        className={"semiBoldText f16"}
                        style={{ color: "#919294" }}
                      >
                        {singleOrder?.data?.order?.user.firstName +
                          " " +
                          singleOrder?.data?.order?.user.lastName || "No name"}
                      </p>
                    </div>
                    <div
                      className={"flexRow alignCenter justifyBetween"}
                      style={{ marginBottom: "16px" }}
                    >
                      <p
                        className={"f16 boldText"}
                        style={{ color: " #38383A" }}
                      >
                        Mobile number
                      </p>
                      <p
                        className={"semiBoldText f16"}
                        style={{ color: "#919294" }}
                      >
                        {singleOrder?.data?.order?.user.phoneNumber ||
                          "No number"}
                      </p>
                    </div>
                    {/* <div className={'flexRow alignCenter justifyBetween'} style={{ marginBottom: '16px' }}>
                                        <p className={'f16 boldText'} style={{ color: ' #38383A' }}>State of residence</p>
                                        <p className={'semiBoldText f16'} style={{ color: '#919294' }}>{stateOfResidence}</p>
                                    </div> */}
                    <div
                      className={"flexRow alignCenter justifyBetween"}
                      style={{ marginBottom: "16px" }}
                    >
                      <p
                        className={"f16 boldText"}
                        style={{ color: " #38383A" }}
                      >
                        Delivery address
                      </p>
                      <p
                        className={"semiBoldText f16"}
                        style={{ color: "#919294" }}
                      >
                        {destinationAddress}
                      </p>
                    </div>
                    {recievingCustomerName && (
                      <div
                        className={"flexRow alignCenter justifyBetween"}
                        style={{ marginBottom: "16px" }}
                      >
                        <p
                          className={"f16 boldText"}
                          style={{ color: " #38383A" }}
                        >
                          Receivers name
                        </p>
                        <p
                          className={"semiBoldText f16"}
                          style={{ color: "#919294" }}
                        >
                          {singleOrder?.data?.order?.recievingCustomerName}
                        </p>
                      </div>
                    )}
                    {recievingCustomerPhone && (
                      <div
                        className={"flexRow alignCenter justifyBetween"}
                        style={{ marginBottom: "16px" }}
                      >
                        <p
                          className={"f16 boldText"}
                          style={{ color: " #38383A" }}
                        >
                          Receivers mobile number
                        </p>
                        <p
                          className={"semiBoldText f16"}
                          style={{ color: "#919294" }}
                        >
                          {singleOrder?.data?.order?.recievingCustomerPhone}
                        </p>
                      </div>
                    )}
                    {(recievingCustomerName || recievingCustomerPhone) && (
                      <div
                        className={"flexRow alignCenter justifyBetween"}
                        style={{ marginBottom: "16px" }}
                      >
                        <p
                          className={"f16 boldText"}
                          style={{ color: " #38383A" }}
                        >
                          Receivers address
                        </p>
                        <p
                          className={"semiBoldText f16"}
                          style={{ color: "#919294" }}
                        >
                          {destinationAddress}
                        </p>
                      </div>
                    )}
                    <div
                      className={"flexRow alignCenter justifyBetween"}
                      style={{ marginBottom: "16px" }}
                    >
                      <p
                        className={"f16 boldText"}
                        style={{ color: " #38383A" }}
                      >
                        Device
                      </p>
                      <p
                        className={"semiBoldText f16"}
                        style={{ color: "#919294" }}
                      >
                        {singleOrder?.data?.order?.deviceUsed}
                      </p>
                    </div>
                  </CardsWithHeader>
                  {singleOrder?.data?.order?.rider && (
                    <CardsWithHeader title={"About Rider"} icon={RiderIcon}>
                      <div
                        className={"flexRow alignCenter justifyBetween"}
                        style={{ marginBottom: "16px" }}
                      >
                        <p
                          className={"f16 boldText"}
                          style={{ color: " #38383A" }}
                        >
                          Fullname
                        </p>
                        <p
                          className={"semiBoldText f16"}
                          style={{ color: "#919294" }}
                        >
                          {singleOrder?.data?.order?.rider?.user?.firstName +
                            " " +
                            singleOrder?.data?.order?.rider?.user?.lastName ||
                            "No name"}
                        </p>
                      </div>
                      <div
                        className={"flexRow alignCenter justifyBetween"}
                        style={{ marginBottom: "16px" }}
                      >
                        <p
                          className={"f16 boldText"}
                          style={{ color: " #38383A" }}
                        >
                          Mobile number
                        </p>
                        <p
                          className={"semiBoldText f16"}
                          style={{ color: "#919294" }}
                        >
                          {singleOrder?.data?.order?.rider?.user?.phoneNumber ||
                            "No number"}
                        </p>
                      </div>
                      <div
                        className={"flexRow alignCenter justifyBetween"}
                        style={{ marginBottom: "16px" }}
                      >
                        <p
                          className={"f16 boldText"}
                          style={{ color: " #38383A" }}
                        >
                          State of residence
                        </p>
                        <p
                          className={"semiBoldText f16"}
                          style={{ color: "#919294" }}
                        >
                          {
                            singleOrder?.data?.order?.rider
                              ?.riderOperationLocation
                          }
                        </p>
                      </div>
                      {/* <div className={'flexRow alignCenter justifyBetween'} style={{ marginBottom: '16px' }}>
                                        <p className={'f16 boldText'} style={{ color: ' #38383A' }}>Delivery address</p>
                                        <p className={'semiBoldText f16'} style={{ color: '#919294' }}>{singleOrder?.data?.order?.shipping?.destinationAddress || 'No address'}</p>
                                    </div> */}
                    </CardsWithHeader>
                  )}
                  <CardsWithHeader title={"Meal setup"} icon={MealSetupIcon}>
                    {singleOrder?.data?.order?.orderItems?.map(
                      (item, index) => {
                        return (
                          <div
                            className={"flexRow alignCenter justifyBetween"}
                            style={{ marginBottom: "16px" }}
                            key={index}
                          >
                            <div>
                              <p className={"f16 boldText"}>{item?.name}</p>
                              <p
                                className={"f14 semiBoldText"}
                                style={{ color: "#919294" }}
                              >
                                ₦{formatAmount(item?.price)}
                              </p>
                            </div>
                            <div>
                              <p
                                className={"f16 boldText"}
                                style={{ color: "#919294" }}
                              >
                                x{item?.quantity}
                              </p>
                            </div>
                          </div>
                        );
                      }
                    )}
                    <div style={{ marginBottom: "16px", marginTop: "20px" }}>
                      <p
                        className={"f16 boldText"}
                        style={{ color: " #38383A" }}
                      >
                        Delivery status
                      </p>
                      <div
                        style={{
                          color: orderClassification.color,
                          backgroundColor: orderClassification.bgColor,
                          width: "auto",
                          textAlign: "center",
                          padding: "3px 6px",
                          borderRadius: "8px",
                          marginTop: "8px",
                          maxWidth: "140px",
                        }}
                      >
                        <p
                          className={"semiBoldText f14"}
                          style={{ color: orderClassification.color }}
                        >
                          {orderClassification.status}
                        </p>
                      </div>
                    </div>
                  </CardsWithHeader>
                  <CardsWithHeader
                    title={"Billing & Payment"}
                    icon={BillingIcon}
                  >
                    <div style={{ marginBottom: "16px" }}>
                      <p
                        className={"f16 boldText"}
                        style={{ color: " #38383A" }}
                      >
                        Payment method
                      </p>
                      <p
                        className={"semiBoldText f16"}
                        style={{ color: "#919294" }}
                      >
                        {paymentMethod?.paymentMode} -{" "}
                        {paymentMethod?.paymentProvider}
                      </p>
                    </div>
                    <div style={{ marginBottom: "16px" }}>
                      <p
                        className={"f16 boldText"}
                        style={{ color: " #38383A" }}
                      >
                        Payment status
                      </p>
                      <p
                        className={"semiBoldText f16"}
                        style={{ color: "#919294" }}
                      >
                        {singleOrder?.data?.order?.paymentStatus}
                      </p>
                    </div>
                    <div style={{ marginBottom: "16px" }}>
                      <p
                        className={"f16 boldText"}
                        style={{ color: " #38383A" }}
                      >
                        Meal cost
                      </p>
                      <p
                        className={"semiBoldText f16"}
                        style={{ color: "#919294" }}
                      >
                        ₦
                        {formatAmount(
                          singleOrder?.data?.order?.totalPriceWithoutServiceFee
                        )}
                      </p>
                    </div>
                    <div style={{ marginBottom: "16px" }}>
                      <p
                        className={"f16 boldText"}
                        style={{ color: " #38383A" }}
                      >
                        Service fee
                      </p>
                      <p
                        className={"semiBoldText f16"}
                        style={{ color: "#919294" }}
                      >
                        {singleOrder?.data?.order?.serviceFee}%
                      </p>
                    </div>
                    <div style={{ marginBottom: "16px" }}>
                      <p
                        className={"f16 boldText"}
                        style={{ color: " #38383A" }}
                      >
                        Delivery fee
                      </p>
                      <p
                        className={"semiBoldText f16"}
                        style={{ color: "#919294" }}
                      >
                        ₦
                        {formatAmount(
                          singleOrder?.data?.order?.shipping?.deliveryFee
                        )}
                      </p>
                    </div>
                    <div
                      style={{
                        marginBottom: "16px",
                        borderBottom: " 0.5px solid #E6E7E9",
                        paddingBottom: "16px",
                      }}
                    >
                      <p
                        className={"f16 boldText"}
                        style={{ color: " #38383A" }}
                      >
                        Discount
                      </p>
                      <p
                        className={"semiBoldText f16"}
                        style={{ color: "#919294" }}
                      >
                        ₦{singleOrder?.data?.order?.totalDiscount}
                      </p>
                    </div>
                    <div style={{ marginBottom: "16px" }}>
                      <p
                        className={"f16 boldText"}
                        style={{ color: " #38383A" }}
                      >
                        Grand total
                      </p>
                      <p
                        className={"semiBoldText f16"}
                        style={{ color: "#919294" }}
                      >
                        ₦
                        {formatAmount(
                          Number(singleOrder?.data?.order?.totalItemsPrice) +
                            Number(
                              singleOrder?.data?.order?.shipping?.deliveryFee
                            )
                        )}
                      </p>
                    </div>
                  </CardsWithHeader>
                  <CardsWithHeader
                    title={"Message for restaurant"}
                    icon={Message}
                    class={`${
                      singleOrder?.data?.order?.rider ? "lastCard" : ""
                    }`}
                  >
                    <div>
                      <p
                        className={"f14 semiBoldText"}
                        style={{ marginBottom: "16px", color: "#38383A" }}
                      >
                        Message
                      </p>
                      <p
                        className={"f16 regularText"}
                        style={{ fontStyle: "italic", color: "#999CA0" }}
                      >
                        {singleOrder?.data?.order?.comment}
                      </p>
                    </div>
                  </CardsWithHeader>
                </>
              )}
            </section>
          </main>
        </>
      )}
      {isTabletOrMobile && (
        <main style={{ padding: "40px 20px 150px 20px" }}>
          <section className={"flexRow alignCenter justifyBetween"}>
            <div className={"flexRow alignCenter"}>
              <BackComponent />
              {gettingSingleOrder ? (
                <>
                  <Skeleton width={"200px"} />
                </>
              ) : (
                <>
                  <h3
                    className={"boldText f16"}
                    style={{ margin: "0 8px 0 8px" }}
                  >
                    {singleOrder?.data?.order?.id}
                  </h3>
                  <p
                    className={
                      "orderStatusContainer textCenter f14 regularText"
                    }
                    style={{
                      backgroundColor: statusConfig.bgColor,
                      color: statusConfig.textColor,
                      width: "auto",
                    }}
                  >
                    {statusConfig.text}
                  </p>
                </>
              )}
            </div>
            <div>
              {singleOrder?.data?.order?.orderStatus !== "ORDER_DELIVERED" &&
                singleOrder?.data?.order?.orderStatus !== "ORDER_CANCELLED" && (
                  <MultiButton
                    title="Actions"
                    bgColor="inherit"
                    onClick={toggleModal}
                    height="40px"
                    width="128px"
                    color="#000"
                    brd="1px solid #000"
                    iconArt={
                      <div style={{ transform: "rotate(90deg)" }}>
                        <More size="20" color="#000" variant="bold" />
                      </div>
                    }
                  />
                )}

              <SliderModal isOpen={isModalOpen} toggleModal={toggleModal}>
                <div>
                  <div
                    className="flexColumn"
                    style={{ gap: "10px", margin: "16px 0" }}
                  >
                    {(singleOrder?.data?.order?.orderStatus ===
                      "ORDER_ASSIGNED" ||
                      singleOrder?.data?.order?.orderStatus ===
                        "ORDER_ACCEPTED" ||
                      singleOrder?.data?.order?.orderStatus ===
                        "ORDER_PICKED_UP") && (
                      <MultiButton
                        title="Update order status"
                        bgColor="#66BB6A"
                        onClick={openUpdateModal}
                        height="40px"
                        // width="160px"
                      />
                    )}
                  </div>

                  {singleOrder?.data?.order?.orderStatus ===
                    "ORDER_CONFIRMED" && (
                    <MultiButton
                      title="Order available"
                      bgColor="#66BB6A"
                      onClick={() => updateStatus("ORDER_AVAILABLE")}
                      height="40px"
                      // width="160px"
                    />
                  )}

                  <div style={{ margin: "16px 0" }}>
                    {(singleOrder?.data?.order?.orderStatus ===
                      "ORDER_AVAILABLE" ||
                      singleOrder?.data?.order?.orderStatus ===
                        "ORDER_ASSIGNED") && (
                      <MultiButton
                        title="Order ready"
                        bgColor="#66BB6A"
                        onClick={() =>
                          updateStatus("ORDER_AWAITING_ASSIGNMENT")
                        }
                        height="40px"
                      />
                    )}
                  </div>

                  <div style={{ marginBottom: "16px" }}>{renderButtons()}</div>
                  <MultiButton
                    title={"Find closest rider"}
                    icon={false}
                    bgColor={"#66BB6A"}
                    onClick={openMapModal}
                    height={"40px"}
                  />
                  {singleOrder?.data?.order?.orderStatus !==
                    "ORDER_DELIVERED" &&
                    singleOrder?.data?.order?.orderStatus !==
                      "ORDER_CANCELLED" && (
                      <MultiButton
                        title="Cancel order"
                        bgColor="#F96868"
                        onClick={openCancelModal}
                        height="40px"
                        // width="128px"
                      />
                    )}
                </div>
              </SliderModal>

              {/**/}
            </div>
          </section>
          <section
            style={{
              display: "grid",
              gridTemplateColumns: "100%",
              gap: "16px",
              marginTop: "20px",
            }}
          >
            {gettingSingleOrder ? (
              <>
                <Skeleton height={"300px"} style={{ borderRadius: "8px" }} />
                <Skeleton height={"300px"} style={{ borderRadius: "8px" }} />
                <Skeleton height={"300px"} style={{ borderRadius: "8px" }} />
                <Skeleton height={"300px"} style={{ borderRadius: "8px" }} />
              </>
            ) : (
              <>
                <CardsWithHeader title={"About customer"} icon={AboutUserIcon}>
                  <div style={{ marginBottom: "16px" }}>
                    <p className={"f16 boldText"} style={{ color: " #38383A" }}>
                      Fullname
                    </p>
                    <p
                      className={"semiBoldText f16"}
                      style={{ color: "#919294" }}
                    >
                      {singleOrder?.data?.order?.user.firstName +
                        " " +
                        singleOrder?.data?.order?.user.lastName || "No name"}
                    </p>
                  </div>
                  <div style={{ marginBottom: "16px" }}>
                    <p className={"f16 boldText"} style={{ color: " #38383A" }}>
                      Mobile number
                    </p>
                    <p
                      className={"semiBoldText f16"}
                      style={{ color: "#919294" }}
                    >
                      {singleOrder?.data?.order?.user.phoneNumber ||
                        "No number"}
                    </p>
                  </div>
                  {/* <div style={{ marginBottom: '16px' }}>
                                    <p className={'f16 boldText'} style={{ color: ' #38383A' }}>State of residence</p>
                                    <p className={'semiBoldText f16'} style={{ color: '#919294' }}>{stateOfResidence}</p>
                                </div> */}
                  <div style={{ marginBottom: "16px" }}>
                    <p className={"f16 boldText"} style={{ color: " #38383A" }}>
                      Delivery address
                    </p>
                    <p
                      className={"semiBoldText f16"}
                      style={{ color: "#919294" }}
                    >
                      {singleOrder?.data?.order?.shipping?.destinationAddress ||
                        "No address"}
                    </p>
                  </div>
                  {recievingCustomerName && (
                    <div style={{ marginBottom: "16px" }}>
                      <p
                        className={"f16 boldText"}
                        style={{ color: " #38383A" }}
                      >
                        Receivers name
                      </p>
                      <p
                        className={"semiBoldText f16"}
                        style={{ color: "#919294" }}
                      >
                        {singleOrder?.data?.order?.recievingCustomerName}
                      </p>
                    </div>
                  )}
                  {recievingCustomerPhone && (
                    <div style={{ marginBottom: "16px" }}>
                      <p
                        className={"f16 boldText"}
                        style={{ color: " #38383A" }}
                      >
                        Receivers mobile number
                      </p>
                      <p
                        className={"semiBoldText f16"}
                        style={{ color: "#919294" }}
                      >
                        {singleOrder?.data?.order?.recievingCustomerPhone}
                      </p>
                    </div>
                  )}
                  {(recievingCustomerName || recievingCustomerPhone) && (
                    <div style={{ marginBottom: "16px" }}>
                      <p
                        className={"f16 boldText"}
                        style={{ color: "#38383A" }}
                      >
                        Receivers address
                      </p>
                      <p
                        className={"semiBoldText f16"}
                        style={{ color: "#919294" }}
                      >
                        {destinationAddress}
                      </p>
                    </div>
                  )}
                  <div style={{ marginBottom: "16px" }}>
                    <p className={"f16 boldText"} style={{ color: " #38383A" }}>
                      Device
                    </p>
                    <p
                      className={"semiBoldText f16"}
                      style={{ color: "#919294" }}
                    >
                      {singleOrder?.data?.order?.deviceUsed}
                    </p>
                  </div>
                </CardsWithHeader>
                {singleOrder?.data?.order?.rider && (
                  <CardsWithHeader title={"About Rider"} icon={RiderIcon}>
                    <div style={{ marginBottom: "16px" }}>
                      <p
                        className={"f16 boldText"}
                        style={{ color: " #38383A" }}
                      >
                        Fullname
                      </p>
                      <p
                        className={"semiBoldText f16"}
                        style={{ color: "#919294" }}
                      >
                        {singleOrder?.data?.order?.rider?.user?.firstName +
                          " " +
                          singleOrder?.data?.order?.rider?.user?.lastName ||
                          "No name"}
                      </p>
                    </div>
                    <div style={{ marginBottom: "16px" }}>
                      <p
                        className={"f16 boldText"}
                        style={{ color: " #38383A" }}
                      >
                        Mobile number
                      </p>
                      <p
                        className={"semiBoldText f16"}
                        style={{ color: "#919294" }}
                      >
                        {singleOrder?.data?.order?.rider?.user?.phoneNumber ||
                          "No number"}
                      </p>
                    </div>
                    <div style={{ marginBottom: "16px" }}>
                      <p
                        className={"f16 boldText"}
                        style={{ color: " #38383A" }}
                      >
                        State of residence
                      </p>
                      <p
                        className={"semiBoldText f16"}
                        style={{ color: "#919294" }}
                      >
                        {
                          singleOrder?.data?.order?.rider
                            ?.riderOperationLocation
                        }
                      </p>
                    </div>
                    {/* <div className={'flexRow alignCenter justifyBetween'} style={{ marginBottom: '16px' }}>
                                        <p className={'f16 boldText'} style={{ color: ' #38383A' }}>Delivery address</p>
                                        <p className={'semiBoldText f16'} style={{ color: '#919294' }}>{singleOrder?.data?.order?.shipping?.destinationAddress || 'No address'}</p>
                                    </div> */}
                  </CardsWithHeader>
                )}
                <CardsWithHeader title={"Meal setup"} icon={MealSetupIcon}>
                  {singleOrder?.data?.order?.orderItems?.map((item, index) => {
                    return (
                      <div
                        className={"flexRow  justifyBetween"}
                        style={{ marginBottom: "16px" }}
                        key={index}
                      >
                        <div>
                          <p className={"f16 boldText"}>{item?.name}</p>
                          <p
                            className={"f14 semiBoldText"}
                            style={{ color: "#919294" }}
                          >
                            ₦{formatAmount(item?.price)}
                          </p>
                        </div>
                        <div>
                          <p
                            className={"f16 boldText"}
                            style={{ color: "#919294" }}
                          >
                            x{item?.quantity}
                          </p>
                        </div>
                      </div>
                    );
                  })}

                  <div style={{ marginBottom: "16px", marginTop: "20px" }}>
                    <p className={"f16 boldText"} style={{ color: " #38383A" }}>
                      Delivery status
                    </p>
                    <div
                      style={{
                        color: orderClassification.color,
                        backgroundColor: orderClassification.bgColor,
                        maxWidth: "140px",
                        textAlign: "center",
                        padding: "3px 6px",
                        borderRadius: "8px",
                        marginTop: "8px",
                      }}
                    >
                      <p
                        className={"semiBoldText f14"}
                        style={{ color: orderClassification.color }}
                      >
                        {orderClassification.status}
                      </p>
                    </div>
                  </div>
                </CardsWithHeader>
                <CardsWithHeader title={"Billing & Payment"} icon={BillingIcon}>
                  <div style={{ marginBottom: "16px" }}>
                    <p className={"f16 boldText"} style={{ color: " #38383A" }}>
                      Payment method
                    </p>
                    <p
                      className={"semiBoldText f16"}
                      style={{ color: "#919294" }}
                    >
                      {paymentMethod?.paymentMode} -{" "}
                      {paymentMethod?.paymentProvider}
                    </p>
                  </div>
                  <div style={{ marginBottom: "16px" }}>
                    <p className={"f16 boldText"} style={{ color: " #38383A" }}>
                      Payment status
                    </p>
                    <p
                      className={"semiBoldText f16"}
                      style={{ color: "#919294" }}
                    >
                      {singleOrder?.data?.order?.paymentStatus}
                    </p>
                  </div>
                  <div style={{ marginBottom: "16px" }}>
                    <p className={"f16 boldText"} style={{ color: " #38383A" }}>
                      Meal cost
                    </p>
                    <p
                      className={"semiBoldText f16"}
                      style={{ color: "#919294" }}
                    >
                      ₦
                      {formatAmount(
                        singleOrder?.data?.order?.totalPriceWithoutServiceFee
                      )}
                    </p>
                  </div>
                  <div style={{ marginBottom: "16px" }}>
                    <p className={"f16 boldText"} style={{ color: " #38383A" }}>
                      Service fee
                    </p>
                    <p
                      className={"semiBoldText f16"}
                      style={{ color: "#919294" }}
                    >
                      {singleOrder?.data?.order?.serviceFee}%
                    </p>
                  </div>
                  <div style={{ marginBottom: "16px" }}>
                    <p className={"f16 boldText"} style={{ color: " #38383A" }}>
                      Delivery fee
                    </p>
                    <p
                      className={"semiBoldText f16"}
                      style={{ color: "#919294" }}
                    >
                      ₦
                      {formatAmount(
                        singleOrder?.data?.order?.shipping?.deliveryFee
                      )}
                    </p>
                  </div>
                  <div
                    style={{
                      marginBottom: "16px",
                      borderBottom: " 0.5px solid #E6E7E9",
                      paddingBottom: "16px",
                    }}
                  >
                    <p className={"f16 boldText"} style={{ color: " #38383A" }}>
                      Discount
                    </p>
                    <p
                      className={"semiBoldText f16"}
                      style={{ color: "#919294" }}
                    >
                      ₦{singleOrder?.data?.order?.totalDiscount}
                    </p>
                  </div>
                  <div style={{ marginBottom: "16px" }}>
                    <p className={"f16 boldText"} style={{ color: " #38383A" }}>
                      Grand total
                    </p>
                    <p
                      className={"semiBoldText f16"}
                      style={{ color: "#919294" }}
                    >
                      ₦
                      {formatAmount(
                        Number(singleOrder?.data?.order?.totalItemsPrice) +
                          Number(
                            singleOrder?.data?.order?.shipping?.deliveryFee
                          )
                      )}
                    </p>
                  </div>
                </CardsWithHeader>
                <CardsWithHeader
                  title={"Message for restaurant"}
                  icon={Message}
                >
                  <div>
                    <p
                      className={"f14 semiBoldText"}
                      style={{ marginBottom: "16px", color: "#38383A" }}
                    >
                      Message
                    </p>
                    <p
                      className={"f16 regularText"}
                      style={{ fontStyle: "italic", color: "#999CA0" }}
                    >
                      {singleOrder?.data?.order?.comment}
                    </p>
                  </div>
                </CardsWithHeader>
              </>
            )}
          </section>
        </main>
      )}
      {state?.confirmModal && (
        <ConfirmPaymentModal
          onDecline={closeConfirmModal}
          orderNo={singleOrder?.data?.order?.orderNo}
          id={singleOrder?.data?.order?.id}
        />
      )}
      {state.assignModal && (
        <AssignRiderModal
          onDecline={closeAssignModal}
          orderId={singleOrder?.data?.order?.id}
          id={singleOrder?.data?.order?.id}
        />
      )}
      {state.mapModal && (
        <MapModal
          onDecline={closeMapModal}
          orderId={singleOrder?.data?.order?.id}
          id={singleOrder?.data?.order?.id}
        />
      )}
      {state.agencyModal && (
        <AssignAgencyModal
          onDecline={closeAssignToAgencyModal}
          orderId={singleOrder?.data?.order?.id}
          id={singleOrder?.data?.order?.id}
        />
      )}
      {state.cancelModal && (
        <CancelOrderModal
          onDecline={closeCancelModal}
          orderId={singleOrder?.data?.order?.id}
          id={singleOrder?.data?.order?.id}
        />
      )}
      {state.updateOrderStatusModal && (
        <UpdateOrderStatusModal
          onDecline={closeUpdateModal}
          orderId={singleOrder?.data?.order?.id}
        />
      )}
    </>
  );
};

export default OrderDetails;
