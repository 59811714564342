import React, { useState, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { getUsersByDates } from '../../redux/actions/UsersActions';
import DateRangePicker from '@wojtekmaj/react-daterange-picker';
import { IoIosArrowDown } from 'react-icons/io';
import moment from 'moment';

const VendorFilter = () => {
    const dispatch = useDispatch();
    const [showDate, setShowDate] = useState(false);
    const [dateRange, setDateRange] = useState([new Date(), new Date()]);

    const getUsersByDate = useCallback(() => {
        if (dateRange) {
            const start_date = moment(dateRange[0]).format('YYYY-MM-DD');
            const end_date = moment(dateRange[1]).format('YYYY-MM-DD');
            dispatch(getUsersByDates('Vendors', start_date, end_date));
            setShowDate(false);
            setDateRange(null)
        }
    }, [dateRange, dispatch]);

    const toggleDate = () => {
        setShowDate((prevState) => !prevState);
    };

    return (
        <section style={{ maxWidth: '200px', marginTop: '20px' }}>
            <div style={{ position: 'relative', cursor: 'pointer' }}>
                <div
                    className={'filterDropContainer flexRow alignCenter justifyCenter'}
                    onClick={toggleDate}
                >
                    <p className={'f16 semiBoldText'} style={{ color: '#919294' }}>
                        Date Joined
                    </p>
                    <IoIosArrowDown color={'#919294'} />
                </div>
                {showDate && (
                    <DateRangePicker
                        onChange={setDateRange}
                        value={dateRange}
                        onCalendarClose={getUsersByDate}
                        calendarType='Hebrew'
                    />
                )}
            </div>
        </section>
    );
};

export default VendorFilter;
