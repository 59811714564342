import React, { useState } from 'react';
import { AiOutlineClose, AiOutlineDelete, AiOutlineEdit, AiOutlineFileText } from 'react-icons/ai';
import { SlOptionsVertical } from 'react-icons/sl'
import Skeleton from 'react-loading-skeleton';
import { useSelector } from 'react-redux';
import { formatAmount, shortenXterLength } from '../../../utils/Helpers';


const FoodCard = (props) => {
    const { data, openDeleteProductModal, openEditProductModal, unListProduct, enListProduct } = props
    // console.log(data, 'data')
    const [showMenu, setShowMenu] = useState(false)
    const { gettingProducts } = useSelector(state => state.products)
    const toggleMenu = () => {
        setShowMenu(prevState => !prevState)
    }

    const deleteData = () => {
        openDeleteProductModal()
        toggleMenu()
    }
    const editData = () => {
        openEditProductModal()
        toggleMenu()
    }
    const unListData = () => {
        unListProduct()
        toggleMenu()
    }
    const enListData = () => {
        enListProduct()
        toggleMenu()
    }

    const productImage = data?.images[data?.images?.length - 1];
    const productRecentPrice = data?.variations[data?.variations?.length - 1]?.price;


    return (
        <>
            {gettingProducts ? <section className={'foodCardContainer '}>
                <Skeleton className={'foodCardImage'}>

                </Skeleton>
                <div className={'foodCardDetails'}>
                    <Skeleton height={'20px'} style={{ marginBottom: '10px' }} />
                    <Skeleton height={'20px'} style={{ marginBottom: '10px' }} />
                    <Skeleton height={'20px'} style={{ marginBottom: '10px' }} />
                    <Skeleton height={'20px'} style={{ marginBottom: '10px' }} />
                </div>
            </section> :
                <section className={'foodCardContainer '}>
                    <div className={'foodCardImage'}>
                        {/* <picture> */}
                        <img src={productImage} alt='food' style={{ width: '100%', height: '209px', borderRadius: '16px 16px 0 0' }} />
                        {/* </picture> */}
                        <div className={'foodCardOverlay'}>
                            <span onClick={toggleMenu}><SlOptionsVertical /></span>
                            {!data?.listingStatus && <div className={'unlistedOverLay'}>
                                <p className={'f18 boldText'} style={{ color: '#fff' }}>Meal Un-listed</p>
                                <span onClick={toggleMenu}><SlOptionsVertical /></span>
                            </div>}
                            {showMenu && <div className={'foodCardOptions'}>
                                <AiOutlineClose onClick={toggleMenu} style={{ alignSelf: 'flex-end' }} />
                                <ul style={{ marginTop: '20px' }}>
                                    {!data?.listingStatus ?
                                        <li className={'foodCardOptionItem boldText f14 flexRow alignCenter'} style={{ color: '#696A6B', cursor: 'pointer' }} onClick={enListData}>
                                            <AiOutlineFileText style={{ marginRight: '10px' }} />
                                            List
                                        </li> :
                                        <li className={'foodCardOptionItem boldText f14 flexRow alignCenter'} style={{ color: '#696A6B', cursor: 'pointer' }} onClick={unListData}>
                                            <AiOutlineFileText style={{ marginRight: '10px' }} />
                                            Un-list
                                        </li>
                                    }
                                    <li className={'foodCardOptionItem boldText f14 flexRow alignCenter'} style={{ color: '#696A6B', cursor: 'pointer' }} onClick={editData}>
                                        <AiOutlineEdit style={{ marginRight: '10px' }} />
                                        Edit
                                    </li>
                                    <li className={'foodCardOptionItem boldText f14 flexRow alignCenter'} style={{ color: '#696A6B', cursor: 'pointer' }} onClick={deleteData}>
                                        <AiOutlineDelete style={{ marginRight: '10px' }} color={'#F96868'} />
                                        Delete
                                    </li>
                                </ul>
                            </div>}
                        </div>
                    </div>
                    <div className={'foodCardDetails'}>
                        <div className={'flexRow alignCenter justifyBetween borderBtm'}>
                            <div>
                                <p style={{ color: "#919294", marginBottom: '8px' }} className={'boldText f16'}>{data?.business?.businessName}</p>
                                <p style={{ color: "#38383A" }} className={'boldText f18'}>{shortenXterLength(data?.name, 15)}</p>
                            </div>
                            <div>
                                <p style={{ color: "#FFB049", marginBottom: '8px' }} className={'boldText f12'}>{data?.business?.categories[0]?.category?.name}</p>
                                <p style={{ color: "#38383A", }} className={'boldText f18'}>₦{formatAmount(productRecentPrice)}</p>
                            </div>
                        </div>
                        <ul className={'flexRow alignCenter borderBtm flexWrap'}>
                            {data?.tags?.map((item, index) => {
                                return (
                                    <li style={{ marginRight: '8px', color: "#919294" }} className={'boldText f12 capitalize'} key={index}>{item}</li>
                                )
                            })}
                        </ul>
                        <div>
                            <p className={'boldText f16'} style={{ color: '#38383A', marginBottom: '8px' }}>Combo options</p>
                            <ul className={'flexRow alignCenter'} style={{
                                //     overflow- x: scroll;
                                // overflow-y: hidden;
                                // scroll-behavior: smooth;
                                overflowX: 'scroll', scrollBehavior: 'smooth',
                            }}>
                                {data?.variations?.slice(0, 3)?.map((item, index) => {
                                    return (
                                        <li style={{ color: "#919294", marginRight: '16px', }} className={'mediumText f12 capitalize'} key={index}>
                                            <p style={{ width: 'auto' }}>{shortenXterLength(item?.name, 25)}</p>
                                            <p>₦{formatAmount(item?.price)}</p>
                                        </li>
                                    )
                                })}
                            </ul>
                        </div>
                    </div>
                </section>}
        </>
    )
}

export default FoodCard
