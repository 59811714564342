import http from "../../utils/utils";
import { ADMIN_PROFILE, ADMIN_PROFILE_FAIL, ADMIN_PROFILE_SUCCESSFUL, OPERATION_LOCATIONS, OPERATION_LOCATIONS_FAIL, OPERATION_LOCATIONS_SUCCESSFUL } from "../Types";

export const getAdminProfile = () => {
    return dispatch => {
        dispatch({ type: ADMIN_PROFILE })
        return new Promise(async (resolve, reject) => {
            try {
                const res = await http.get(`admin/auth/profile`)
                const data = res?.data
                // console.log(data)
                dispatch({ type: ADMIN_PROFILE_SUCCESSFUL, payload: data });
                resolve(res);
            } catch (error) {
                // console.log('get orders error', error)
                dispatch({ type: ADMIN_PROFILE_FAIL, payload: error });
                reject(error);
            }
        });
    };
};

export const getOperationsLocations = () => {
    return dispatch => {
        dispatch({ type: OPERATION_LOCATIONS })
        return new Promise(async (resolve, reject) => {
            try {
                const res = await http.get(`operation_locations`)
                const data = res?.data
                // console.log(data)
                dispatch({ type: OPERATION_LOCATIONS_SUCCESSFUL, payload: data });
                resolve(res);
            } catch (error) {
                // console.log('get orders error', error)
                dispatch({ type: OPERATION_LOCATIONS_FAIL, payload: error });
                reject(error);
            }
        });
    };
};