import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useMediaQuery } from 'react-responsive';
import '../../../../assets/styles/orderManagementStyles.css';
import { CART } from '../../../../redux/Types';
import http from '../../../../utils/utils';
import AboutCustomerForm from './AboutCustomerForm';
import BillingForm from './BillingForm';
import MealSetupForm from './MealSetupForm';
import OrderSummary from './OrderSummary';
import { showWarning } from '../../../../utils/Toast';


const CreateOrderForm = (props) => {

    const [state, setState] = useState({
        customer_fullname: "", customer_country_code: "234",
        customer_phone_number: "", business_id: "", order_items: [], payment_method: "",
        payment_status: "", mealTitle: '', mealPrice: '',
        business_name: "", business_state: '', deliveryFee: '', mealCost: null,
        state_of_residence: '', serviceCharge: null, message: '', bizName: ''
    })
    // const isDesktopOrLaptop = useMediaQuery({ query: '(min-width: 1430px)' })

    const [quantity, setQuantity] = useState(1)
    const cities = [
        { id: '1', name: 'Abuja' },
        { id: '2', name: 'Enugu' },
    ]
    const paymentMethods = [
        { id: '1', name: 'CASH' },
        { id: '2', name: 'BANK_TRANSFER' },
    ]

    const paymentStatus = [
        { id: '1', name: 'PROCESSING' },
        { id: '2', name: 'COMPLETED' },
    ]

    const [delivery_address, setDeliveryAddress] = useState(null)
    const [business_address, setBusinessAddress] = useState(null)
    const dispatch = useDispatch()
    const [business, setBusiness] = useState(null)
    console.log(business, business_address)
    const selectBusiness = (val) => {
        setBusiness(val)
        // console.log(val, 'business')
        setState((prevState) => ({ ...prevState, business_id: val?.id, business_name: val?.businessName, business_state: val?.businessAddress?.state }))
        setBusinessAddress(val?.businessAddress?.address)
    }


    //about customer form
    const onChangeName = (e) => {
        setState({ ...state, customer_fullname: e.target.value })
    }

    const onChangeBizName = (e) => {
        setState({ ...state, bizName: e.target.value })
    }

    const onChangeDeliveryFee = (e) => {
        setState({ ...state, deliveryFee: e.target.value })
    }

    const onChangePhone = (e) => {
        const newValue = e.target.value;
        if (!isNaN(newValue)) {
            setState({ ...state, customer_phone_number: newValue });
        }
    }

    //meal setup form
    const onChangeMealTitle = (e) => {
        setState({ ...state, mealTitle: e.target.value })
    }
    const onChangeMealPrice = (e) => {
        const newValue = e.target.value;
        if (!isNaN(newValue)) {
            setState({ ...state, mealPrice: newValue });
        }
    }

    const onChangeQuantity = (e) => {
        const newValue = e.target.value;
        if (!isNaN(newValue)) {
            setState({ ...state, quantity: newValue });
        }
    }


    const onChangeMessage = (e) => {
        setState({ ...state, message: e.target.value })
    }


    const selectPaymentStatus = (val) => {
        setState((prevState) => ({ ...prevState, payment_status: val?.name }))
    }
    const selectPaymentMethod = (val) => {
        setState((prevState) => ({ ...prevState, payment_method: val?.name }))
    }

    const selectStateOfResidence = (val) => {
        setState((prevState) => ({ ...prevState, state_of_residence: val?.name }))
    }

    const addQuantity = () => {
        setQuantity(quantity + 1)
    }

    const decreaseQuantity = () => {
        if (quantity <= 1) {
            setQuantity(1)
        } else {
            setQuantity(quantity - 1)
        }

    }


    const obj = {
        customer_fullname: state.customer_fullname,
        customer_country_code: state.customer_country_code,
        customer_phone_number: state.customer_phone_number,
        business_id: state.business_id,
        business_name: state.business_name ? state?.business_name : state?.bizName,
        business_address: business_address?.label || business_address,
        business_state: state.business_state ? state?.business_state : state?.state_of_residence,
        order_items: state.order_items,
        delivery_address: delivery_address?.label,
        payment_method: state.payment_method,
        payment_status: state.payment_status,
        message: state.message,
        order_delivery_fee: Number(state.deliveryFee)
    }

    const summaryData = {
        customer_fullname: state.customer_fullname,
        customer_country_code: state.customer_country_code,
        customer_phone_number: state.customer_phone_number,
        business_id: state.business_id,
        business_name: state.business_name || state.bizName,
        business_address: business_address?.label || business_address,
        business_state: state.business_state,
        order_items: state.order_items,
        delivery_address: delivery_address?.label,
        payment_method: state.payment_method,
        payment_status: state.payment_status,
        state_of_residence: state.state_of_residence,
        mealCost: state.mealCost,
        delivery_fee: state.deliveryFee,
        message: state.message,

    }

    console.log(obj, summaryData)

    const addToCart = () => {
        const { mealPrice, mealTitle, order_items, } = state
        if (mealPrice !== '' && mealTitle !== '' && quantity !== '') {
            order_items.push({
                price: Number(mealPrice), name: mealTitle, quantity
            })
            dispatch({ type: CART, payload: order_items })
            setState({ ...state, mealPrice: '', mealTitle: '' })
            setQuantity(1)
        }
    }

    const deleteFromCart = (index) => {
        const newArray = [...state.order_items];
        newArray.splice(index, 1);
        setState({ ...state, order_items: newArray })
    };

    const getDeliveryPrice = async () => {

        const bAddress = business_address?.label || business_address

        const obj = { business_id: business?.id, delivery_address: delivery_address?.label, business_address: bAddress }
        if (business?.id) {
            try {
                const res = await http.post(`admin/order/calculate_delivery_fee`, obj)
                setState((prevState) => ({
                    ...prevState, deliveryFee: res?.data?.deliveryFee
                }))
                console.log(res)
            } catch (error) {
                // console.log(error)
                showWarning('Please enter delivery fee')
            }
        }
    }

    const getMealCost = () => {
        const totalAnnualSalary = state.order_items.reduce((acc, obj) => acc + (obj.quantity * obj.price), 0);
        // console.log(totalAnnualSalary)
        setState((prevState) => ({ ...prevState, mealCost: totalAnnualSalary }))

    }
    const getServiceCharge = async () => {
        const obj = { state: state?.state_of_residence, order_items: state?.order_items }
        try {
            const res = await http.post(`admin/order/calculate_service_fee`, obj)
            setState((prevState) => ({ ...prevState, serviceCharge: res?.data }))
            // console.log(res)
        } catch (error) {
            console.log(error)
        }
    }



    // console.log(state.state_of_residence)
    return (
        <section className={'createOrderFormContainer'}>
            {props.currentIndex === 0 &&
                <AboutCustomerForm onChangeName={onChangeName} onChangePhone={onChangePhone}
                    phone={state.customer_phone_number} name={state.customer_fullname}
                // isDesktopOrLaptop={isDesktopOrLaptop}
                />
            }
            {props.currentIndex === 1 && <MealSetupForm
                onChangeMealPrice={onChangeMealPrice} onChangeMealTitle={onChangeMealTitle}
                delivery_address={delivery_address} setDeliveryAddress={setDeliveryAddress} options={cities} addToCart={addToCart}
                cart={state.order_items} mealPrice={state.mealPrice} mealTitle={state.mealTitle} onChangeQuantity={onChangeQuantity}
                quantity={quantity} deleteFromCart={deleteFromCart} business_address={business_address}
                setBusinessAddress={setBusinessAddress} business={business} setBusiness={setBusiness} selectBusiness={selectBusiness}
                selectStateOfResidence={selectStateOfResidence} state_of_residence={state.state_of_residence} message={state.message}
                onChangeMessage={onChangeMessage} onChangeBizName={onChangeBizName} bizName={state.bizName} addQuantity={addQuantity}
                decreaseQuantity={decreaseQuantity}
            />}
            {props.currentIndex === 2 && <BillingForm
                getDeliveryPrice={getDeliveryPrice} deliveryFee={state.deliveryFee} getMealCost={getMealCost}
                mealCost={state.mealCost} paymentMethods={paymentMethods} paymentStatus={paymentStatus}
                selectPaymentMethod={selectPaymentMethod} selectPaymentStatus={selectPaymentStatus}
                payment_method={state.payment_method} payment_status={state.payment_status} onChangeDeliveryFee={onChangeDeliveryFee}

            />
            }
            {props.currentIndex === 3 && <OrderSummary summary={summaryData} obj={obj} done={props.submit} currentIndex={props.currentIndex}
                serviceCharge={state.serviceCharge} getServiceCharge={getServiceCharge}
            />}
        </section>
    )
}

export default CreateOrderForm