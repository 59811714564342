import { Route, Routes } from "react-router-dom"
import ContactAdmin from "../pages/auth/ContactAdmin"
import ForgotPassword from "../pages/auth/ForgotPassword"
import ForgotPasswordOtp from "../pages/auth/ForgotPasswordOtp"
import PhoneVerification from "../pages/auth/PhoneVerification"
import ResetPasswordDone from "../pages/auth/ResetDone"
import ResetPassword from "../pages/auth/ResetPassword"
import SignIn from "../pages/auth/SignIn"
import ComingSoon from "../pages/ComingSoon"
import OrderManagement from "../pages/dashboard/oderManagement/OrderManagement";
import Overview from "../pages/dashboard/Overview"
import Products from "../pages/dashboard/products/ProductsLayout"
import Search from "../pages/dashboard/Search"
import { ProtectedRoute } from "./ProtectedRoutes"
import More from "../pages/dashboard/more/More"
import UserManagement from "../pages/dashboard/user/UserManagement"
import Settings from "../pages/dashboard/settings/Settings"

const RoutesContainer = () => {

    return (
        <>
            <Routes>
                <Route path='/' element={<ProtectedRoute><Overview /></ProtectedRoute>} />
                <Route path={'/login'} element={<SignIn />} />
                <Route path='phoneVerify' element={<PhoneVerification />} />
                <Route path='forgotPassword' element={<ForgotPassword />} />
                <Route path='contactAdmin' element={<ContactAdmin />} />
                <Route path='forgotPasswordOtp' element={<ForgotPasswordOtp />} />
                <Route path='resetPassword' element={<ResetPassword />} />
                <Route path='resetPasswordDone' element={<ResetPasswordDone />} />
                <Route path='orderManagement/*' element={<ProtectedRoute><OrderManagement /></ProtectedRoute>} />
                <Route path='comingSoon' element={<ComingSoon />} />
                <Route path='products/*' element={<ProtectedRoute><Products /></ProtectedRoute>} />
                <Route path='search' element={<ProtectedRoute><Search /></ProtectedRoute>} />
                <Route path='more' element={<ProtectedRoute><More /></ProtectedRoute>} />
                <Route path='userManagement' element={<ProtectedRoute ><UserManagement /></ProtectedRoute>} />
                <Route path='settings' element={<ProtectedRoute ><Settings /></ProtectedRoute>} />


            </Routes>
        </>
    )
}

export default RoutesContainer

