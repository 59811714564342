import React from 'react';
import { useMediaQuery } from 'react-responsive';
import './widgetStyles.css';
import InfoIcon from '../assets/images/icons/infoIcon.svg';
import FilterDropDown from '../components/dashboard/products/FilterDropDown';
import MultiButton from '../utils/MultiButton';
import { InfoDark, PlusIcon } from '../assets/images/icons';
import BackComponent from '../utils/BackComponent';
import { RiSparklingLine } from "react-icons/ri";
import CreateOrderButton from '../assets/images/icons/dashboard/createOrder.svg';
import AIButton from '../utils/AIButton';

const ProductsNav = (props) => {
    const isDesktopOrLaptop = useMediaQuery({ query: '(min-width: 1430px)' })
    const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1430px)' })
    const { openCreateProductsModal, closeCreateProductsModal, closeEditProductModal, openModal } = props
    const closeModal = () => {
        closeCreateProductsModal()
        closeEditProductModal()
    }


    return (
        <nav className={'productsNavContainer'}>
            {isDesktopOrLaptop && <>
                <section className='productsNavLeft'>
                    <div style={{ marginBottom: '30px' }}>
                        <div className={'flexRow alignCenter'}>
                            <h3 className={'f24 boldText '} style={{ color: '#16192C' }}>Products</h3>
                            <img src={InfoIcon} alt='icon' style={{ marginLeft: '10px' }} />
                        </div>
                        <div>
                            <p className={'f16 regularText'}>Create & Manage products </p>
                        </div>
                    </div>
                    {/* <div className={'flexRow alignCenter'}>
                        <p>Filter by</p>
                        <FilterDropDown title={'Category'} />
                        <FilterDropDown title={'Price range'} />
                        <FilterDropDown title={'Vendor'} />
                    </div> */}

                </section>
                <section className='productsNavRight'>
                    <div style={{ marginBottom: '30px', gap: '20px' }} className={'flexRow alignCenter'}>
                        <MultiButton title={'Create products'} glitterEffect={false} icon={PlusIcon} bgColor={'#FFA024'} height={'48px'} width={'185px'} onClick={openCreateProductsModal} />
                        <AIButton title={'Create with AI'} glitterEffect iconArt={<RiSparklingLine />} bgColor={'#FFA024'} height={'48px'} width={'185px'} onClick={openModal} />

                    </div>
                    {/* <div className={'flexRow  justifyEnd'}>
                        <MultiButton title={'Actions'} icon={InfoDark} bgColor={'inherit'} height={'40px'} width={'101px'} brd={'1.5px solid #D6D6D8'}
                            color={'#565758'}
                        />
                    </div> */}
                </section>

            </>}
            {isTabletOrMobile &&
                <section className={'flexRow alignCenter justifyBetween'} style={{ width: '100%' }}>
                    <BackComponent returnBack={closeModal} />
                    <p>Products</p>
                    <div className={'flexRow alignCenter'} style={{ gap: '16px' }}>
                        <img src={CreateOrderButton} alt='icon' style={{ height: '40px', }} onClick={openCreateProductsModal} />
                        <div>
                            <AIButton title={''} glitterEffect iconArt={<RiSparklingLine />} bgColor={'#FFA024'} height={'40px'} width={'40px'} onClick={openModal} />
                        </div>
                    </div>
                </section>}

        </nav >
    )
}

export default ProductsNav