import moment from 'moment';
import React, { useState } from 'react';
import Skeleton from 'react-loading-skeleton';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { BackWardIcon, ForwardIcon } from '../../assets/images/icons';
import '../../assets/styles/extraStyles.css';
import { getOrders, getOrdersByPage, getOrdersByType } from '../../redux/actions/OrdersAction';
import { getStatusConfig } from '../../utils/Helpers';

function getStatusFromTimeDifference(timeDifference) {
  const roundedTime = timeDifference.toFixed(2); // Round to 2 decimal places

  if (timeDifference <= 30) {
    return { status: `Normal ${roundedTime} mins`, color: 'rgba(102, 203, 159, 1)', bgColor: 'rgba(222, 255, 238, 1)', time: roundedTime };
  } else if (timeDifference > 30 && timeDifference <= 40) {
    return { status: `Potential Delay ${roundedTime} mins`, color: 'rgba(255, 213, 79, 1)', bgColor: 'rgba(255, 248, 225, 1)', time: roundedTime };
  } else if (timeDifference > 40 && timeDifference <= 60) {
    return { status: `Delay ${roundedTime} mins`, color: 'rgba(255, 213, 79, 1)', bgColor: 'rgba(255, 248, 225, 1)', time: roundedTime };
  } else if (timeDifference > 60) {
    return { status: `Critical ${roundedTime} mins`, color: 'rgba(241, 96, 99, 1)', bgColor: 'rgba(255, 230, 228, 1)', time: roundedTime };
  } else {
    return { status: `Unknown classification ${roundedTime} mins`, color: 'rgba(241, 96, 99, 1)', bgColor: 'rgba(255, 230, 228, 1)', time: roundedTime };
  }
}

const ClassifyOrder = (whenCustomerPaid, whenOrderDelivered, orderStatus) => {
  const currentTime = new Date().getTime();
  const paymentTime = new Date(whenCustomerPaid).getTime();
  const deliveredTime = new Date(whenOrderDelivered).getTime();
  const timeDifference = (currentTime - paymentTime) / 60000;
  const deliveredTimeDifference = (deliveredTime - paymentTime) / 60000;

  if (!whenCustomerPaid) {
    return { status: `Unpaid`, color: 'rgba(166, 183, 212, 1)', bgColor: 'rgba(235, 242, 250, 1)' };
  }
  if (whenOrderDelivered) {
    return { status: `Delivered ${deliveredTimeDifference.toFixed(2)} mins`, color: '#000', bgColor: 'rgba(240, 241, 242, 1)' };
  }
  if (orderStatus === 'ORDER_CANCELLED') {
    return { status: `Cancelled`, color: '#000', bgColor: 'rgba(240, 241, 242, 1)' };
  }

  return getStatusFromTimeDifference(timeDifference);
};




const CustomOrdersTable = (props) => {

  const { count, currentPage, perPage, total, totalPages, } = props.meta;
  const { filterOrders, orders, gettingOrders, setMeta, meta } = props
  const navigate = useNavigate()
  const dispatch = useDispatch()


  const pageNumbers = Array.from({ length: orders?.meta?.totalPages }, (_, index) => index + 1);
  // console.log(pageNumbers, orders?.meta?.totalPages)
  const maxVisibleNumbers = 5; // Maximum visible numbers on the screen


  let visiblePageNumbers = [];

  if (pageNumbers.length <= maxVisibleNumbers) {
    visiblePageNumbers = pageNumbers; // Show all numbers if the array length is smaller or equal to the maximum visible numbers
  } else {
    const halfVisibleNumbers = Math.floor((maxVisibleNumbers - 1) / 2);
    const startPage = currentPage - halfVisibleNumbers;
    const endPage = currentPage + halfVisibleNumbers;

    if (startPage <= 1) {
      visiblePageNumbers = [...pageNumbers.slice(0, maxVisibleNumbers - 1), '...'];
    } else if (endPage >= pageNumbers.length) {
      visiblePageNumbers = ['...', ...pageNumbers.slice(pageNumbers.length - maxVisibleNumbers + 2)];
    } else {
      visiblePageNumbers = ['...', ...pageNumbers.slice(startPage, endPage + 1), '...'];
    }
  }

  const handlePageChange = pageNumber => {
    setMeta({ ...meta, currentPage: pageNumber });
    dispatch(getOrdersByPage(pageNumber))
  };


  const header = [
    { title: 'ORDER ID', accessor: 'id' },
    { title: 'CUSTOMER NAME', accessor: 'customer' },
    { title: 'VENDOR NAME', accessor: 'vendor' },
    { title: 'ORDER TYPE', accessor: 'type' },
    { title: 'DATE & TIME', accessor: 'dateTime' },
    { title: 'STATUS', accessor: 'status' },
    { title: 'DELIVERY STATUS', accessor: 'delivery status' },
    { title: 'ACTION', accessor: 'action' }
  ]


  const [state, setState] = useState({
    toggleState: 0, plans: [{ id: 1, name: 'All', type: '' }, { id: 2, name: 'Online', order_type: 'ONLINE_ORDER' }, { id: 3, name: 'Offline', order_type: 'OFFLINE_ORDER' },],
  })

  const toggleTab = (index, order_type) => {
    setState({ ...state, toggleState: index })
    filterOrders(order_type)
    // console.log(index)
  }



  const renderPlans = () => {
    const { toggleState, plans } = state
    if (plans && plans.length !== 0) {
      return plans.map((plan, index) => {
        return (
          <>
            <p className={toggleState === index ? 'activeState f12 boldText' : 'tabNeutral regularText f12'}
              onClick={() => toggleTab(index, plan?.order_type)} key={index}
              style={{ cursor: 'pointer', }}
            >{plan.name}</p>
          </>
        )
      })
    }
  }

  const renderTableHead = () => {
    if (header) {
      return header?.map((item, index) => {
        return (
          <td className={'boldText'} key={index}>{item?.title}</td>
        )
      })
    }
  }



  const getSingleOrder = (val) => {

    navigate('/orderManagement/orderDetails', {
      state: {
        id: val.id,
      }
    })

  }

  const renderTableRow = () => {

    if (gettingOrders) {
      return (
        <>
          <Skeleton height={'40px'} width={'100%'} style={{ marginBottom: '20px' }} />
          <Skeleton height={'40px'} width={'100%'} style={{ marginBottom: '20px' }} />
          <Skeleton height={'40px'} width={'100%'} style={{ marginBottom: '20px' }} />
          <Skeleton height={'40px'} width={'100%'} style={{ marginBottom: '20px' }} />
          <Skeleton height={'40px'} width={'100%'} style={{ marginBottom: '20px' }} />
          <Skeleton height={'40px'} width={'100%'} style={{ marginBottom: '20px' }} />
          <Skeleton height={'40px'} width={'100%'} style={{ marginBottom: '20px' }} />
        </>
      )
    }

    if (orders) {
      return orders?.orders?.map((item, index) => {
        const formattedDate = moment(item?.createdAt).format('YYYY-MM-DD hh:mm')
        const orderClassification = ClassifyOrder(item?.whenCustomerPayedForOrder, item?.whenOrderGotDelivered, item?.orderStatus);
        const statusConfig = getStatusConfig(item?.orderStatus);
        return (
          <tr key={index}>
            <td className={'regularText f12 capitalize'}>{item?.id}</td>
            <td className={'regularText f12 capitalize'}>{item?.user?.firstName || 'Nil'}</td>
            <td className={'regularText f12 capitalize'}>{item?.originBusinessName || 'Nil'}</td>
            <td className={'regularText f12 capitalize'}>{item?.orderType}</td>
            <td className={'regularText f12 capitalize'}>{formattedDate}</td>
            <td className={'regularText f10 capitalize textCenter'}>
              <p
                className={'orderStatusContainer textCenter f10 regularText'}
                style={{
                  backgroundColor: statusConfig.bgColor,
                  color: statusConfig.textColor,
                  // width: 'auto',
                }}
              >
                {statusConfig.text}
              </p>
            </td>
            <td className={'regularText f10 capitalize textCenter'}>
              <p style={{ backgroundColor: orderClassification.bgColor, margin: '0', color: orderClassification.color }}
                className={'orderStatusContainer'}>
                {orderClassification.status}
              </p>
            </td>
            <td className={'semiBoldText f12 capitalize'} style={{ color: '#FFA024', cursor: 'pointer', textAlign: 'left' }} onClick={() => getSingleOrder(item)}>
              <p style={{ width: '88px' }}>View</p>
            </td>
          </tr>
        )
      })
    }
  }
  console.log(visiblePageNumbers, 'dkdk')

  return (
    <section className={'tableContainer'}>
      <section className={'flexRow alignCenter justifyBetween tableHeader'}>
        <div>
          <p className={'boldText f16'}>{props.title ? props.title : 'Orders'}</p>
        </div>
        {/* <div className={'filterContainer'}>
          {renderPlans()}
        </div> */}
      </section>
      <table className={'tableContentContainer'}>
        <thead >
          <tr >
            {renderTableHead()}
          </tr>
        </thead>
        <tbody className={'tableBodyContainer'}>
          {renderTableRow()}
        </tbody>
      </table>
      <div className={'flexRow alignCenter paginationContainer'}>
        <button disabled={currentPage === 1} onClick={() => handlePageChange(currentPage - 1)} className={'prevBtn'}>
          <img src={BackWardIcon} alt='icon' />
        </button>
        <ul className={'flexRow alignCenter'}>
          {visiblePageNumbers.map(pageNumber => (
            <li key={pageNumber} className={'pageNumberContainer regularText f14'}>
              <button onClick={() => handlePageChange(pageNumber)} className={currentPage === pageNumber ? "active" : ""}>
                {pageNumber}
              </button>
            </li>
          ))}
        </ul>
        <button disabled={currentPage === orders?.data?.meta?.totalPages} onClick={() => handlePageChange(currentPage + 1)} className={'nextBtn'}>
          <img src={ForwardIcon} alt='icon' />
        </button>

      </div>
    </section>
  )
}

export default CustomOrdersTable



