import React, { useState } from 'react';
import Upload from '../assets/images/icons/upload.svg';

function AIImageUpload(props) {
    const { label, customInputContainer, customLabel, icon, inputStyle, image, handleImageChange, loading, handleRemove } = props;

    const hiddenFileInput = React.useRef(null);

    const handleClick = () => {
        hiddenFileInput.current.click();
    };



    return (
        <div className={`inputContainer ${customInputContainer}`} style={{ position: 'relative' }}>
            <label className={`f14 grey semiBoldText flex alignCenter ${customLabel}`}>
                {label}
            </label>

            {/* File input */}
            <div
                className={`input flexRow alignCenter justifyBetween ${inputStyle}`}
                style={{ padding: '0 16px', height: '48px', cursor: 'pointer' }}
                onClick={handleClick}
            >
                <input
                    type="file"
                    id="image"
                    name="image"
                    accept="image/*,.pdf,.doc,.docx"
                    style={{ display: 'none' }}
                    onChange={handleImageChange}
                    ref={hiddenFileInput}
                />


                {/* Show uploaded image name or placeholder */}
                {image ? (
                    <p className="f12 boldText">{image.name}</p>
                ) : (
                    <p className="f10 boldText" style={{ color: '#999CA0', maxWidth: '200px' }}>
                        Upload corresponding food image in jpg or png (size within 6mb).
                    </p>
                )}

                <label style={{ position: 'absolute', right: '16px' }}>
                    <img src={Upload} alt="upload images" />
                </label>
            </div>

            {/* Show loading spinner if loading */}
            {loading && <div className={'flexRow alignCenter'} style={{ gap: '16px', margin: '16px 0 0 10px' }}>
                <div className={'aiLoader'} />
                <p className={'boldText f12'}>Extracting Data...</p>
            </div>}

            {/* Show the image preview and buttons if image is uploaded */}
            {image && image instanceof File && !loading && (
                <div className="uploadedImageContainer" style={{ marginTop: '16px', textAlign: 'center' }}>
                    <img
                        src={URL?.createObjectURL(image)}
                        alt="Uploaded"
                        style={{ width: '100px', height: '100px', objectFit: 'cover', marginBottom: '8px' }}
                    />

                    {/* Buttons to change or remove the image */}
                    <div style={{ display: 'flex', justifyContent: 'center', gap: '16px', width: '200px', margin: "20px auto" }}>
                        <button
                            className="button"
                            onClick={handleClick}
                            style={{ backgroundColor: 'inherit', padding: '8px', borderRadius: '4px', cursor: 'pointer', border: '1px solid #000', color: "#000" }}
                        >
                            Change
                        </button>
                        <button
                            className="button"
                            onClick={() => handleRemove(hiddenFileInput)}
                            style={{ backgroundColor: '#f44336', padding: '8px', borderRadius: '4px', color: '#fff', cursor: 'pointer' }}
                        >
                            Remove
                        </button>
                    </div>
                </div>
            )}
        </div>
    );
}

export default AIImageUpload;
