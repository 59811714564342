import http from "../../utils/utils";
import { FETCH_USERS, FETCH_USERS_FAIL, FETCH_USERS_SUCCESSFUL, UPDATE_META, USER_ORDER_HISTORY, USER_ORDER_HISTORY_FAIL, USER_ORDER_HISTORY_SUCCESSFUL, USER_PROFILE, USER_PROFILE_FAIL, USER_PROFILE_SUCCESSFUL } from "../Types";

export const getUsers = () => {
    return dispatch => {
        dispatch({ type: FETCH_USERS })
        return new Promise(async (resolve, reject) => {
            try {
                const res = await http.get(`admin/users`)
                const data = res?.data
                dispatch({ type: FETCH_USERS_SUCCESSFUL, payload: data });
                resolve(res);
            } catch (error) {
                // console.log('get orders error', error)
                dispatch({ type: FETCH_USERS_FAIL, payload: error });
                reject(error);
            }
        });
    };
};

export const getUsersByType = (userType, page) => {
    return dispatch => {
        dispatch({ type: FETCH_USERS })
        return new Promise(async (resolve, reject) => {
            try {
                const res = await http.get(`admin/users?user_type=${userType}&page=${page}`)
                const data = res?.data
                console.log(data)
                dispatch({ type: FETCH_USERS_SUCCESSFUL, payload: data });
                resolve(data);
            } catch (error) {
                dispatch({ type: FETCH_USERS_FAIL, payload: error });
                reject(error);
            }
        });
    };
};



export const getUsersByDates = (userType, start_date, end_date) => {
    return dispatch => {
        dispatch({ type: FETCH_USERS });
        return new Promise(async (resolve, reject) => {
            try {
                let url = 'admin/users';
                if (userType) {
                    url += `?user_type=${userType}&start_date=${start_date}&end_date=${end_date}`;
                }

                if (!userType && start_date && end_date) {
                    url += `?start_date=${start_date}&end_date=${end_date}`;
                }

                const res = await http.get(url);
                const data = res?.data?.users
                dispatch({ type: FETCH_USERS_SUCCESSFUL, payload: data });
                resolve(data);
            } catch (error) {
                dispatch({ type: FETCH_USERS_FAIL, payload: error });
                reject(error);
            }
        });
    };
};


export const getUsersByPage = (page) => {
    return dispatch => {
        dispatch({ type: FETCH_USERS })
        return new Promise(async (resolve, reject) => {
            try {
                const res = await http.get(`admin/users?page=${page}`)
                const data = res?.data
                dispatch({ type: FETCH_USERS_SUCCESSFUL, payload: data });
                resolve(data);
            } catch (error) {
                // console.log('get orders error', error)
                dispatch({ type: FETCH_USERS_FAIL, payload: error });
                reject(error);
            }
        });
    };
};


export const getUserProfile = (id) => {
    return dispatch => {
        dispatch({ type: USER_PROFILE })
        return new Promise(async (resolve, reject) => {
            try {
                const res = await http.get(`admin/user/${id}`)
                const data = res?.data?.user
                dispatch({ type: USER_PROFILE_SUCCESSFUL, payload: data });
                resolve(data);
            } catch (error) {
                dispatch({ type: USER_PROFILE_FAIL, payload: error });
                reject(error);
            }
        });
    };
};





export const getUserWaitList = (userType, start_date, end_date, page) => {
    return dispatch => {
        dispatch({ type: FETCH_USERS });
        return new Promise(async (resolve, reject) => {
            try {
                let url = 'admin/user/waitlist';
                if (userType) {
                    url += `?user_type=${userType}`;
                }
                if (start_date && end_date) {
                    url += `?start_date=${start_date}&end_date=${end_date}`;
                }
                if (page) {
                    url += `?page=${page}`;
                }

                const res = await http.get(url);
                const data = res.data?.data
                // console
                console.log('users ', res?.data?.data);
                dispatch({ type: FETCH_USERS_SUCCESSFUL, payload: data });
                resolve(data);
            } catch (error) {
                dispatch({ type: FETCH_USERS_FAIL, payload: error });
                reject(error);
            }
        });
    };
};


export const getUsersAll = (userType, status, start_date, end_date, page) => {
    return dispatch => {
        dispatch({ type: FETCH_USERS });
        return new Promise(async (resolve, reject) => {
            try {
                let url = 'admin/users';
                if (userType) {
                    url += `?user_type=${userType}`;
                }
                if (status) {
                    url += `?status=${status}`;
                }
                if (start_date && end_date) {
                    url += `?start_date=${start_date}&end_date=${end_date}`;
                }
                if (page) {
                    url += `?page=${page}`;
                }
                const res = await http.get(url);
                const data = res?.data?.users
                console.log('users ', data);
                dispatch({ type: FETCH_USERS_SUCCESSFUL, payload: data });
                resolve(data);
            } catch (error) {
                dispatch({ type: FETCH_USERS_FAIL, payload: error });
                reject(error);
            }
        });
    };
};

export const getUsersRiders = (status, start_date, end_date,) => {
    return dispatch => {
        dispatch({ type: FETCH_USERS });
        return new Promise(async (resolve, reject) => {
            try {
                let url = 'admin/users?user_type=Riders';

                if (status) {
                    url += `&status=${status}`;
                }
                if (start_date && end_date) {
                    url += `&start_date=${start_date}&end_date=${end_date}`;
                }
                const res = await http.get(url);
                const data = res?.data?.users
                console.log('users ', data);
                dispatch({ type: FETCH_USERS_SUCCESSFUL, payload: data });
                resolve(data);
            } catch (error) {
                dispatch({ type: FETCH_USERS_FAIL, payload: error });
                reject(error);
            }
        });
    };
};




export const getUserOrderHistory = (id) => {
    return dispatch => {
        dispatch({ type: USER_ORDER_HISTORY })
        return new Promise(async (resolve, reject) => {
            try {
                const res = await http.get(`admin/user/${id}/order_history`)
                const data = res?.data?.orders
                dispatch({ type: USER_ORDER_HISTORY_SUCCESSFUL, payload: data });
                resolve(data);
            } catch (error) {
                dispatch({ type: USER_ORDER_HISTORY_FAIL, payload: error });
                reject(error);
            }
        });
    };
};

