import React from 'react';
import '../../../assets/styles/userManagementStyles.css';
import { Logo } from '../../../assets/images/Index';

const GridCard = (props) => {
    const { item, onClick, itemID } = props
    const renderName = () => {
        if (item?.firstName) {
            return item?.firstName
        }
        if (item?.riderFirstName) {
            return item?.riderFirstName
        }
        if (item?.vendorFirstName) {
            return item?.vendorFirstName
        }
        return 'Nil'
    }
    const renderEmail = () => {
        if (item?.email) {
            return item?.email
        }
        if (item?.riderEmailAddress) {
            return item?.riderEmailAddress
        }
        if (item?.vendorEmailAddress) {
            return item?.vendorEmailAddress
        }
        return 'Nil'
    }
    const renderPhoneNumber = () => {
        if (item?.phone) {
            return item?.phone
        }
        if (item?.riderPhoneNumber) {
            return item?.riderPhoneNumber
        }
        if (item?.vendorPhoneNumber) {
            return item?.vendorPhoneNumber
        }
        return 'Nil'
    }

    const renderRole = () => {
        if (item?.role) {
            return item?.role
        }
        if (item?.userType) {
            return item?.userType
        }
        return 'Nil'
    }


    const renderAvatar = () => {
        if (item?.avatar) {
            return item?.avatar
        }
        return Logo
    }


    return (
        <section className={'userGridCardContainer'} onClick={onClick} >
            <div className={'userGridCardImage'}>
                <span className={'userGridCardStatus f10 boldText'}>Active</span>
                <img src={renderAvatar()} alt='avatar' style={{ width: '100%', height: '100%', borderRadius: '12px 12px 0 0', }} />
            </div>
            <div className={'userGridCardContent'}>
                <div className={'flexRow alignCenter justifyBetween'}>
                    <p className={'f16 boldText'} style={{ color: '#38383A', textTransform: 'capitalize' }}>{renderName()}</p>
                    <p className={'f16 boldText'} style={{ color: '#919294' }}>{item?.gender || 'Nil'}</p>
                </div>
                <div className={'userGridCardInfo'}>
                    <ul className={'flexRow alignCenter'}>
                        <li className={'f14 boldText'} style={{ color: '#919294', textAlign: 'center', borderRight: '0.5px solid #D6D6D8', width: "50%", paddingRight: '8px' }}>{renderPhoneNumber()}</li>
                        <li className={'f14 boldText'} style={{ color: '#919294', textAlign: 'center', width: "50%", textTransform: 'capitalize' }}>{renderRole()}</li>
                    </ul>
                    <p className={'f14 boldText'} style={{ color: '#919294', borderTop: '0.5px solid #D6D6D8', textAlign: 'center', paddingTop: '4px' }}>{renderEmail()}</p>
                </div>
            </div>
        </section>
    )
}

export default GridCard