import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import { useMediaQuery } from 'react-responsive'
import { CloseIcon } from '../../../assets/images/icons'
import { getOrdersById } from '../../../redux/actions/OrdersAction'
import CustomButton from '../../../utils/CustomButton'
import CustomInput from '../../../utils/CustomInput'
import CustomMessageInput from '../../../utils/CustomMessageInput'
import MultiButton from '../../../utils/MultiButton'
import { showSuccess } from '../../../utils/Toast'
import http from '../../../utils/utils'

const CancelOrderModal = (props) => {

    const isDesktopOrLaptop = useMediaQuery({
        query: '(min-width: 1430px)'
    })
    const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1430px)' })


    const { orderId, onDecline, id } = props

    const [state, setState] = useState({
        cancel_reason: '', loading: false
    })
    const dispatch = useDispatch()

    const onChangeCancelReason = (e) => {
        setState({ ...state, cancel_reason: e.target.value })
    }

    const disabled = () => {
        if (state.cancel_reason !== '') {
            return false
        }
        return true
    }

    const cancelOrder = async () => {
        const obj = { cancel_reason: state.cancel_reason, order_id: orderId }
        setState({ ...state, loading: true })
        try {
            const res = await http.post(`admin/order/cancel`, obj)
            setState({ ...state, loading: false })
            showSuccess(res?.message)
            onDecline()
            dispatch(getOrdersById(id))

        } catch (error) {
            console.log(error)
            setState({ ...state, loading: false })

        }
    }



    return (


        <>
            {isDesktopOrLaptop &&
                <section className={'modalContainer'}>
                    <div className={'createOrderModal'} style={{ maxWidth: '633px', width: '633px' }}>
                        <div className={'createOrderModalHeader flexRow alignCenter justifyBetween'}>
                            <p className={'f20 boldText'}>Cancel order</p>
                            <span onClick={props.onDecline}>
                                <img src={CloseIcon} alt='icon' />
                            </span>
                        </div>
                        <div>
                            <CustomMessageInput placeholder={'Reasons for cancelling'} label={'Enter reason for cancelling'}
                                value={state.cancel_reason} onChange={onChangeCancelReason} />
                        </div>
                        <div className={'createOrderModalFooter flexRow alignCenter justifyBetween'} style={{ marginTop: '40px' }}>
                            <div />
                            <div className={'flexRow alignCenter footerButtons'} style={{ width: '50%', justifyContent: 'flex-end' }}>
                                <div style={{ marginRight: '8px' }}>
                                    <CustomButton title={'Cancel'} customStyle={'createOrderCancelBtn'} onClick={props.onDecline} />
                                </div>
                                <div >
                                    <MultiButton title={'Yes, reject'} bgColor={'#F84E4E'} disabled={disabled()} loading={state.loading}
                                        onClick={cancelOrder}
                                    />
                                </div>

                            </div>
                        </div>
                    </div>
                </section>
            }
            {isTabletOrMobile &&
                <section className={'mobileModalContainer'}>
                    <div className={'mobileCreateOrderModal'} style={{ width: "100%" }}>
                        <div className={'createOrderModalHeader flexRow alignCenter justifyBetween'}>
                            <p className={'f16 boldText'}>Cancel order</p>
                            <span onClick={props.onDecline}>
                                <img src={CloseIcon} alt='icon' />
                            </span>
                        </div>
                        <div>
                            <CustomMessageInput placeholder={'Reasons for cancelling'} label={'Enter reason for cancelling'}
                                value={state.cancel_reason} onChange={onChangeCancelReason} />
                        </div>
                        <div className={'flexRow alignCenter justifyBetween'} style={{ marginTop: '40px' }}>

                            <div style={{ marginRight: '8px' }}>
                                <CustomButton title={'Cancel'} customStyle={'createOrderCancelBtn'} onClick={props.onDecline} />
                            </div>
                            <div >
                                <MultiButton title={'Yes, reject'} bgColor={'#F84E4E'} disabled={disabled()} loading={state.loading}
                                    onClick={cancelOrder}
                                />
                            </div>


                        </div>
                    </div>
                </section>
            }
        </>

    )
}

export default CancelOrderModal