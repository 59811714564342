import React, { useEffect } from 'react'
import { useMediaQuery } from 'react-responsive';
import CityDropDown from '../../../../utils/CityDropDown';
import CustomInput from '../../../../utils/CustomInput'

const BillingForm = (props) => {


    const isDesktopOrLaptop = useMediaQuery({ query: '(min-width: 1430px)' })
    const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1430px)' })

    const { getDeliveryPrice, deliveryFee, getMealCost, mealCost, paymentMethods, paymentStatus,
        selectPaymentMethod, selectPaymentStatus, payment_method, payment_status, onChangeDeliveryFee
    } = props

    useEffect(() => {
        getDeliveryPrice();
        getMealCost();
        return () => { };
    }, []);


    return (
        <>
            {isDesktopOrLaptop &&
                <section style={{ padding: '24px 40px 40px 40px' }}>
                    <div style={{ paddingBottom: '24px' }}>
                        <h3 className={'textCenter f24 boldText'} style={{ color: '#565758' }}>Billing & Payment</h3>
                    </div>
                    <div className={'flexRow alignCenter'} style={{ gap: '16px', marginTop: '24px' }}>
                        <div style={{ width: '100%' }}>
                            <CustomInput label='Meal cost' placeholder={'Enter meal cost'} value={mealCost} />
                        </div>
                        <div style={{ width: '100%' }}>
                            <CustomInput label='Delivery fee' placeholder={'Enter delivery fee'} onChange={onChangeDeliveryFee} value={deliveryFee} />
                        </div>
                    </div>
                    <div className={'flexRow alignCenter'} style={{ gap: '16px' }}>
                        <div style={{ width: '100%' }}>
                            <p className={'f14 grey semiBoldText'} style={{ marginBottom: '8px' }}>Payment method</p>
                            <CityDropDown options={paymentMethods} placeHolder={'Select payment method'} selectData={selectPaymentMethod} data={payment_method} />
                        </div>
                        <div style={{ width: '100%' }}>
                            <p className={'f14 grey semiBoldText'} style={{ marginBottom: '8px' }}>Payment status</p>
                            <CityDropDown options={paymentStatus} placeHolder={'Select payment status'} selectData={selectPaymentStatus} data={payment_status} />
                        </div>
                    </div>

                    <div className={'flexRow alignCenter'} style={{ gap: '16px', marginTop: "24px" }}>
                        <div style={{ width: '100%' }}>
                            <CustomInput label='Coupon' placeholder={'Enter coupon code'} />
                        </div>
                        <div style={{ width: '100%' }} />
                    </div>
                </section>}
            {isTabletOrMobile && <section style={{ paddingBottom: '150px' }}>
                <div style={{ paddingBottom: '24px' }}>
                    <h3 className={'textCenter f16 boldText'} style={{ color: '#565758' }}>Billing & Payment</h3>
                </div>
                <div style={{ marginTop: '24px' }}>
                    <div style={{ width: '100%' }}>
                        <CustomInput label='Meal cost' placeholder={'Enter meal cost'} value={mealCost} />
                    </div>
                    <div style={{ width: '100%' }}>
                        <CustomInput label='Delivery fee' placeholder={'Enter delivery fee'} value={deliveryFee} onChange={onChangeDeliveryFee} />
                    </div>
                </div>
                <div >
                    <div style={{ width: '100%', marginBottom: '16px' }}>
                        <p className={'f14 grey semiBoldText'} style={{ marginBottom: '8px' }}>Payment method</p>
                        <CityDropDown options={paymentMethods} placeHolder={'Select payment method'} selectData={selectPaymentMethod} data={payment_method} />
                    </div>
                    <div style={{ width: '100%' }}>
                        <p className={'f14 grey semiBoldText'} style={{ marginBottom: '8px' }}>Payment status</p>
                        <CityDropDown options={paymentStatus} placeHolder={'Select payment status'} selectData={selectPaymentStatus} data={payment_status} />
                    </div>
                </div>

                <div style={{ marginTop: "16px" }}>
                    <div style={{ width: '100%' }}>
                        <CustomInput label='Coupon' placeholder={'Enter coupon code'} />
                    </div>
                </div>
            </section>}
        </>
    )
}

export default BillingForm