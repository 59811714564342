import moment from "moment";
import http from "../../utils/utils";
import { ACTIVE_ORDERS, ACTIVE_ORDER_FAIL, ACTIVE_ORDER_SUCCESSFUL, GETTING_ORDERS, GETTING_ORDERS_FAIL, GETTING_ORDERS_SUCCESSFUL, GET_SINGLE_ORDER, GET_SINGLE_ORDER_FAIL, GET_SINGLE_ORDER_SUCCESSFUL } from "../Types";

export const getOrders = () => {
    return dispatch => {
        dispatch({ type: GETTING_ORDERS })
        return new Promise(async (resolve, reject) => {
            try {
                const res = await http.get(`admin/orders`)
                const data = res
                // console.log('get Orders ', res)
                dispatch({ type: GETTING_ORDERS_SUCCESSFUL, payload: res });
                resolve(data);
            } catch (error) {
                // console.log('get orders error', error)
                dispatch({ type: GETTING_ORDERS_FAIL, payload: error });
                reject(error);
            }
        });
    };
};

export const getOrdersByDate = (start_date, end_date) => {
    return dispatch => {
        dispatch({ type: GETTING_ORDERS })
        return new Promise(async (resolve, reject) => {
            try {
                const res = await http.get(`admin/orders?start_date=${start_date}&end_date=${end_date}`,)
                const data = res
                // console.log('get Orders ', res)
                dispatch({ type: GETTING_ORDERS_SUCCESSFUL, payload: res });
                resolve(data);
            } catch (error) {
                // console.log('get orders error', error)
                dispatch({ type: GETTING_ORDERS_FAIL, payload: error });
                reject(error);
            }
        });
    };
};

export const getOrdersByStatus = (order_status, page) => {
    return dispatch => {
        dispatch({ type: GETTING_ORDERS })
        return new Promise(async (resolve, reject) => {
            try {
                const res = await http.get(`admin/orders?order_status=${order_status}&page=${page}`)
                const data = res
                // console.log('get Orders status ', res)
                dispatch({ type: GETTING_ORDERS_SUCCESSFUL, payload: res });
                resolve(data);
            } catch (error) {
                // console.log('get orders error', error)
                dispatch({ type: GETTING_ORDERS_FAIL, payload: error });
                reject(error);
            }
        });
    };
};

export const getOrdersByPage = (page) => {
    return dispatch => {
        dispatch({ type: GETTING_ORDERS })
        return new Promise(async (resolve, reject) => {
            try {
                const res = await http.get(`admin/orders?page=${page}`,)
                const data = res
                // console.log('get Orders ', res)
                dispatch({ type: GETTING_ORDERS_SUCCESSFUL, payload: res });
                resolve(data);
            } catch (error) {
                // console.log('get orders error', error)
                dispatch({ type: GETTING_ORDERS_FAIL, payload: error });
                reject(error);
            }
        });
    };
};

export const getOrdersById = (id) => {
    return dispatch => {
        dispatch({ type: GET_SINGLE_ORDER })
        return new Promise(async (resolve, reject) => {
            try {
                const res = await http.get(`admin/order/${id}`,)
                const data = res
                // console.log('get Orders ', res)
                dispatch({ type: GET_SINGLE_ORDER_SUCCESSFUL, payload: res });
                resolve(data);
            } catch (error) {
                // console.log('get orders error', error)
                dispatch({ type: GET_SINGLE_ORDER_FAIL, payload: error });
                reject(error);
            }
        });
    };
};

// https://dadofoods.herokuapp.com/api/v1/admin/orders?order_type=OFFLINE_ORDER

export const getOrdersByType = (order_type) => {
    return dispatch => {
        dispatch({ type: GETTING_ORDERS })
        return new Promise(async (resolve, reject) => {
            try {
                const res = await http.get(`admin/orders?order_type=${order_type}`,)
                const data = res
                // console.log('get Orders ', res)
                dispatch({ type: GETTING_ORDERS_SUCCESSFUL, payload: res });
                resolve(data);
            } catch (error) {
                // console.log('get orders error', error)
                dispatch({ type: GETTING_ORDERS_FAIL, payload: error });
                reject(error);
            }
        });
    };
};

export const getActiveOrders = (order_type) => {
    return dispatch => {
        dispatch({ type: ACTIVE_ORDERS })
        return new Promise(async (resolve, reject) => {
            try {
                const res = await http.get(`admin/orders?order_status=${'ORDER_CONFIRMED'}`,)
                const data = res
                // console.log('get Orders ', res)
                dispatch({ type: ACTIVE_ORDER_SUCCESSFUL, payload: res });
                resolve(data);
            } catch (error) {
                // console.log('get orders error', error)
                dispatch({ type: ACTIVE_ORDER_FAIL, payload: error });
                reject(error);
            }
        });
    };
};



export const getOrdersAll = (order_type = '', start_date = '', end_date = '', page = null, order_status = null) => {
    // console.log(order_type)
    // console.log(order_status)
    // console.log(page)

    return dispatch => {
        dispatch({ type: GETTING_ORDERS })
        return new Promise(async (resolve, reject) => {
            try {
                let apiCall = `admin/orders`
                if (page) {
                    console.log(page)
                    apiCall += `?page=${page}`
                }
                if (order_type) {
                    apiCall += `?order_type=${order_type}`
                }
                if (start_date && end_date) {
                    apiCall += `?start_date=${start_date}&&end_date=${end_date}`
                }
                if (order_status) {
                    apiCall += `?order_status=${order_status}`

                }
                const res = await http.get(apiCall)
                const data = res
                // console.log('get Orders action ', res)
                dispatch({ type: GETTING_ORDERS_SUCCESSFUL, payload: res });
                resolve(data);
            } catch (error) {
                // console.log('get orders error', error)
                dispatch({ type: GETTING_ORDERS_FAIL, payload: error });
                reject(error);
            }
        });
    };
};


// const fetchOrders = async (page = 1, start_date = '', end_date = '', order_status = '', order_type = '') => {
//     try {
//         let endpoint = '/your-endpoint?';
//         endpoint += `page=${page}`;
//         if (start_date) endpoint += `&start_date=${start_date}`;
//         if (end_date) endpoint += `&end_date=${end_date}`;
//         if (order_status) endpoint += `&order_status=${order_status}`;
//         if (order_type) endpoint += `&order_type=${order_type}`;

//         const response = await axios.get(endpoint);

//         return response.data;
//     } catch (error) {
//         console.error(error);
//         throw error;
//     }
// };