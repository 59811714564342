import React, { useEffect, useState } from 'react'
import Navigation from '../../../widgets/Navigation'
import UserManagementNav from '../../../widgets/UserManagementNav'
import GridView from '../../../components/dashboard/userManagement/GridView';
import { BackWardIcon, ForwardIcon, GridViewIcon, ListViewIcon } from '../../../assets/images/icons';
import ListView from '../../../components/dashboard/userManagement/ListView';
import '../../../assets/styles/userManagementStyles.css';
import { useDispatch, useSelector } from 'react-redux';
import { getUserWaitList, getUsers, getUsersAll, getUsersByType } from '../../../redux/actions/UsersActions';
import ViewModal from '../../../components/dashboard/userManagement/ViewModal';
import InviteRiderModal from '../../../components/dashboard/userManagement/InviteRiderModal';
import { useMediaQuery } from 'react-responsive';

const UserManagement = (props) => {

    const [viewMode, setViewMode] = useState('grid');
    const [showModal, setShowModal] = useState(false);
    const [showInviteUser, setInviteUser] = useState(false);
    const { userType, users } = useSelector(state => state.users)
    // console.log(userType, users)
    const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1224px)' })
    const dispatch = useDispatch()
    useEffect(() => {
        dispatch(getUsers())
        return () => {

        }
    }, [])

    const [meta, setMeta] = useState({
        count: users?.meta?.count, currentPage: users?.meta?.currentPage,
        perPage: users?.meta?.perPage, total: users?.meta?.total, totalPages: users?.meta?.totalPages
    });

    const toggleViewMode = () => {
        setViewMode(viewMode === 'grid' ? 'list' : 'grid');
    };


    const pageNumbers = Array.from({ length: users?.meta?.totalPages }, (_, index) => index + 1);
    const maxVisibleNumbers = 5; // Maximum visible numbers on the screen
    const handlePageChange = pageNumber => {
        setMeta({ ...meta, currentPage: pageNumber });
        dispatch(getUsersByType(userType, pageNumber))
    };

    let visiblePageNumbers = [];

    if (pageNumbers.length <= maxVisibleNumbers) {
        visiblePageNumbers = pageNumbers; // Show all numbers if the array length is smaller or equal to the maximum visible numbers
    } else {
        const halfVisibleNumbers = Math.floor((maxVisibleNumbers - 1) / 2);
        const startPage = meta?.currentPage - halfVisibleNumbers;
        const endPage = meta?.currentPage + halfVisibleNumbers;

        if (startPage <= 1) {
            visiblePageNumbers = [...pageNumbers.slice(0, maxVisibleNumbers - 1), '...'];
        } else if (endPage >= pageNumbers.length) {
            visiblePageNumbers = ['...', ...pageNumbers.slice(pageNumbers.length - maxVisibleNumbers + 2)];
        } else {
            visiblePageNumbers = ['...', ...pageNumbers.slice(startPage, endPage + 1), '...'];
        }
    }





    const openModal = () => {
        setShowModal(true)
    }
    const closeModal = () => {
        setShowModal(false)
    }

    const openInviteModal = () => {
        setInviteUser(true)
    }
    const closeInviteModal = () => {
        setInviteUser(false)
    }


    return (
        <>
            <Navigation />
            <UserManagementNav open={openInviteModal} />
            <main className={'userManagementContainer'}>
                <section className='flexRow alignCenter justifyBetween'>
                    <p className={'f20 boldText'}>All users</p>
                    <ul className={'flexRow alignCenter'} style={{ gap: '10px' }}>
                        <li className={'flexRow alignCenter'} style={{ gap: '10px', borderRadius: '8px', border: viewMode === 'grid' ? '1px solid #FFB049' : '', padding: '4px 10px', cursor: 'pointer' }} onClick={toggleViewMode}>
                            <img src={GridViewIcon} />
                            <p className='f14 semiBoldText' style={{ color: '#565758', }}>Grid</p>
                        </li>
                        {!isTabletOrMobile &&
                            <li className={'flexRow alignCenter'} style={{ gap: '10px', borderRadius: '8px', border: viewMode === 'list' ? '1px solid #FFB049' : '', padding: '4px 10px', cursor: 'pointer' }} onClick={toggleViewMode}>
                                <img src={ListViewIcon} />
                                <p className='f14 semiBoldText' style={{ color: '#565758 ', }}>List</p>
                            </li>}
                    </ul>
                </section>

                <section>
                    {viewMode === 'grid' && <GridView openModal={openModal} />}
                    {viewMode === 'list' && <ListView openModal={openModal} />}
                    <div className={'flexRow alignCenter paginationContainer'}>
                        <button disabled={meta?.currentPage === 1} onClick={() => handlePageChange(meta?.currentPage - 1)} className={'prevBtn'}>
                            <img src={BackWardIcon} alt='icon' />
                        </button>
                        <ul className={'flexRow alignCenter'}>
                            {visiblePageNumbers.map(pageNumber => (
                                <li key={pageNumber} className={'pageNumberContainer regularText f14'}>
                                    <button onClick={() => handlePageChange(pageNumber)} className={meta?.currentPage === pageNumber ? "active" : ""}>
                                        {pageNumber}
                                    </button>
                                </li>
                            ))}
                        </ul>
                        <button disabled={meta?.currentPage === users?.meta?.totalPages} onClick={() => handlePageChange(meta?.currentPage + 1)} className={'nextBtn'}>
                            <img src={ForwardIcon} alt='icon' />
                        </button>

                    </div>
                </section>
                {showModal && <ViewModal onDecline={closeModal} />}
                {showInviteUser && <InviteRiderModal onDecline={closeInviteModal} />}
            </main>
        </>
    )
}

export default UserManagement