import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import { getUsersAll, getUsersByDates, getUsersRiders } from '../../redux/actions/UsersActions'
import DateRangePicker from '@wojtekmaj/react-daterange-picker'
import { IoIosArrowDown } from 'react-icons/io'
import moment from 'moment'

const userTypes = ['Active', 'Inactive']

const RiderFilter = () => {
    const [showUserType, setShowUserType] = useState(false)
    const [showDate, setShowDate] = useState(false)
    const dispatch = useDispatch()
    const [value, onChange] = useState([new Date(), new Date()]);
    const [selectedType, setSelectedType] = useState(null)

    const toggleUserType = () => {
        setShowUserType(prevState => !prevState)
    }
    const toggleDate = () => {
        setShowDate(prevState => !prevState)
    }

    let selectedStatus = null

    const toggleTabs = (val) => {
        setSelectedType(val)
        selectedStatus = val
        dispatch(getUsersRiders(selectedStatus, null, null,))
        toggleUserType()

    }


    const renderTabs = () => {
        return userTypes?.map((item, index) => {
            return (
                <li key={index} className={`filterListItem boldText f14`} onClick={() => toggleTabs(item)}>{item}</li>
            )
        })
    }

    let start_date = null
    let end_date = null
    const getUsersByDate = () => {
        if (value) {
            start_date = moment(value[0]).format('YYYY-MM-DD')
            end_date = moment(value[1]).format('YYYY-MM-DD')
            // console.log(start_date, end_date)
            dispatch(getUsersByDates(selectedType, start_date, end_date))
            toggleDate()
        }
    }



    return (

        <>

            <section className={'flexRow alignCenter'} style={{ marginTop: '20px' }}>
                <div style={{ position: 'relative', cursor: "pointer" }}>
                    <div className={'filterDropContainer flexRow alignCenter justifyCenter'} onClick={toggleUserType}>

                        <p className={'f16 semiBoldText'} style={{ color: '#919294' }}>Status</p>
                        <IoIosArrowDown color={'#919294'} />
                    </div>
                    {showUserType && <ul className={'filterListContainer'}>
                        {renderTabs()}
                    </ul>}
                </div>
                <div style={{ position: 'relative', cursor: "pointer" }}>
                    <div className={'filterDropContainer flexRow alignCenter justifyCenter'} onClick={toggleDate}>

                        <p className={'f16 semiBoldText'} style={{ color: '#919294' }}>Date Joined</p>
                        <IoIosArrowDown color={'#919294'} />
                    </div>
                    {showDate && <DateRangePicker onChange={onChange} value={value} onCalendarClose={getUsersByDate} calendarType='Hebrew' />}
                </div>
            </section>
        </>
    )
}

export default RiderFilter



