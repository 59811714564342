import React, { useState } from "react";
import "../../../assets/styles/settingsStyles.css";
import { useDispatch, useSelector } from "react-redux";
import { AiOutlineEdit } from "react-icons/ai";
import MultiButton from "../../../utils/MultiButton";
import CustomInput from "../../../utils/CustomInput";
import EditProfileModal from "./EditProfileModal";
import CityDropDown from "../../../utils/CityDropDown";
import { getOperationsLocations } from "../../../redux/actions/SettingsActions";

const PersonalInformation = () => {
  const { adminProfile } = useSelector((state) => state.settings);
  //   console.log(adminProfile);
  const dispatch = useDispatch();
  const [modal, setModal] = useState(false);
  const toggleModal = () => {
    setModal(!modal);
    dispatch(getOperationsLocations());
  };

  return (
    <>
      <section className={"personalInformationContainer"}>
        <header>
          <h5 className={"f16 boldText"} style={{ color: "#565758" }}>
            Admin Personal Information
          </h5>
        </header>
        <section className={"personalInfoCard"}>
          <header className={"personalInfoCardHeader flexRow alignCenter justifyBetween"}>
            <div className={"flexRow alignCenter"} style={{ gap: "20px" }}>
              <div className={"personalProfileAvatar"}>
                <img src={adminProfile?.admin?.avatar} />
              </div>
              <div>
                <h5 className={"f16 boldText"} style={{ color: "#565758" }}>
                  {adminProfile?.admin?.firstName} {adminProfile?.admin?.lastName}
                </h5>
                <p className={"f12 regularText"} style={{ color: "#FFB049" }}>
                  {adminProfile?.admin?.email}
                </p>
              </div>
            </div>
            <div>
              <MultiButton iconArt={<AiOutlineEdit color="#000" />} title={"Edit profile"} height={"32px"} width={"130px"} bgColor={"#fff"} color={"#000"} brd={"0.50px #999CA0 solid"} onClick={toggleModal} />
            </div>
          </header>
          <div className={"flexRow alignCenter flexWrap"}>
            <CustomInput label={"First name*"} value={adminProfile?.admin?.firstName} disabled={true} height={"100%"} />
            <CustomInput label={"Last name*"} value={adminProfile?.admin?.lastName} disabled={true} height={"100%"} />
          </div>
          <div className={"flexRow alignCenter flexWrap"}>
            <CustomInput label={"Mobile number*"} value={adminProfile?.admin?.phone} disabled={true} height={"100%"} />
            <CustomInput label={"Email*"} value={adminProfile?.admin?.email} disabled={true} height={"100%"} />
          </div>
          <div className={"flexRow alignCenter flexWrap"}>
            <CustomInput label={"Operation location*"} value={adminProfile?.admin?.operationLocation?.location?.state} disabled={true} height={"100%"} />
          </div>
        </section>
      </section>
      {modal && <EditProfileModal onDecline={toggleModal} />}
    </>
  );
};

export default PersonalInformation;
