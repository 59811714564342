import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getOrdersByPage } from '../../../redux/actions/OrdersAction'
import CustomOrdersTable from '../../all/CustomOrdersTable'

const RecentOrdersTable = () => {

    const { orders, gettingOrders } = useSelector(state => state.orders)
    const dispatch = useDispatch()
    const [meta, setMeta] = useState({
        count: orders?.data?.meta?.count, currentPage: orders?.data?.meta?.currentPage,
        perPage: orders?.data?.meta?.perPage, total: orders?.data?.meta?.total, totalPages: orders?.data?.meta?.totalPages
    });

    const handlePageChange = pageNumber => {
        setMeta({ ...meta, currentPage: pageNumber });
        dispatch(getOrdersByPage(pageNumber))
    };
    return (
        <>
            <section >
                <CustomOrdersTable orders={orders?.data} gettingOrders={gettingOrders} title={'Recent Orders'} meta={meta} setMeta={setMeta} onPageChange={handlePageChange} />
            </section>
        </>
    )
}

export default RecentOrdersTable