import React, { useRef, useState, useEffect } from 'react'
import http from '../../utils/utils';
import '../../assets/styles/searchStyles.css';
import { AiOutlineClose, } from 'react-icons/ai';
import SearchIcon from '../../assets/images/icons/searchBtn.svg';
import SearchEmpty from '../../assets/images/icons/noSearch.svg';
import { useMediaQuery } from 'react-responsive';
import FoodCard from '../../components/dashboard/products/FoodCard';
import Pagination from '../../utils/Pagination';
import BackComponent from '../../utils/BackComponent';
import Navigation from '../../widgets/Navigation';
import DeleteProductModal from '../../components/dashboard/products/DeleteProductModal';
import EditProducts from '../../components/dashboard/products/EditProducts';
import { useDispatch, useSelector } from 'react-redux';
import { getProducts } from '../../redux/actions/ProductsActions';
import { showSuccess } from '../../utils/Toast';
import FilterDropDown from '../../components/dashboard/products/FilterDropDown';
import { getVendors } from '../../redux/actions/OtherActions';



const categories = ['Restaurants', 'Home Chef', 'Native Kitchen', 'Bites']
const ProductsSearch = () => {
    const [query, setQuery] = useState('');
    const [results, setResults] = useState([]);
    const isDesktopOrLaptop = useMediaQuery({ query: '(min-width: 1200px)' })
    const dispatch = useDispatch()
    const [filteredProducts, setFilteredProducts] = useState([])
    const { vendors } = useSelector(state => state.others)
    const [state, setState] = useState({
        showCreateProducts: false, deleteModal: false, selectedProduct: null, loading: false, editModal: false,
    })
    const handleInputChange = async (event) => {
        const query = event.target.value;
        setQuery(query);

        const obj = { query }

        try {
            const res = await http.post(`admin/products/search?page=1&limit=100`, obj)
            const data = res?.data
            setResults(data)
        } catch (error) {
            console.log(error)
        }
    };

    const openDeleteProductModal = (val) => {
        setState((prevState) => ({ ...prevState, deleteModal: true, selectedProduct: val }))

    }
    const closeDeleteProductModal = () => {
        setState((prevState) => ({ ...prevState, deleteModal: false }))
    }

    const openEditProductModal = (val) => {
        setState((prevState) => ({ ...prevState, editModal: true, selectedProduct: val }))
    }
    const closeEditProductModal = () => {
        setState((prevState) => ({ ...prevState, editModal: false }))
    }

    const refreshSearch = async () => {
        const obj = { query }

        try {
            const res = await http.post(`admin/products/search?page=1&limit=100`, obj)
            const data = res?.data
            setResults(data)
        } catch (error) {
            console.log(error)
        }
    }

    const deleteProduct = async (val) => {
        setState({ ...state, loading: true })

        try {
            const res = await http.delete(`admin/business/${val?.businessId}/product/${val?.id}/delete`)
            // closeDeleteProductModal()
            if (res) {
                dispatch(getProducts(1))
                setResults([])
                showSuccess('Product Deleted Successfully')
            }
            setState({ ...state, loading: false, deleteModal: false })

        } catch (error) {
            console.log(error)
            setState({ ...state, loading: false })

        }
    }

    const unListProduct = async (val) => {
        setState({ ...state, loading: true })
        const obj = { listing_status: false, }
        try {
            const res = await http.put(`admin/business/${val?.businessId}/product/${val?.id}/update`, obj)
            // closeDeleteProductModal()
            if (res) {
                dispatch(getProducts(1))
                setResults([])
                showSuccess('Product Unlisted Successfully')
            }
            setState({ ...state, loading: false, deleteModal: false })

        } catch (error) {
            console.log(error)
            setState({ ...state, loading: false })

        }
    }

    const enListProduct = async (val) => {
        setState({ ...state, loading: true })
        const obj = { listing_status: true, }
        try {
            const res = await http.put(`admin/business/${val?.businessId}/product/${val?.id}/update`, obj)
            // closeDeleteProductModal()
            if (res) {
                dispatch(getProducts(1))
                setResults([])
                showSuccess('Product Enlisted Successfully')
            }
            setState({ ...state, loading: false, deleteModal: false })

        } catch (error) {
            console.log(error)
            setState({ ...state, loading: false })

        }
    }

    const handleSearchButtonClick = async (query) => {
        setQuery(query);
        const obj = { query }
        try {
            const res = await http.post(`admin/products/search?page=1&limit=15`, obj)
            const data = res?.data
            setResults(data)
        } catch (error) {
            console.log(error)
        }
    };
    const predefinedSearchWords = ['Intercontinental', 'Native kitchen', 'Bakery', 'Chinese food', 'Fit fam', 'Pastries', 'Promo', 'Quick bites', 'Sea food', 'Home chef',];

    const renderResult = () => {
        let allProducts = results?.products
        if (allProducts?.length !== 0) {
            if (filteredProducts?.length !== 0) {
                allProducts = filteredProducts
            }
            return allProducts?.map((item, index) => {
                return (
                    <div key={index} style={{ justifySelf: isDesktopOrLaptop ? 'center' : '' }}>
                        <FoodCard data={item}
                            openDeleteProductModal={() => openDeleteProductModal(item)} openEditProductModal={() => openEditProductModal(item)}
                            unListProduct={() => unListProduct(item)} enListProduct={() => enListProduct(item)}
                        />
                    </div>
                )
            })
        }

    }
    const renderEmptyState = () => {
        if (results?.products?.length === 0) {
            return (
                <div className={'emptySearchContainer'} style={{ paddingBottom: '100px' }}>
                    <img src={SearchEmpty} alt='ICON' />
                    <p className={'f18 mediumText'} style={{ color: "#919294", marginTop: "40px", textAlign: "center" }}>Your search results will be displayed here, toggle the categories above to see meals we have listed. </p>
                </div>
            )
        }
    }

    const renderHeader = () => {
        if (results?.length !== 0 && results?.products?.length !== 0) {
            return (
                <div style={{ marginTop: '40px', marginBottom: '24px', paddingLeft: isDesktopOrLaptop ? '40px' : '0' }}>
                    <p className={'boldText f16'}>Search results</p>
                </div>
            )
        }
    }

    const renderPredefined = () => {
        if (results?.length === 0 && results?.products?.length === 0) {
            return predefinedSearchWords.map((word) => (
                <button key={word} onClick={() => handleSearchButtonClick(word)} className={'predefinedWordBtn'}>
                    <img src={SearchIcon} alt='icon' style={{ marginRight: '6px' }} />
                    <p className={'f16 semiBoldText'} style={{ color: '#919294' }}>{word}</p>
                </button>
            ))
        }
    }

    const clearSearch = () => {
        setQuery('')
        // setResults([])
        // setFilteredProducts([])
    }



    const returnClass = () => {
        if (results?.length !== 0 && results?.products?.length !== 0) {
            return 'brdBottomCurved'
        }
        return ''
    }

    const handleFilterByCategories = (filterParam) => {

        console.log(filterParam)
        const newFilteredProducts = results?.products?.filter(product =>
            product?.business?.categories?.some(category =>
                category?.category?.name === filterParam
            )
        );
        setFilteredProducts(newFilteredProducts);
    }
    const handleFilterByVendors = (filterParam) => {
        const newFilteredProducts = results?.products?.filter(product =>
            product.business.businessName.includes(filterParam?.businessName)
        );
        setFilteredProducts(newFilteredProducts);
    }
    return (
        <section>
            <div className={returnClass()} id='searchHeaderContainer'>
                <div className='searchInputContainer'>
                    <input
                        type="text"
                        placeholder={'Search for meals, drinks & vendors'}
                        value={query}
                        onChange={handleInputChange}
                    />
                    {query !== '' &&
                        <AiOutlineClose size={26} onClick={clearSearch} />}
                </div>
                <div className={'searchFilterContainer'}>
                    <p className={'f18 boldText'}>Filter by</p>
                    <FilterDropDown title={'Category'} list={categories} onClick={handleFilterByCategories} />
                    <FilterDropDown title={'Vendor'} list={vendors?.data?.businesses} onClick={handleFilterByVendors} />
                </div>
            </div>

            <section className={'searchContentContainer'}>
                <div className={'predefinedWordContainer'}>
                    {renderPredefined()}
                </div>
                <div>
                    {renderHeader()}
                </div>
                <div className={'searchGrid'}>
                    {renderResult()}
                </div>
                {/* <div>{renderPagination()}</div> */}
                <div>
                    {renderEmptyState()}
                </div>
            </section>
            {state.deleteModal && <DeleteProductModal onDecline={closeDeleteProductModal} selectedProduct={state.selectedProduct} deleteProduct={deleteProduct} loading={state.loading} />}
            {state.editModal && <EditProducts onDecline={closeEditProductModal} selectedProduct={state.selectedProduct} />}

        </section>

    )
}

export default ProductsSearch