import React, { useState } from 'react';
import CreateOrderButton from '../assets/images/icons/dashboard/createOrder.svg';
import SearchIcon from '../assets/images/icons/dashboard/searchIcon.svg';
import FilterIcon from '../assets/images/icons/dashboard/filterIcon.svg';
import CustomInput from '../utils/CustomInput';
import { useDispatch, useSelector } from 'react-redux';
import { getOrdersAll, getOrdersByStatus } from '../redux/actions/OrdersAction';


const OrderManagementMobileWidget = (props) => {

    const { openCreateOrderModal, searchValue, onChange, page } = props
    console.log(page)
    const { orders } = useSelector(state => state.orders)
    console.log(orders)
    const [state, setState] = useState({
        toggleState: 0, plans: [{ id: 1, name: 'All', type: '' }, { id: 2, name: 'Active', order_status: 'ORDER_ACCEPTED' }, { id: 3, name: 'Pending', order_status: 'ORDER_ASSIGNED' },
        { id: 4, name: 'Completed', order_status: 'ORDER_DELIVERED' }],
    })

    const dispatch = useDispatch()

    const toggleTab = (index, order_status,) => {
        setState({ ...state, toggleState: index })
        dispatch(getOrdersByStatus(order_status, page))
        console.log(order_status, 'order')
    }

    const renderPlans = () => {
        const { toggleState, plans } = state
        if (plans && plans.length !== 0) {
            return plans.map((plan, index) => {
                return (
                    <>
                        <p className={toggleState === index ? 'activeState f12 boldText' : 'tabNeutral regularText f12'}
                            onClick={() => toggleTab(index, plan?.order_status)} key={index}
                            style={{ cursor: 'pointer', }}
                        >{plan.name}</p>
                    </>
                )
            })
        }
    }



    return (
        <section className={'OrderWidgetContainer'}>
            <div className={'flexRow alignCenter justifyBetween'} style={{ gap: '20px' }}>
                {/* <div className={'filterContainer'}>
                    {renderPlans()}
                </div> */}
                <div className={'flexRow alignCenter orderSearchContainer'}>
                    <div>
                        <img src={SearchIcon} alt='icon' />
                    </div>
                    <div style={{ width: '100%', }}>
                        <input onChange={onChange} value={searchValue} type={'text'} />
                    </div>
                    <div>
                        <img src={FilterIcon} alt='icon' />
                    </div>
                </div>
                <div style={{ width: '40px' }}>
                    <img src={CreateOrderButton} alt='icon' style={{ height: '40px', }} onClick={openCreateOrderModal} />
                </div>
            </div>


        </section>
    )
}

export default OrderManagementMobileWidget