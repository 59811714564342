import { useEffect, useRef } from "react";
import useNavigatorOnLine from "./network/useNavigatorOnline";
import RoutesContainer from "./routes/Routes";
import { showError, showSuccess, showWarning } from "./utils/Toast";


function App() {
  const { status, connectionQuality } = useNavigatorOnLine();
  console.log(status, connectionQuality)
  const firstUpdate = useRef(true);

  useEffect(() => {
    if (firstUpdate.current) {
      firstUpdate.current = false;
      return;
    }

    if (!status) {
      showError("You are currently offline");
    } else if (connectionQuality === 'slow-2g' || connectionQuality === '2g' || connectionQuality === '3g') {
      showWarning("You are online, but the network connection is poor");
    } else {
      showSuccess("You are back online!");
    }

  }, [status, connectionQuality]);


  const ACTIVITY_TIMEOUT = 72 * 60 * 60 * 1000; // 72 hours in milliseconds

  // Function to update the last activity timestamp in local storage
  const updateLastActivity = () => {
    localStorage.setItem('lastActivity', Date.now().toString());
  };
  
  // Function to check if the session has expired
  const checkSessionExpiration = () => {
    const lastActivity = localStorage.getItem('lastActivity');
    if (lastActivity) {
      const elapsed = Date.now() - parseInt(lastActivity, 10);
      if (elapsed >= ACTIVITY_TIMEOUT) {
        // Clear local storage or session storage
        localStorage.clear();
        sessionStorage.clear();
        console.log('Storage cleared due to inactivity.');
      }
    }
  };
  
  // Function to initialize activity tracking
  const initializeActivityTracking = () => {
    updateLastActivity();
    document.addEventListener('mousemove', updateLastActivity);
    document.addEventListener('keydown', updateLastActivity);
    setInterval(checkSessionExpiration, 60000); // Check every minute for expiration
  };
  
  // Initialize activity tracking on app load
  initializeActivityTracking();
  

  return (
    <RoutesContainer />

  );
}

export default App;
