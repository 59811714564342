import React from 'react';
import '../../../assets/styles/extraStyles.css';
import { useNavigate } from 'react-router-dom';

const MoreCards = (props) => {
    const navigate = useNavigate()

    const goTo = (val) => {
        navigate(val)
    }
    return (
        <div className={'moreCardContainer'} onClick={() => goTo(props.path)}>
            <div className={'moreCardIcon'}>
                {props.icon}
            </div>
            <div>
                <p className={'boldText f16'} style={{ color: '#38383A' }}>{props.title}</p>
            </div>
        </div>
    )
}

export default MoreCards