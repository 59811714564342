import React from 'react'
import Skeleton from 'react-loading-skeleton'

const GridSkeleton = () => {
    return (
        <>
            <section className={'userGridCardContainer'}>
                <Skeleton className={'userGridCardImage'} />
                <div className={'userGridCardContent'} >
                    <Skeleton style={{ height: '10px', marginBottom: '10px' }} />
                    <Skeleton className={'userGridCardInfo'} style={{ width: "90%" }} />
                </div>
            </section>
            <section className={'userGridCardContainer'}>
                <Skeleton className={'userGridCardImage'} />
                <div className={'userGridCardContent'}>
                    <Skeleton style={{ height: '10px', marginBottom: '10px' }} />
                    <Skeleton className={'userGridCardInfo'} style={{ width: "90%" }} />
                </div>
            </section>
            <section className={'userGridCardContainer'}>
                <Skeleton className={'userGridCardImage'} />
                <div className={'userGridCardContent'}>
                    <Skeleton style={{ height: '10px', marginBottom: '10px' }} />
                    <Skeleton className={'userGridCardInfo'} style={{ width: "90%" }} />
                </div>
            </section>
            <section className={'userGridCardContainer'}>
                <Skeleton className={'userGridCardImage'} />
                <div className={'userGridCardContent'}>
                    <Skeleton style={{ height: '10px', marginBottom: '10px' }} />
                    <Skeleton className={'userGridCardInfo'} style={{ width: "90%" }} />
                </div>
            </section>


        </>
    )
}

export default GridSkeleton