import React from 'react';
import './utils.css';
import { MdOutlineDone } from 'react-icons/md';

const CheckBox = (props) => {
    const { saveForLater, title, onClick } = props

    return (
        <div className={'flexRow alignCenter'} >
            <div className={'checkBoxContainer'}
                style={{ borderColor: saveForLater ? '#FFA024' : '#999CA0', backgroundColor: saveForLater ? '#FFA024' : '', cursor: 'pointer' }}
                onClick={onClick}
            >
                {saveForLater &&
                    <span>
                        <MdOutlineDone color='#ffff' />
                    </span>
                }
            </div>
            <div>
                <p className={'f16 semiBoldText'} style={{ color: saveForLater ? '#696A6B' : '#B1B2B4', cursor: 'pointer' }}>{title}</p>
            </div>
        </div>
    )
}

export default CheckBox