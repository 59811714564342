import { combineReducers } from 'redux';
import { SIGNOUT_USER_SUCCESSFUL } from '../Types';
import AuthReducers from './AuthReducers';
import OrderReducers from './OrderReducers';
import OtherReducers from './OtherReducers';
import ProductsReducers from './ProductsReducers';
import TransactionsReducers from './TransactionsReducers';
import UsersReducer from './UsersReducer';
import SettingsReducers from './SettingsReducers';
// import AuthReducer from './AuthReducer';



const appReducer = combineReducers({
    auth: AuthReducers,
    orders: OrderReducers,
    others: OtherReducers,
    transactions: TransactionsReducers,
    products: ProductsReducers,
    users: UsersReducer,
    settings: SettingsReducers,

});

const rootReducer = (state, action) => {
    // when a logout action is dispatched it will reset redux state
    if (action.type === SIGNOUT_USER_SUCCESSFUL) {
        state = undefined;
    }

    return appReducer(state, action);
};

export default rootReducer;