import React from 'react'

const AuthFooter = () => {
    return (
        <section className={'flexRow alignCenter justifyBetween authFooterContainer'}>
            <div>
                <p>© Dado foods 2022</p>
            </div>
            <div>
                <p>support@dadofoods.ng</p>
            </div>
        </section>
    )
}

export default AuthFooter